export const FETCH_BOOKMARK_GROUPS_REQUEST = "FETCH_GROUPS_REQUEST";
export const FETCH_BOOKMARK_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_BOOKMARK_GROUPS_FAILURE = "FETCH_GROUPS_FAILURE";
export const FETCH_BOOKMARK_GROUPS_RESET = "FETCH_GROUPS_RESET";
export const EDIT_GROUP_TITLE = "EDIT_GROUP_TITLE";
export const DELETE_GROUP = "DELETE_GROUP";

export const FETCH_CASES_REQUEST = "FETCH_CASES_REQUEST";
export const FETCH_CASES_SUCCESS = "FETCH_CASES_SUCCESS";
export const FETCH_CASES_FAILURE = "FETCH_CASES_FAILURE";
export const FETCH_CASES_RESET = "FETCH_CASES_RESET";

export const FETCH_STATUTES_REQUEST = "FETCH_STATUTES_REQUEST";
export const FETCH_STATUTES_SUCCESS = "FETCH_STATUTES_SUCCESS";
export const FETCH_STATUTES_FAILURE = "FETCH_STATUTES_FAILURE";
export const FETCH_STATUTES_RESET = "FETCH_STATUTES_RESET";

export const UPDATE_BOOKMARK_CASE_NOTES = "UPDATE_BOOKMARK_CASE_NOTES";
export const REMOVE_BOOKMARK_CASE_NOTES = "REMOVE_BOOKMARK_NOTES";
export const UPDATE_BOOKMARK_STATUTE_NOTES = "UPDATE_BOOKMARK_STATUTE_NOTES";
export const REMOVE_BOOKMARK_STATUTE_NOTES = "REMOVE_BOOKMARK_STATUTE_NOTES";

export const UPDATE_NOTES_IN_GROUP = "UPDATE_NOTES_IN_GROUP";
export const COPY_BOOKMARK_TO_GROUP = "COPY_BOOKMARK_TO_GROUP";

export const DELETE_BOOKMARK_CASE = "DELETE_BOOKMARK_CASE";
export const DELETE_BOOKMARK_STATUTE = "DELETE_BOOKMARK_STATUTE";

export const DELETE_BOOKMARK_FROM_GROUP = "DELETE_BOOKMARK_FROM_GROUP";
