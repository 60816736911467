import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { userLoginReducer, userSignUpReducer } from "./reducers/userReducers";
import {
  chatHistoryReducer,
  chatTitlesReducer,
  checkServerReducer,
  newChatReducer,
} from "./reducers/chatReducers";
import {
  deletePetitionReducer,
  editPetitionTitleReducer,
  newPetitionReducer,
  petitionHistoryReducer,
  petitionTitlesReducer,
  selectedPetitionReducer,
} from "./reducers/petitionReducers";
import {
  caseDetailReducer,
  caseDetailsFromCosmosReducer,
  caseFiltersReducer,
  searchFacetsReducer,
  searchFilterPaginationReducer,
  searchFilterValuesReducer,
  searchResultsCountReducer,
  searchResultsReducer,
  searchSelectedFiltersReducer,
} from "./reducers/searchReducers";
import statuteSearchReducer, {
  statutePaginationReducer,
  statutePdfPreviewReducer,
  statuteSearchFilterReducer,
  statuteSectionsReducer,
} from "./reducers/statuteSearchReducer";
import { statuteTitleReducer } from "./reducers/statuteSearchReducer";
import {
  applicationTitlesReducer,
  deleteApplicationReducer,
  newapplicationReducer,
  selectedApplicationReducer,
} from "./reducers/applicationReducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import creditReducer from "./reducers/creditReducers";
import {
  bookmarkCasesReducer,
  bookmarkGroupsReducer,
  bookmarkStatutesReducer,
} from "./store/Bookmarks/bookmarksReducers";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["chatTitles", "credit", "userLogin"],
};

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userSignup: userSignUpReducer,
  chatTitles: chatTitlesReducer,
  petitionTitles: petitionTitlesReducer,
  newChat: newChatReducer,
  newPetitionRequest: newPetitionReducer,
  chatHistory: chatHistoryReducer,
  petitionHistory: petitionHistoryReducer,
  editPetitionTitle: editPetitionTitleReducer,
  selectedPetition: selectedPetitionReducer,
  deletePetition: deletePetitionReducer,
  serverStatus: checkServerReducer,
  searchResultsCount: searchResultsCountReducer,
  searchResults: searchResultsReducer,
  searchFacets: searchFacetsReducer,
  searchFilters: searchFilterValuesReducer,
  searchFiltersPagination: searchFilterPaginationReducer,
  selectedFilters: searchSelectedFiltersReducer,
  caseDetail: caseDetailReducer,
  caseDetailsFromCosmos: caseDetailsFromCosmosReducer,
  caseFilters: caseFiltersReducer,
  statutePagination: statutePaginationReducer,
  statuteSearch: statuteSearchReducer,
  isStatuteFilterApplied: statuteSearchFilterReducer,
  statuteSections: statuteSectionsReducer,
  statuteTitle: statuteTitleReducer,
  statutePdfPreviewUrl: statutePdfPreviewReducer,
  newApplicationRequest: newapplicationReducer,
  selectedApplication: selectedApplicationReducer,
  applicationTitles: applicationTitlesReducer,
  deleteApplication: deleteApplicationReducer,
  credit: creditReducer,
  bookmarkGroups: bookmarkGroupsReducer,
  bookmarkCases: bookmarkCasesReducer,
  bookmarkStatutes: bookmarkStatutesReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = [thunk];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const preloadedState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  preloadedState,
});

const persistor = persistStore(store);

export { store, persistor };
