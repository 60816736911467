import React, { useState } from "react";
import { Box } from "@mui/material";
import BookmarksTable from "../../components/Bookmarks/BookmarksTable";
import BookmarksSidebar from "../../components/Bookmarks/BookmarksSidebar";

const Bookmarks = () => {
  const [selectedGroup, setSelectedGroup] = useState({});
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        bgcolor: "#f5f5f5",
        height: "calc(100vh - 50px)",
        width: "100%",
      }}
    >
      <BookmarksSidebar
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
      />
      <BookmarksTable selectedGroup={selectedGroup} />
    </Box>
  );
};

export default Bookmarks;
