import { faFloppyDisk, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

const InformationCard = ({ title, data, onEdit, editable, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(data);

  const handleEditClick = () => setIsEditing(true);
  const handleSaveClick = () => {
    setIsEditing(false);
    onSave(formData);
  };

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Card
      sx={{
        borderRadius: "10px",
        p: 1,
        maxHeight: 380,
        minHeight: 380,
        overflowY: "auto",
      }}
    >
      <CardContent sx={{ p: 0 }}>
        <Box
          sx={{
            bgcolor: "#003D78",
            color: "#FFFFFF",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            p: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"1rem"}>{title}</Typography>
          <Button
            onClick={isEditing ? handleSaveClick : handleEditClick}
            color="primary"
            sx={{
              bgcolor: "#FFFFFF",
              minWidth: "30px", // Ensures a consistent width
              minHeight: "30px", // Ensures a consistent height
              padding: 0, // Removes extra padding inside the button
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: 1, // Adds slight elevation for better visuals
              "&:hover": {
                bgcolor: "#FFFFFF", // Keeps the background color unchanged
                cursor: "pointer", // Shows the pointer cursor on hover
              },
            }}
          >
            <FontAwesomeIcon
              icon={isEditing ? faFloppyDisk : faPenToSquare}
              style={{ fontSize: "16px", color: "#003D78" }} // Adjusts icon size for better fit
            />
          </Button>
        </Box>

        <Box sx={{}}>
          {Object.entries(formData).map(([key, value]) => (
            <Box
              key={key}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                my: "4px",
                alignItems: "center",
              }}
            >
              <Box sx={{ backgroundColor: "#f1f8ff", p: 1, width: "35%" }}>
                <Typography fontSize="13px" fontWeight="bold" my="2px">
                  {key
                    .replace(/([A-Z])/g, " $1")
                    .trim()
                    .charAt(0)
                    .toUpperCase() +
                    key
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .slice(1)}
                  :
                </Typography>
              </Box>
              {isEditing ? (
                <TextField
                  size="small"
                  value={value}
                  onChange={(e) => handleChange(key, e.target.value)}
                  sx={{ width: "60%" }}
                  InputProps={{
                    sx: {
                      bgcolor: "white",
                      fontSize: "13px",
                      "& input:-webkit-autofill": {
                        backgroundColor: "white !important",
                      },
                    },
                  }}
                />
              ) : (
                <Typography fontSize="13px" align="right" sx={{ width: "60%" }}>
                  {value}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default InformationCard;
