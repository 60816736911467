import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  TextField,
  MenuItem,
  Box,
  Typography,
  IconButton,
  Modal,
  Stack,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../config/api";

const FeedbackDialog = ({ open, onClose }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);

  // Form validation schema using Yup
  const validationSchema = Yup.object({
    // name: Yup.string().max(50, "Name must be 50 characters or less."),
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("Email is required"),
    category: Yup.string().required("Category is required"),
    subject: Yup.string().required("Subject is required"),
    details: Yup.string().required("Details are required"),
  });

  const formik = useFormik({
    initialValues: {
      name: userInfo?.name,
      email: userInfo?.email,
      id: userInfo?._id,
      category: "",
      subject: "",
      details: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true); // Start loading
      const transformedPayload = {
        user: {
          name: values.name,
          email: values.email,
          id: userInfo?._id, // Assuming this comes from userInfo
        },
        category: values.category,
        subject: values.subject,
        details: values.details,
        tags: [], // Assuming empty tags for now; modify if needed
        files: attachments.map((attachment) => ({
          fileName: attachment.fileName,
          base64String: attachment.base64String,
          fileType: attachment.fileType,
        })),
      };

      try {
        // API call using Axios
        const response = await axios.post(
          `${config.backendUrl}/storeComplaint`,
          transformedPayload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Handle successful submission
        if (response.status === 201) {
          if (
            formik.values.category === "Bug/Error" ||
            formik.values.category === "Issue"
          ) {
            toast.success(`${formik.values.category} reported successfully!`);
          } else {
            toast.success(`${formik.values.category} submitted successfully!`);
          }
          formik.resetForm();
          setAttachments([]);
          onClose();
        } else {
          // Handle non-success status codes
          toast.error(
            `Error: ${response.data?.message || "Submission failed."}`
          );
        }
      } catch (error) {
        console.error("Error during API call:", error);
        toast.error(
          error.response?.data?.message ||
            "An error occurred while submitting the complaint."
        );
      } finally {
        setLoading(false); // Stop loading
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setAttachments([]);
    onClose();
    setLoading(false);
  };

  const handleRemoveFile = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 string
      reader.onerror = (error) => reject(error);
    });
  };
  // File attachment handling with react-dropzone
  const onDrop = async (acceptedFiles) => {
    // Allowed file types and size limits
    const allowedTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "video/mp4",
      "video/avi",
    ];
    const sizeLimits = {
      "application/pdf": 3 * 1024 * 1024, // 3MB
      "image/jpeg": 3 * 1024 * 1024, // 3MB
      "image/png": 3 * 1024 * 1024, // 3MB
      "video/mp4": 20 * 1024 * 1024, // 10MB
      "video/avi": 20 * 1024 * 1024, // 10MB
    };

    // Filter valid files
    const validFiles = acceptedFiles.filter((file) => {
      const isValidType = allowedTypes.includes(file.type);
      const isValidSize = sizeLimits[file.type]
        ? file.size <= sizeLimits[file.type]
        : false;
      return isValidType && isValidSize;
    });

    // Check file count limit
    if (validFiles.length + attachments.length > 3) {
      toast.error("You can only upload up to 3 files.");
      return;
    }

    // Provide feedback for invalid files
    acceptedFiles.forEach((file) => {
      if (!allowedTypes.includes(file.type)) {
        toast.error(`Unsupported file type: ${file.name}`);
      } else if (file.size > (sizeLimits[file.type] || 0)) {
        const maxSizeMB = (sizeLimits[file.type] - 5) / (1024 * 1024);
        console.log(
          `File size exceeds the limit for ${file.type.split("/")[0]}: ${
            file.name
          } (Max ${maxSizeMB} MB)`
        );
        toast.error(
          `File size exceeds the limit for ${file.type.split("/")[0]}: ${
            file.name
          } (Max 15MB)`
        );
      }
    });

    // Convert each valid file to base64 and update attachments
    const formattedAttachments = await Promise.all(
      validFiles.map(async (file) => {
        const base64String = await convertToBase64(file);
        return {
          fileName: file.name,
          base64String,
          fileType:
            file.type.split("/")[0] === "application" &&
            file.type.endsWith("pdf")
              ? "pdf"
              : file.type.split("/")[0], // "image", "video", or "pdf"
          file,
        };
      })
    );

    // Update attachments state with formatted attachments
    setAttachments((prev) => [...prev, ...formattedAttachments]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "image/*": [".jpeg", ".jpg", ".png"],
      "video/*": [".mp4", ".avi", ".mkv"],
    },
    maxFiles: 3,
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        handleCancel();
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: 500,
          maxWidth: "95%",
          bgcolor: "background.paper",
          borderRadius: 4,
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflowY: "auto",
          clipPath: "inset(0 round 14px)",
          "::-webkit-scrollbar": {
            width: "5px",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#bbb",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent", // Prevent track from showing outside bounds
          },
        }}
      >
        <IconButton
          onClick={handleCancel}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#003D78",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            color: "#003D78",
            mb: 2,
          }}
        >
          Feedback & Complaint
        </Typography>
        <Divider sx={{ width: "100%", mb: 3 }} />
        <form onSubmit={formik.handleSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
          >
            <TextField
              name="name"
              label="Name"
              size="small"
              disabled={true}
              variant="outlined"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            />
            <TextField
              name="email"
              label="Email Address"
              size="small"
              disabled={true}
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
              required
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            />
            <TextField
              name="category"
              label="Category"
              variant="outlined"
              size="small"
              select
              value={formik.values.category}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.category && Boolean(formik.errors.category)}
              helperText={formik.touched.category && formik.errors.category}
              fullWidth
              required
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      "& .MuiMenuItem-root": {
                        fontSize: "14px",
                      },
                    },
                  },
                },
              }}
            >
              <MenuItem value="Complaint">Complaint</MenuItem>
              <MenuItem value="Bug/Error">Bug/Error</MenuItem>
              <MenuItem value="Issue">Issue</MenuItem>
              <MenuItem value="Feedback">Feedback</MenuItem>
            </TextField>
            <TextField
              name="subject"
              label="Subject"
              variant="outlined"
              size="small"
              value={formik.values.subject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              helperText={formik.touched.subject && formik.errors.subject}
              fullWidth
              required
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            />
            <TextField
              name="details"
              label="Details"
              variant="outlined"
              multiline
              rows={4}
              value={formik.values.details}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.details && Boolean(formik.errors.details)}
              helperText={formik.touched.details && formik.errors.details}
              fullWidth
              required
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            />
            <Box
              {...getRootProps()}
              sx={{
                width: "95%",
                border: "2px dashed #ccc",
                borderRadius: "8px",
                padding: 1.5,
                textAlign: "center",
                cursor: "pointer",
                bgcolor: isDragActive ? "#f0f8ff" : "#fafafa",
                "&:hover": {
                  borderColor: "#2196f3",
                },
                transition: "background-color 0.3s, border-color 0.3s",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#303f9f",
                  mb: 1,
                }}
              >
                Attachments
              </Typography>
              <input {...getInputProps()} />
              <Typography>
                {isDragActive
                  ? "Drop files here..."
                  : "Click to upload files, or drag & drop files here"}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                (Only images, videos (max 15MB), or PDFs. Max 3 files)
              </Typography>
            </Box>
            {attachments?.length > 0 && (
              <Box width="95%">
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "bold",
                    color: "#303f9f",
                    fontSize: "14px",
                  }}
                >
                  Selected Files:
                </Typography>
                {attachments?.map((file, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      p: 1,
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      mt: 1,
                      bgcolor: "#fff",
                    }}
                  >
                    {/* Thumbnail or Icon */}
                    {file?.file?.type.startsWith("image/") && (
                      <Box
                        component="img"
                        src={URL.createObjectURL(file?.file)}
                        alt={file?.file.name}
                        sx={{
                          width: 40,
                          height: 40,
                          objectFit: "cover",
                          borderRadius: "4px",
                        }}
                      />
                    )}
                    {file?.file?.type.startsWith("video/") && (
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          bgcolor: "#000",
                          color: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                        }}
                      >
                        🎥
                      </Box>
                    )}
                    {file?.file?.type === "application/pdf" && (
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          bgcolor: "#f44336",
                          color: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                        }}
                      >
                        📄
                      </Box>
                    )}

                    {/* File Details */}
                    <Box flexGrow={1} mx={2}>
                      <Typography variant="body2">
                        {file?.file?.name}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {Math.round(file?.file?.size / 1024)} KB
                      </Typography>
                    </Box>

                    {/* Clear Option */}
                    <IconButton
                      onClick={() => handleRemoveFile(index)}
                      sx={{ color: "#f44336" }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}

            <Stack sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Button
                onClick={handleCancel}
                sx={{
                  borderRadius: "24px",
                  padding: "8px 24px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                variant="outlined"
                color="error"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "24px",
                  padding: "8px 24px",
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "#003D78",
                  "&:hover": { backgroundColor: "primary.main", color: "#fff" },
                  transition: "background-color 0.3s ease-in-out",
                }}
                disabled={loading} // Disable the button during submission
              >
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px", // Adds space between spinner and text
                    }}
                  >
                    <CircularProgress
                      size={18}
                      sx={{
                        color: "#a9a9a9",
                      }}
                    />
                    <Box>Submitting...</Box>
                  </Box>
                ) : (
                  "Submit"
                )}
              </Button>
            </Stack>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default FeedbackDialog;
