import { AppBar, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Gavel as GavelIcon,
  Groups as GroupsIcon,
} from "@mui/icons-material";
const Navbar = ({ currentTab, handleTabChange }) => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#FFFFFF",
        mb: "5px",
        color: "#003D78",
        width: "100%",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        boxShadow: "none",
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          sx={{
            width: "33%",
            fontWeight: "bold",
            color: "#003D78",
          }}
        >
          Case Management System
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#003D78",
              },
            }}
          >
            <Tab
              icon={<DashboardIcon />}
              iconPosition="start"
              label="Dashboard"
              sx={{ textTransform: "none", minWidth: 100 }}
            />
            <Tab
              icon={<GavelIcon />}
              iconPosition="start"
              label="Cases"
              sx={{ textTransform: "none", minWidth: 100 }}
            />
            <Tab
              icon={<PeopleIcon />}
              iconPosition="start"
              label="Clients"
              sx={{ textTransform: "none", minWidth: 100 }}
            />
            <Tab
              icon={<GroupsIcon />}
              iconPosition="start"
              label="Lawyers"
              sx={{ textTransform: "none", minWidth: 100 }}
            />
          </Tabs>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
