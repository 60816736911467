import React, { useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  IconButton,
  Chip,
  Paper,
  Avatar,
  Tooltip,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import SendIcon from '@mui/icons-material/Send';
import BotIcon from '@mui/icons-material/SmartToy';
import IosShareIcon from '@mui/icons-material/IosShare';
import FileViewer from 'react-file-viewer';
import modelLogo from './../assets/images/modelLogo.png';
import ComingSoonModal from './Modal/CommingSoonModal';

const DocumentAnalysis = () => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [docFileUrl, setDocFileUrl] = useState('');
  const [query, setQuery] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [messages, setMessages] = useState([
    { type: 'bot', text: 'Hello! How would you like to analyze the document?' },
  ]);
  const [fileType, setFileType] = useState('');

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      setFileUrl(URL.createObjectURL(droppedFile));
    }
  };

  const handleSendMessage = () => {
    if (query.trim()) {
      setMessages([...messages, { type: 'user', text: query }]);
      setQuery('');
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleChipClick = (suggestion) => {
    setMessages([...messages, { type: 'user', text: suggestion }]);
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      if (
        uploadedFile.type === 'application/pdf' ||
        uploadedFile.type === 'text/plain'
      ) {
        setFileUrl(URL.createObjectURL(uploadedFile));
      } else {
        const type = uploadedFile.name.split('.').pop();
        setFileType(type);
        setDocFileUrl(URL.createObjectURL(uploadedFile));
      }
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileUrl(null);
    setDocFileUrl(null);
  };

  return (
    <>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100vh - 50px)',
        flex: 5,
        bgcolor: '#f5f5f5',
        boxSizing: 'border-box',
        p: '12px 12px 0 12px',
        gap: '12px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
          bgcolor: '#fff',
          borderRadius: '8px',
          p: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{ textAlign: 'left', mb: 2, fontSize: '18px' }}
        >
          Upload and Preview
        </Typography>
        {/* Upload Section */}
        {!file ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: file ? 'none' : '2px dashed #ccc',
              borderRadius: '8px',
              padding: '20px',
              bgcolor: isDragging ? '#E3F2FD' : '#F1F9FF',
              transition: 'background-color 0.3s',
              cursor: 'pointer',
            }}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <>
              <UploadFileIcon sx={{ fontSize: 40, color: '#3C50B1' }} />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                sx={{ mt: 2 }}
              >
                Drag & Drop your PDF, Word or Text file here
              </Typography>
              <Button
                variant="contained"
                component="label"
                sx={{
                  mt: 2,
                  backgroundColor: '#003D78',
                  textTransform: 'none',
                }}
              >
                Choose a File
                <input
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx,.txt"
                />
              </Button>
            </>
          </Box>
        ) : (
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '95%',
              bgcolor: '#F1F9FF',
            }}
          >
            {file.type === 'application/pdf' ? (
              <PictureAsPdfIcon sx={{ color: '#d32f2f' }} />
            ) : (
              <DescriptionIcon sx={{ color: '#1976d2' }} />
            )}
            <Typography variant="body1" sx={{ ml: 1, flexGrow: 1 }}>
              {file.name}
            </Typography>
            <IconButton color="error" onClick={handleRemoveFile}>
              <CloseIcon />
            </IconButton>
          </Paper>
        )}

        {/* Preview Section */}
        <Box
          sx={{
            border: '2px solid #ccc',
            borderRadius: '8px',
            minHeight: '200px',
            bgcolor: file ? '#f0f4ff' : '#f5f5f5',
            display: 'flex',
            flexGrow: file ? 1 : 0,
            alignItems: 'center',
            justifyContent: 'center',
            mt: 2,
            overflowY: 'auto',
          }}
        >
          {!file ? (
            <Typography variant="subtitle2" color="textSecondary">
              No document loaded for preview
            </Typography>
          ) : docFileUrl ? (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <FileViewer
                fileType={fileType}
                filePath={docFileUrl}
                onError={(e) => console.log('Error:', e)}
              />
            </Box>
          ) : (
            <iframe
              src={fileUrl}
              title="Document Preview"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '8px',
                border: 'none',
              }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%',
          bgcolor: '#fff',
          borderRadius: '8px',
          p: 2,
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            mb: 1,
          }}
        >
          <Typography variant="h6" sx={{ fontSize: '18px' }}>
            Document Analysis Chatbot
          </Typography>
          <Tooltip
            title="Export Chat"
            placement="bottom"
            disableInteractive
            arrow
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#101936',
                },
                '& .MuiTooltip-arrow': {
                  color: '#101936',
                },
              },
            }}
          >
            <IconButton size="small" sx={{ mb: '5px' }}>
              <IosShareIcon sx={{ color: '#0d47a1' }} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Paper
          sx={{
            flexGrow: 1,
            p: 2,
            borderRadius: '8px',
            border: '1px solid #e0e0e0',
            overflowY: 'auto',
            mb: 2,
          }}
        >
          {messages.map((message, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent:
                  message.type === 'user' ? 'flex-end' : 'flex-start',
                alignItems: 'center',
                mb: 1,
              }}
            >
              {message.type === 'bot' && (
                <Avatar
                  sx={{
                    bgcolor: '#bbdefb',
                    mr: 1,
                    width: '36px',
                    height: 'auto',
                  }}
                  src={modelLogo}
                />
              )}
              <Box
                sx={{
                  p: 1,
                  borderRadius: '12px',
                  maxWidth: '70%',
                  bgcolor: message.type === 'user' ? '#c5e1a5' : '#bbdefb',
                  color: message.type === 'user' ? '#2e7d32' : '#0d47a1',
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Typography variant="subtitle2">{message.text}</Typography>
              </Box>
            </Box>
          ))}
        </Paper>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {[
            'Summarize Text',
            'Improve Text',
            'Similar Laws and Cases',
            'Extract References',
            'Validate Arguments',
            'Extract Arguments',
            'Enhance Arguments',
            'Generate Counter Arguments',
          ].map((chipText, index) => (
            <Chip
              key={index}
              label={chipText}
              onClick={() => handleChipClick(chipText)}
              clickable
              sx={{
                bgcolor: '#e3f2fd',
                color: '#1976d2',
                '&:hover': {
                  bgcolor: '#bbdefb',
                },
              }}
            />
          ))}
        </Box>
        {/* Input Field and Send Button */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mt: 2,
          }}
        >
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder="Ask question about the document..."
            value={query}
            onKeyDown={handleEnter}
            onChange={(e) => setQuery(e.target.value)}
            sx={{
              //bgcolor: '#f5f5f5',
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                fontSize: '14px',
              },
            }}
          />
          <IconButton
            size="medium"
            color="primary"
            onClick={handleSendMessage}
            sx={{
              backgroundColor: '#303f9f',
              '&:hover': {
                backgroundColor: '#303f9f',
              },
            }}
          >
            <SendIcon fontSize="small" sx={{ color: '#fff' }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
    <Box
      sx={{
        position: "absolute",
        width: "calc(100% - 45px)",
        height: "calc(100vh - 50px)",
        top: "50px",
        left: "45px",
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }} 
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          position: "relative",
          width: "calc(100vw - 45px)",
          height: "calc(100vh - 50px)",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "row",
          overflowY: "clip",
        }}
      >
      
        <ComingSoonModal />
      </Box>
    </Box>
    </>
  );
};

export default DocumentAnalysis;
