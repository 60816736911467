import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  IconButton,
  Typography,
  Box,
  TextField,
  List,
  ListItem,
  Slide,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import SearchIcon from "@mui/icons-material/Search";
import SpinnerWithKeyframes from "./SpinnerPopup";

const formatCourtName = (courtName) => {
  if (!courtName) return "";

  return courtName
    .toLowerCase()
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const formatJudgeName = (names) => {
  if (!names || names.length === 0) return "";

  // Check if names is an array, if not treat it as a single name
  if (!Array.isArray(names)) {
    names = [names];
  }

  // Convert each name to title case and join them with commas
  const titleCaseNames = names
    .map((name) =>
      name
        .toLowerCase()
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
    )
    .join(", ");

  return titleCaseNames;
};

const CaseLawPreviewModal = ({
  open,
  onClose,
  title,
  court,
  citation,
  judge,
  caseDescriptionContent,
}) => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [caseDetails, setCaseDetails] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);

  const caseDetail = useSelector((state) => state.caseDetail);
  const { loading, caseItem } = caseDetail;

  const caseDetailsFromCosmos = useSelector(
    (state) => state.caseDetailsFromCosmos
  );
  const { loading: loadingAdditionalDetails, caseItem: additionalCaseDetails } =
    caseDetailsFromCosmos;

  const formatCaseTitle = (title) => {
    if (!title) return;
    const splitTitle = title.split("Honorable")[0];
    const titleCaseTitle = splitTitle
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return titleCaseTitle;
  };

  const handleClosePreview = () => {
    dispatch({ type: "CASE_DETAILS_FROM_COSMOS_RESET" });
    onClose();
    setTimeout(() => {
      setFullscreen(false);
    }, 200);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const cleanHeadNotes = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    doc
      .querySelectorAll(
        ".modal-header, #TopCloseButtonCreate, #titleModalNewAppointment, .userNameClientSide, .inner-addon, #query, #searchbtn_hit, #bookmarkoption, #modalbookmarmessage"
      )
      .forEach((element) => {
        element.remove();
      });

    return doc.body.innerHTML;
  };

  const highlightText = (html, search) => {
    if (!search) return html;

    const escapedTerm = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`(${escapedTerm})`, "gi");

    const highlightNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const highlightedText = node.textContent.replace(
          regex,
          '<span style="background-color: yellow; font-weight: bold;">$1</span>'
        );
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = highlightedText;
        return tempDiv.innerHTML;
      }

      if (node.nodeType === Node.ELEMENT_NODE) {
        const children = Array.from(node.childNodes)
          .map(highlightNode)
          .join("");
        const element = document.createElement(node.nodeName.toLowerCase());
        Array.from(node.attributes).forEach((attr) => {
          element.setAttribute(attr.name, attr.value);
        });
        element.innerHTML = children;
        return element.outerHTML;
      }

      return "";
    };

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const highlightedHtml = highlightNode(doc.body);

    return highlightedHtml;
  };

  const highlightSummary = (content, searchTerm) => {
    if (!searchTerm) return content;

    const escapedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`(${escapedTerm})`, "gi");

    const highlightNode = (node) => {
      if (typeof node === "string") {
        // Split the text and wrap the matches
        const parts = node.split(regex);
        return parts.map((part, index) =>
          regex.test(part) ? (
            <span
              key={index}
              style={{ backgroundColor: "yellow", fontWeight: "bold" }}
            >
              {part}
            </span>
          ) : (
            part
          )
        );
      }

      // If the node is a React element, clone it and apply highlighting to its children
      if (React.isValidElement(node)) {
        return React.cloneElement(node, {
          children: React.Children.map(node.props.children, highlightNode),
        });
      }

      return node;
    };

    return React.Children.map(content, highlightNode);
  };

  const renderMarkdownContent = (content, searchTerm) => {
    const lines = content.split("\n");
    const elements = [];

    const processBoldText = (text) => {
      // Handle bold text within a string by splitting on ** and wrapping bold parts in <strong>
      const parts = text.split(/\*\*(.*?)\*\*/); // Regex to split bold text by **
      return parts.map((part, idx) =>
        idx % 2 === 0 ? (
          highlightSummary(part, searchTerm)
        ) : (
          <strong key={idx}>{highlightSummary(part, searchTerm)}</strong>
        )
      );
    };

    lines.forEach((line, index) => {
      if (line.startsWith("# ")) {
        // Heading logic
        elements.push(
          <Typography
            key={index}
            variant="h6"
            component="h2"
            gutterBottom
            sx={{
              marginBottom: "15px",
              paddingBottom: "8px",
              color: "#2980b9",
              borderBottom: "1px solid #e6e6e6",
            }}
          >
            {highlightSummary(line.replace("# ", ""), searchTerm)}
          </Typography>
        );
      } else if (line.startsWith("- ")) {
        // List item logic
        const listItemContent = line.replace("- ", "");

        const listItem = (
          <ListItem
            key={elements.length}
            sx={{
              display: "list-item",
              pl: 0,
              mb: 0,
            }}
          >
            <Typography variant="body1">
              {processBoldText(listItemContent)}
            </Typography>
          </ListItem>
        );

        const lastElement = elements[elements.length - 1];
        // Check if last element is a list, then append to it
        if (Array.isArray(lastElement)) {
          lastElement.push(listItem);
        } else {
          elements.push([listItem]);
        }
      } else {
        // Handle bold text within regular paragraphs
        const paragraphElements = processBoldText(line);

        elements.push(
          <Typography
            key={index}
            variant="body1"
            component="div" // Ensure it's a block-level element to handle line breaks
            paragraph
            sx={{ marginBottom: "15px" }} // Adjust margin for better spacing between paragraphs
          >
            {paragraphElements}
          </Typography>
        );
      }
    });

    return elements.map((element, idx) =>
      Array.isArray(element) ? (
        <List
          key={idx}
          dense
          sx={{
            listStyleType: "decimal",
            pl: 4,
            mb: 0,
          }}
        >
          {element}
        </List>
      ) : (
        element
      )
    );
  };

  const getContentAfterHeadings = (html) => {
    // Parse the HTML string to a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Find elements that could contain "ORDER" or "JUDGEMENT"
    const allElements = Array.from(doc.querySelectorAll("h1, h2, p, b"));

    // Look for "ORDER" or "JUDGEMENT" inside these elements (including nested elements)
    const targetElement = allElements.find(
      (el) =>
        el.textContent.trim().includes("ORDER") ||
        el.textContent.trim().includes("JUDGEMENT") ||
        el.textContent.trim().includes("JUDGMENT")
    );

    // Create an array to store the content after "ORDER" or "JUDGEMENT"
    let contentAfterHeadings = [];

    if (targetElement) {
      // Traverse the sibling elements after the found target element
      let nextElement =
        targetElement.closest("p, h1, h2, b").nextElementSibling;
      while (nextElement) {
        contentAfterHeadings.push(nextElement.outerHTML);
        nextElement = nextElement.nextElementSibling;
      }
    }

    // Join the content and return
    return contentAfterHeadings.join("");
  };

  const caseDescriptionWithStyles = `
  <style>
    .MsoNormal {
      font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
      line-height: 1.6;
      font-size: 14px
    }
     .MsoNormal b {
      font-size: 14px;
      color: #303f9f;
    }
  </style>
  ${highlightText(
    getContentAfterHeadings(
      caseDetails?.Case_Description_HTML || caseDescriptionContent
    ),
    searchTerm
  )}
`;

  const caseMetaData = [
    {
      label: "Citation",
      value: caseItem?.value?.[0]?.Citation_Name || citation,
    },
    {
      label: "Court",
      value: formatCourtName(caseItem?.value?.[0]?.Court || court),
    },
    {
      label: "Judge",
      value: formatJudgeName(caseItem?.value?.[0]?.Judge_Name || judge),
    },
    {
      label: "Appellant",
      value:
        formatCaseTitle(caseItem?.value?.[0]?.Title)?.split("Versus")[0] ||
        title?.split("Versus")[0],
    },
    {
      label: "Respondents",
      value:
        formatCaseTitle(caseItem?.value?.[0]?.Title)?.split("Versus")[1] ||
        title?.split("Versus")[1],
    },
  ];

  const filteredMetaData = caseMetaData.filter((detail) => detail.value);

  useEffect(() => {
    if (caseItem && caseItem?.value?.length > 0) {
      setCaseDetails(caseItem?.value[0]);
    } else {
      setCaseDetails(null);
    }
  }, [caseItem]);

  const handleFullscreenToggle = () => {
    setFullscreen(!fullscreen);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClosePreview}
      TransitionComponent={Slide}
      maxWidth="md"
      fullWidth
      fullScreen={fullscreen}
      PaperProps={{
        sx: {
          ...(fullscreen && {
            width: "100%",
            height: "100%",
            margin: 0,
            borderRadius: 0,
            boxShadow: "none",
          }),
        },
      }}
    >
      {/* Body Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "auto",
          p: 2,
        }}
      >
        {loading && <Typography textAlign="center">Loading...</Typography>}
        {(caseDetails || title) && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
                bgcolor: "background.default",
                flexShrink: 0,
              }}
            >
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#303f9f",
                  fontWeight: "bold",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    formatCaseTitle(
                      caseDetails?.Title || additionalCaseDetails?.title
                    ) || title,
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleFullscreenToggle}>
                  {fullscreen ? (
                    <FullscreenExitIcon sx={{ color: "#303f9f" }} />
                  ) : (
                    <FullscreenIcon sx={{ color: "#303f9f" }} />
                  )}
                </IconButton>
                <IconButton onClick={handleClosePreview}>
                  <CancelIcon color="error" />
                </IconButton>
              </Box>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                mb: 2,
                "& .MuiInputBase-root": {
                  height: "40px",
                },
                "& input": {
                  padding: "10px 10px",
                  fontSize: "0.875rem",
                },
              }}
              InputProps={{
                sx: { borderRadius: "24px" },
                startAdornment: <SearchIcon sx={{ color: "#303f9f" }} />,
              }}
            />
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                color: "#303f9f",
                fontWeight: "bold",
                fontSize: "18px",
                marginBottom: "10px",
              }}
            >
              Original Judgement
            </Typography>
            <Box
              sx={{
                height: "100%",
                paddingRight: "8px",
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "5px",
                },
                "::-webkit-scrollbar-track": {
                  background: "#fff",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#bbb",
                  borderRadius: "6px",
                },
              }}
            >
              {filteredMetaData.map((detail, index) => (
                <Box
                  key={index}
                  sx={{
                    bgcolor: index % 2 === 0 ? "#f5f5f5" : "#fff",
                    paddingLeft: "5px",
                    marginBottom: "8px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px" }}>
                    <span style={{ fontWeight: "bold" }}>{detail.label}:</span>{" "}
                    <span>{detail.value}</span>
                  </Typography>
                </Box>
              ))}
              <Box
                sx={{ marginTop: "12px" }}
                dangerouslySetInnerHTML={{
                  __html: caseDescriptionWithStyles,
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default CaseLawPreviewModal;
