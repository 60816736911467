import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Dialog, IconButton, DialogContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StatutePdfViewer = ({ open, onClose }) => {
  const statutePdfPreviewUrl = useSelector(
    (state) => state.statutePdfPreviewUrl
  );
  const { pdfUrl } = statutePdfPreviewUrl;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          backgroundColor: '#f5f5f5',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '50px',
          padding: '0 16px',
          borderBottom: '1px solid #e6e6e6',
          backgroundColor: '#fff',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            color: '#303f9f',
            '&:hover': { backgroundColor: '#e6e6e6' },
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: 0 }}>
        <iframe
          src={pdfUrl}
          title="PDF Viewer"
          width="100%"
          height="99.5%"
          style={{ border: 'none' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default StatutePdfViewer;
