import React, { useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Typography,
  Box,
  Collapse,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Input,
  DialogActions,
  Badge,
} from "@mui/material";
import {
  Add as AddIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
  Chat as ChatIcon,
  Upload as UploadIcon,
  KeyboardArrowRight,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

// Assuming CMSContext is imported from the correct path
import { CMSContext } from "../context/CaseManagmentContext";
import { Stack } from "@mui/system";

export default function DocumentManagement() {
  const { data, setData, documents, setDocuments } = useContext(CMSContext);
  const [expandedRows, setExpandedRows] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [newDocument, setNewDocument] = useState({
    title: "",
    description: "",
    parentId: null,
  });
  const theme = useTheme();

  const [newChildDocuments, setNewChildDocuments] = useState({});

  const toggleRow = (id) => {
    setExpandedRows((prevRows) =>
      prevRows.includes(id)
        ? prevRows.filter((rowId) => rowId !== id)
        : [...prevRows, id]
    );
  };

  const handleAddChild = (parentId) => {
    setNewChildDocuments((prev) => ({
      ...prev,
      [parentId]: {
        title: "",
        description: "",
        version: "",
        uploadedBy: "Current User",
        timestamp: new Date().toISOString().split("T")[0],
        comments: [],
      },
    }));
    if (!expandedRows.includes(parentId)) {
      toggleRow(parentId);
    }
  };

  const handleSaveChild = (parentId) => {
    const newChild = newChildDocuments[parentId];
    if (newChild && newChild.title) {
      const newDoc = {
        id: Date.now().toString(),
        ...newChild,
        parentId,
      };
      setDocuments((prevDocs) => [...prevDocs, newDoc]);
      setNewChildDocuments((prev) => {
        const updated = { ...prev };
        delete updated[parentId];
        return updated;
      });
    }
  };

  const handleInputChange = (parentId, field, value) => {
    setNewChildDocuments((prev) => ({
      ...prev,
      [parentId]: {
        ...prev[parentId],
        [field]: value,
      },
    }));
  };

  const getChildDocuments = (parentId) => {
    return documents.filter((doc) => doc.parentId === parentId);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.name.split(".").pop() || "";
      const newDoc = {
        id: (documents.length + 1).toString(),
        title: newDocument.title || file.name,
        description: newDocument.description || "",
        version: "1.0",
        uploadedBy: "Current User",
        timestamp: new Date().toISOString().split("T")[0],
        fileType,
        fileUrl: URL.createObjectURL(file),
        parentId: newDocument.parentId,
        comments: [],
      };
      setDocuments([...documents, newDoc]);
      setUploadDialogOpen(false);
      setNewDocument({});
    }
  };

  const handleAddComment = () => {
    if (selectedDocument && newComment.trim()) {
      setDocuments(
        documents.map((doc) =>
          doc.id === selectedDocument.id
            ? { ...doc, comments: [...doc.comments, newComment] }
            : doc
        )
      );
      setCommentDialogOpen(false);
      setNewComment("");
      setSelectedDocument(null);
    }
  };

  const handleDelete = (id) => {
    setDocuments(
      documents.filter((doc) => doc.id !== id && doc.parentId !== id)
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Documents
        </Typography>
        <Button
          variant="contained"
          startIcon={<UploadIcon />}
          onClick={() => setUploadDialogOpen(true)}
          sx={{
            backgroundColor: "#003D78",
            color: "#FFFFFF",
            textTransform: "capitalize",
          }}
        >
          Upload
        </Button>
      </Box>

      <TableContainer
        sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        component={Paper}
      >
        <Table>
          <TableHead sx={{ backgroundColor: "#003D78" }}>
            <TableRow>
              <TableCell
                width="5%"
                sx={{ color: "#FFFFFF", fontWeight: 600, py: "12px" }}
              >
                {" "}
              </TableCell>
              <TableCell
                width="15%"
                sx={{ color: "#FFFFFF", fontWeight: 600, py: "12px" }}
              >
                {" "}
                Title
              </TableCell>
              <TableCell
                width="16%"
                sx={{ color: "#FFFFFF", fontWeight: 600, py: "12px" }}
              >
                Description
              </TableCell>
              <TableCell
                width="10%"
                sx={{ color: "#FFFFFF", fontWeight: 600, py: "12px" }}
              >
                Version
              </TableCell>
              <TableCell
                width="15%"
                sx={{ color: "#FFFFFF", fontWeight: 600, py: "12px" }}
              >
                Uploaded By
              </TableCell>
              <TableCell
                width="15%"
                sx={{ color: "#FFFFFF", fontWeight: 600, py: "12px" }}
              >
                Timestamp
              </TableCell>
              <TableCell
                width="5%"
                sx={{ color: "#FFFFFF", fontWeight: 600, py: "12px" }}
              >
                Comments
              </TableCell>
              <TableCell
                width="15%"
                sx={{ color: "#FFFFFF", fontWeight: 600, py: "12px" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents
              .filter((doc) => !doc.parentId)
              .map((document) => (
                <React.Fragment key={document.id}>
                  <TableRow
                    sx={{ py: "0px", cursor: "pointer" }}
                    onClick={() => toggleRow(document.id)}
                  >
                    <TableCell width="5%" sx={{ py: "3px" }}>
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleAddChild(document.id);
                        }}
                      >
                        <AddIcon
                          sx={{
                            fontSize: "16px",
                          }}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      width="10%"
                      sx={{
                        fontSize: "13px",
                        py: "3px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          maxWidth: 80, // Adjust width as needed
                          minWidth: 80,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {document.title}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width="15%"
                      sx={{
                        fontSize: "13px",
                        py: "3px",
                        maxWidth: 105, // Adjust width as needed
                        minWidth: 105,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {document.description}
                    </TableCell>
                    <TableCell
                      width="10%"
                      sx={{
                        fontSize: "13px",
                        py: "0px",
                      }}
                    >
                      {document.version}
                    </TableCell>
                    <TableCell
                      width="15%"
                      sx={{
                        fontSize: "13px",
                        py: "3px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          maxWidth: 80, // Adjust width as needed
                          minWidth: 80,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {document.uploadedBy}
                      </Typography>
                    </TableCell>
                    <TableCell
                      width="15%"
                      sx={{
                        fontSize: "13px",
                        py: "3px",
                        paddingLeft: "30px",
                        "@media (min-width: 1920px)": {
                          paddingLeft: "16px",
                          paddingRight: "32px",
                        },
                      }}
                    >
                      {document.timestamp}
                    </TableCell>
                    <TableCell
                      width="5%"
                      sx={{
                        py: "3px",
                        paddingLeft: "30px",
                        "@media (min-width: 1920px)": {
                          paddingLeft: "7px",
                        },
                      }}
                    >
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setData(document);
                          setSelectedDocument(document);
                          //   setCommentDialogOpen(true);
                        }}
                      >
                        <Badge
                          badgeContent={document.comments.length}
                          color="primary"
                          sx={{
                            "& .MuiBadge-badge": {
                              right: 1, // Adjust positioning if needed
                              top: 1,
                              fontSize: "0.5rem", // Smaller font size
                              minWidth: "13px", // Smaller circle size
                              height: "13px", // Smaller circle size
                              padding: "0", // Remove extra padding
                            },
                          }}
                        >
                          <ChatIcon
                            sx={{
                              fontSize: "16px",
                            }}
                          />
                        </Badge>
                      </IconButton>
                    </TableCell>
                    <TableCell
                      width="15%"
                      sx={{
                        py: "3px",
                      }}
                    >
                      <IconButton
                        sx={{
                          px: "3px",
                        }}
                        color="primary"
                      >
                        <VisibilityIcon
                          sx={{
                            fontSize: "16px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        sx={{
                          px: "3px",
                        }}
                        color="primary"
                      >
                        <DownloadIcon
                          sx={{
                            fontSize: "16px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        sx={{
                          px: "3px",
                        }}
                        color="error"
                        onClick={() => handleDelete(document.id)}
                      >
                        <DeleteIcon
                          sx={{
                            fontSize: "16px",
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: 0 }} colSpan={8}>
                      <Collapse
                        in={expandedRows.includes(document.id)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Table size="small">
                          <TableBody>
                            {getChildDocuments(document.id).map((childDoc) => (
                              <TableRow key={childDoc.id}>
                                <TableCell width="5%">
                                  <IconButton size="small">
                                    <KeyboardArrowRight
                                      sx={{ fontSize: "16px" }}
                                    />
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  width="15%"
                                  sx={{
                                    fontSize: "13px",
                                    py: "3px",
                                    maxWidth: 100, // Adjust width as needed
                                    minWidth: 100,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    "@media (min-width: 1920px)": {
                                      maxWidth: 130, // Adjust width as needed
                                      minWidth: 130,
                                    },
                                  }}
                                >
                                  {childDoc.title}
                                </TableCell>
                                <TableCell
                                  width="16%"
                                  sx={{
                                    fontSize: "13px",
                                    py: "3px",
                                    maxWidth: 110, // Adjust width as needed
                                    minWidth: 100,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    "@media (min-width: 1920px)": {
                                      maxWidth: 140, // Adjust width as needed
                                      minWidth: 140,
                                    },
                                  }}
                                >
                                  {childDoc.description}
                                </TableCell>
                                <TableCell
                                  width="10%"
                                  sx={{
                                    fontSize: "13px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      maxWidth: 30, // Adjust width as needed
                                      minWidth: 30,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      "@media (min-width: 1920px)": {
                                        paddingLeft: "7px", // Adjust width for large screens
                                      },
                                    }}
                                  >
                                    {childDoc.version}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  width="15%"
                                  sx={{
                                    fontSize: "13px",
                                    maxWidth: 80, // Adjust width as needed

                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {childDoc.uploadedBy}
                                </TableCell>
                                <TableCell
                                  width="15%"
                                  sx={{
                                    fontSize: "13px",
                                    maxWidth: 80, // Adjust width as needed

                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {childDoc.timestamp}
                                </TableCell>
                                <TableCell
                                  width="7.5%"
                                  sx={{ fontSize: "13px" }}
                                >
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setData(childDoc);
                                      setSelectedDocument(childDoc);
                                      //   setCommentDialogOpen(true);
                                    }}
                                    sx={{
                                      "&:hover": {
                                        bgcolor: "transparent", // Keeps the background color unchanged
                                        cursor: "pointer", // Shows the pointer cursor on hover
                                      },
                                    }}
                                  >
                                    <Badge
                                      badgeContent={childDoc.comments.length}
                                      color="primary"
                                      sx={{
                                        "& .MuiBadge-badge": {
                                          right: 1, // Adjust positioning if needed
                                          top: 1,
                                          fontSize: "0.5rem", // Smaller font size
                                          minWidth: "13px", // Smaller circle size
                                          height: "13px", // Smaller circle size
                                          padding: "0", // Remove extra padding
                                        },
                                      }}
                                    >
                                      <ChatIcon
                                        sx={{
                                          fontSize: "16px",
                                          //   paddingLeft: "15px",
                                          "@media (min-width: 1920px)": {
                                            // paddingLeft: "30px", // Adjust width for large screens
                                          },
                                        }}
                                      />
                                    </Badge>
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: "15%",
                                    "@media (min-width: 1920px)": {
                                      width: "16.8%", // Adjust width for large screens
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "4px",
                                      paddingLeft: "20px",
                                      "@media (min-width: 1920px)": {
                                        paddingLeft: "22px",
                                      },
                                    }}
                                  >
                                    <IconButton
                                      sx={{ px: "0px" }}
                                      size="small"
                                      color="primary"
                                    >
                                      <VisibilityIcon
                                        sx={{ fontSize: "16px" }}
                                      />
                                    </IconButton>
                                    <IconButton
                                      sx={{ px: "0px" }}
                                      size="small"
                                      color="primary"
                                    >
                                      <DownloadIcon sx={{ fontSize: "16px" }} />
                                    </IconButton>
                                    <IconButton
                                      sx={{ px: "0px" }}
                                      size="small"
                                      color="error"
                                      onClick={() => handleDelete(childDoc.id)}
                                    >
                                      <DeleteIcon sx={{ fontSize: "16px" }} />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                            {newChildDocuments[document.id] && (
                              <TableRow>
                                <TableCell width="5%">
                                  <IconButton size="small">
                                    <KeyboardArrowRight
                                      sx={{ fontSize: "16px" }}
                                    />
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  //   sx={{ paddingRight: "0px" }}
                                  width="15%"
                                >
                                  <TextField
                                    value={newChildDocuments[document.id].title}
                                    onChange={(e) =>
                                      handleInputChange(
                                        document.id,
                                        "title",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Title"
                                    size="small"
                                    sx={{ fontSize: "13px" }}
                                    InputProps={{
                                      sx: {
                                        bgcolor: "white",
                                        fontSize: "13px",
                                        "& input:-webkit-autofill": {
                                          backgroundColor: "white !important",
                                        },
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell width="15%">
                                  <TextField
                                    value={
                                      newChildDocuments[document.id].description
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        document.id,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Description"
                                    size="small"
                                    sx={{ fontSize: "13px" }}
                                    InputProps={{
                                      sx: {
                                        bgcolor: "white",
                                        fontSize: "13px",
                                        "& input:-webkit-autofill": {
                                          backgroundColor: "white !important",
                                        },
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    width="10%"
                                    value={
                                      newChildDocuments[document.id].version
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        document.id,
                                        "version",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Version"
                                    size="small"
                                    sx={{ fontSize: "13px" }}
                                    InputProps={{
                                      sx: {
                                        bgcolor: "white",
                                        fontSize: "13px",
                                        "& input:-webkit-autofill": {
                                          backgroundColor: "white !important",
                                        },
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  width="15%"
                                  sx={{ fontSize: "13px" }}
                                >
                                  {newChildDocuments[document.id].uploadedBy}
                                </TableCell>
                                <TableCell
                                  width="15%"
                                  sx={{ fontSize: "13px" }}
                                >
                                  {newChildDocuments[document.id].timestamp}
                                </TableCell>
                                <TableCell width="5%" sx={{ fontSize: "13px" }}>
                                  <IconButton color="primary" disabled>
                                    <ChatIcon
                                      sx={{
                                        fontSize: "16px",
                                        // paddingLeft: "0px",
                                        "@media (min-width: 1920px)": {
                                          //   paddingLeft: "0px",
                                        },
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: "15%",
                                    "@media (min-width: 1920px)": {
                                      width: "16.8%", // Adjust width for large screens
                                    },
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      display: "flex",
                                      gap: 1,
                                      paddingLeft: "13px",
                                      "@media (min-width: 1920px)": {
                                        paddingLeft: "22px",
                                      },
                                    }}
                                    color="primary"
                                    onClick={() => handleSaveChild(document.id)}
                                  >
                                    <SaveIcon
                                      sx={{
                                        fontSize: "16px",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Upload Dialog */}
      <Dialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload Document</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <TextField
              label="Document Title"
              fullWidth
              value={newDocument.title}
              onChange={(e) =>
                setNewDocument({ ...newDocument, title: e.target.value })
              }
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={newDocument.description}
              onChange={(e) =>
                setNewDocument({ ...newDocument, description: e.target.value })
              }
            />
            <Input
              type="file"
              onChange={handleFileUpload}
              sx={{ display: "none" }}
              id="file-upload"
            />
            <label htmlFor="file-upload">
              <Button variant="outlined" component="span" fullWidth>
                Choose File
              </Button>
            </label>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
