import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  ListItemIcon,
  Divider,
  InputLabel,
  FormControl,
  Select,
  TablePagination,
} from "@mui/material";
import { Delete, SwapHoriz, SwapVert } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CaseLawPreviewModal from "./../../components/UI/CaseLawPreviewModal";
import StatutePreviewModal from "./../../components/UI/StautePreviewModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import InsightsIcon from "@mui/icons-material/Insights";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/free-solid-svg-icons";
import { blue, red, grey } from "@mui/material/colors";
import {
  copyBookmark,
  removeNote,
  updateNote,
} from "../../store/Bookmarks/bookmarksActions";
import {
  DELETE_BOOKMARK_CASE,
  DELETE_BOOKMARK_FROM_GROUP,
  DELETE_BOOKMARK_STATUTE,
} from "../../store/Bookmarks/bookmarksTypes";
import CaseDetailDialog from "../CaseDetailDialog";
import { findCaseSummary } from "../../actions/searchActions";
import CaseSummaryDialog from "../CaseSummaryDialog";
import { showInsufficientCreditsModal } from "../../actions/creditActions";
import { deductCredits } from "../../utils/HelperFunction/deductCredits";
import { Stack } from "@mui/system";
import StatuteDetailDialog from "../StatuteDetailDialog";
import { searchSections } from "../../actions/statuteSearchActions";

const deleteCaseUrl =
  "https://rg-law-gpt-be-auth2.azurewebsites.net/api/deleteCaseOrStatuteFromGroup";

const BookmarksTable = ({ selectedGroup }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const bookmarkGroups = useSelector((state) => state.bookmarkGroups);
  const { groups } = bookmarkGroups;

  const bookmarkCases = useSelector((state) => state.bookmarkCases);
  const { loadingCases, cases } = bookmarkCases;

  const bookmarkStatutes = useSelector((state) => state.bookmarkStatutes);
  const { loadingStatutes, statutes } = bookmarkStatutes;

  const [searchTerm, setSearchTerm] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [selectedStatute, setSelectedStatute] = useState(null);
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [targetGroupId, setTargetGroupId] = useState("");
  const [editNoteItem, setEditNoteItem] = useState(null);
  const [newNote, setNewNote] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [inputError, setInputError] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCaseInsights, setOpenCaseInsights] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [bookmarkAlreadyExists, setBookmarkAlreadyExists] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dispatch = useDispatch();
  const credits = useSelector((state) => state.credit.credits);
  const userId = useSelector((state) => state.credit.userId);

  const handlePreviewClick = (item) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    if (item.type === "case") {
      setSelectedCase(item);
      setOpenPreview(true);
    } else if (item.type === "statute") {
      setSelectedStatute(item);
      const cleanedName = item.Act_Ordinance.replace(/<\/?[^>]+(>|$)/g, "");
      dispatch(searchSections("", cleanedName, "keyword"));
      setOpenPreview(true);
    }
    const deductionSuccess = deductCredits(
      { userId, creditsToDeduct: 1, event: "Preview Bookmarked Document" },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleSummaryClick = async (citation) => {
    dispatch(findCaseSummary(citation));
    setOpenSummary(true);
  };

  const handleNoteChange = (e) => {
    const value = e.target.value;
    if (value.length <= 2000) {
      setNewNote(value);
      setInputError("");
    } else {
      setInputError("You can only input 2000 characters");
    }
  };

  const handleNoteClick = (item) => {
    handleEditNote(item);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setSelectedCase(null);
    setSelectedStatute(null);
  };

  const handleOpenConfirmDialog = (itemId, groupId, itemType) => {
    setItemToDelete({ itemId, groupId, itemType });
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setItemToDelete(null);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDelete = async (itemId, groupId, itemType) => {
    try {
      const response = await fetch(deleteCaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id,
          groupId,
          caseId: itemType === "case" ? itemId : undefined,
          statuteId: itemType === "statute" ? itemId : undefined,
        }),
      });

      if (response.ok) {
        if (itemType === "case") {
          dispatch({ type: DELETE_BOOKMARK_CASE, payload: { itemId } });
          //setCases(cases.filter((caseItem) => caseItem.id !== itemId));
        } else if (itemType === "statute") {
          dispatch({ type: DELETE_BOOKMARK_STATUTE, payload: { itemId } });
          // setStatutes(
          //   statutes.filter((statutesItem) => statutesItem.id !== itemId)
          // );
        }
        dispatch({
          type: DELETE_BOOKMARK_FROM_GROUP,
          payload: { groupId, itemId, itemType },
        });
        setSnackbarMessage({
          type: "success",
          text: "Bookmark Deleted!",
        });
      } else {
        console.error("Error deleting item");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleMove = (item) => {
    setSelectedItem(item);
    setMoveModalOpen(true);
  };

  const handleMoveConfirm = async () => {
    if (!targetGroupId) return;
    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }
      const result = await dispatch(
        copyBookmark({
          userId: userInfo._id,
          fromGroupId: selectedGroup?.id,
          toGroupId: targetGroupId,
          caseId:
            selectedItem.type === "case" ? selectedItem.doc_id : undefined,
          statuteId:
            selectedItem.type === "statute" ? selectedItem.doc_id : undefined,
          selectedItem,
        })
      );
      if (result?.success) {
        setSnackbarMessage({
          type: "success",
          text: result.message,
        });
        setMoveModalOpen(false);
        setSelectedItem(null);
        setTargetGroupId("");
      } else {
        setSnackbarMessage({
          type: "error",
          text: "Failed to copy the bookmark.",
        });
      }
      const deductionSuccess = deductCredits(
        {
          userId,
          creditsToDeduct: 1,
          event: "Move bookmark item to another group",
        },
        dispatch
      );

      if (!deductionSuccess) {
        console.warn(
          "Failed to deduct credits. Ensure retry or user notification."
        );
      }
    } catch (error) {
      console.error("Error moving item:", error);
    }
  };

  const handleSaveNote = () => {
    if (!editNoteItem || !newNote) return;

    try {
      dispatch(
        updateNote({
          userId: userInfo._id,
          groupId: selectedGroup?.id,
          itemId: editNoteItem.doc_id,
          itemType: editNoteItem.type,
          note: newNote,
        })
      );

      // Handle success
      // setSnackbarMessage({
      //   type: "success",
      //   text: "Notes Updated!",
      // });

      // Reset UI state
      setEditNoteItem(null);
      setNewNote("");
    } catch (error) {
      // Handle error
      // setSnackbarMessage({
      //   type: "error",
      //   text: "Failed to update notes. Please try again.",
      // });
      console.error("Error updating note:", error);
    }
  };

  const handleRemoveNoteClick = () => {
    try {
      dispatch(
        removeNote({
          userId: userInfo._id,
          groupId: selectedGroup?.id,
          itemId: editNoteItem.doc_id,
          itemType: editNoteItem.type,
        })
      );

      setEditNoteItem(null);
    } catch (error) {
      console.error("Error removing note:", error);
    }
  };

  const handleEditNote = (item) => {
    setEditNoteItem(item);
    setNewNote(item.note || "");
  };

  const handleSelectGroupToCopy = (targetGroupId) => {
    setTargetGroupId(targetGroupId);

    const targetGroup = groups?.find((group) => group._id === targetGroupId);

    const collection =
      selectItem.type === "case" ? targetGroup.cases : targetGroup.statutes;

    const itemExists = collection?.some(
      (item) => item.id === selectItem.doc_id
    );
    setBookmarkAlreadyExists(itemExists);
  };

  const handleMoveCancel = () => {
    setMoveModalOpen(false);
    setSelectedItem(null);
    setTargetGroupId("");
    setBookmarkAlreadyExists(false);
  };

  const formatCaseTitle = (title) => {
    if (!title) return;
    const splitTitle = title?.split("Honorable")[0];
    const titleCaseTitle = splitTitle
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return titleCaseTitle;
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const combinedItems = [...cases, ...statutes]
    .filter((item) => {
      if (item.type === "case") {
        return item.Title.toLowerCase().includes(searchTerm.toLowerCase());
      } else if (item.type === "statute") {
        return item.Statute_Name.toLowerCase().includes(
          searchTerm.toLowerCase()
        );
      }
      return false;
    })
    .sort((a, b) => new Date(b.addedAt) - new Date(a.addedAt));

  // Check if filtered data is empty; if yes, return initial/default data
  const result =
    combinedItems.length > 0 ? combinedItems : [...cases, ...statutes];

  const sortedItems = [...cases, ...statutes]
    .filter((item) => {
      if (
        item.type === "case" &&
        (selectedCategory === "all" || selectedCategory === "case")
      ) {
        return item.Title.toLowerCase().includes(searchTerm.toLowerCase());
      } else if (
        item.type === "statute" &&
        (selectedCategory === "all" || selectedCategory === "statute")
      ) {
        return item.Statute_Name.toLowerCase().includes(
          searchTerm.toLowerCase()
        );
      }
      return false;
    })
    .sort((a, b) => {
      const isAsc = sortConfig.direction === "asc";
      if (sortConfig.key === "title") {
        // Sort by Title or Statute_Name alphabetically
        const titleA =
          a.type === "case"
            ? a.Title.toLowerCase()
            : a.Statute_Name.toLowerCase();
        const titleB =
          b.type === "case"
            ? b.Title.toLowerCase()
            : b.Statute_Name.toLowerCase();
        return isAsc
          ? titleA.localeCompare(titleB)
          : titleB.localeCompare(titleA);
      } else if (sortConfig.key === "date") {
        const dateA = new Date(a.addedAt);
        const dateB = new Date(b.addedAt);
        return isAsc ? dateA - dateB : dateB - dateA;
      }
      return 0; // No sorting if sortBy is undefined or unhandled
    });

  const sortedAndPaginatedResult =
    sortedItems.length > 0 ? sortedItems.slice(startIndex, endIndex) : [];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          backgroundColor: "#fff",
          flex: 5,
          p: 2,
          m: "12px 12px 0 12px",
          borderRadius: "10px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
          overflow: "auto",
          "::-webkit-scrollbar": {
            width: "5px",
          },
          "::-webkit-scrollbar-track": {
            background: "#fff",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#bbb",
            borderRadius: "6px",
          },
        }}
      >
        <>
          {loadingCases || loadingStatutes ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress size={22} />
            </Box>
          ) : result.length !== 0 ? (
            <>
              <Typography
                sx={{
                  fontSize: "18px",
                  mb: 1,
                  ml: 1,
                  mt: 1,
                  color: "#3C50B1",
                  fontWeight: 600,
                }}
              >
                {selectedGroup?.name}
              </Typography>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    maxWidth: "400px",
                    mt: 1,
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#00509e",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00509e",
                      },
                    },
                    "& input": {
                      padding: "10px 6px",
                      fontSize: "0.875rem",
                    },
                  }}
                  placeholder="Search by title"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon sx={{ color: "#303f9f", padding: "8px" }} />
                    ),
                    sx: {
                      p: 0,
                      backgroundColor: "#ffffff",
                      borderRadius: "20px",
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    sx={{
                      marginRight: "4px",
                      fontSize: "13px",
                      color: "#555",
                      fontWeight: "500",
                    }}
                    htmlFor="resultsCount"
                  >
                    Filter by Category:
                  </InputLabel>
                  <FormControl
                    variant="outlined"
                    sx={{
                      width: 80,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "6px",
                        height: "30px",
                        padding: "8px",
                      },
                      "& fieldset": {
                        borderColor: "#ddd",
                        borderWidth: "1px",
                      },
                      "& .MuiSelect-icon": {
                        right: "4px",
                      },
                      "& .MuiSelect-select": {
                        padding: "0px",
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Select
                      id="filterByCategory"
                      value={selectedCategory}
                      onChange={(e) => {
                        handleCategoryChange(e.target.value);
                      }}
                      sx={{
                        fontSize: "13px",
                        height: "30px",
                        bgcolor: "#fff",
                      }}
                    >
                      <MenuItem value={"all"} sx={{ fontSize: "0.875rem" }}>
                        All
                      </MenuItem>
                      <MenuItem value={"case"} sx={{ fontSize: "0.875rem" }}>
                        Case
                      </MenuItem>
                      <MenuItem value={"statute"} sx={{ fontSize: "0.875rem" }}>
                        Statute
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "12px",
                  overflowY: "auto",
                  "::-webkit-scrollbar": {
                    width: "5px",
                  },
                  "::-webkit-scrollbar-track": {
                    background: "#fff",
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#bbb",
                    borderRadius: "6px",
                  },
                }}
              >
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "#f5f5f5",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      <TableCell
                        sx={{ fontWeight: 600, color: "#101936", py: 2 }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Title
                          <IconButton
                            onClick={() => handleSort("title")}
                            size="small"
                            sx={{ ml: 1 }}
                          >
                            <SwapVert fontSize="small" />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 600, color: "#101936", py: 2 }}
                      >
                        Category
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 600, color: "#101936", py: 2 }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Date
                          <IconButton
                            onClick={() => handleSort("date")}
                            size="small"
                            sx={{ ml: 1 }}
                          >
                            <SwapVert fontSize="small" />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 600, color: "#101936", py: 2 }}
                      >
                        Notes
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 600, color: "#101936", py: 2 }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedAndPaginatedResult &&
                    sortedAndPaginatedResult.length > 0 ? (
                      sortedAndPaginatedResult.map((item, index) => (
                        <TableRow
                          key={item.id}
                          sx={{
                            backgroundColor: "white",
                            transition: "all 0.3s",
                            "&:hover": {
                              backgroundColor: "#f9f9f9",
                              transform: "translateY(-2px)",
                              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
                            },
                            "&:not(:last-child)": {
                              borderBottom: "1px solid #f0f0f0",
                            },
                          }}
                        >
                          <TableCell
                            sx={{
                              py: 2,
                              color: "#101936",
                              fontWeight: 500,
                              cursor: "pointer",
                              "&:hover": {
                                textDecoration: "underline",
                                color: "#3C50B1",
                              },
                            }}
                            onClick={() => handlePreviewClick(item)}
                          >
                            {item.type === "case"
                              ? truncateText(formatCaseTitle(item.Title), 100)
                              : truncateText(
                                  formatCaseTitle(item.Statute_Name),
                                  100
                                )}
                          </TableCell>
                          <TableCell sx={{ py: 2, color: grey[700] }}>
                            {item.type === "case" ? "Case" : "Statute"}
                          </TableCell>
                          <TableCell sx={{ py: 2, color: grey[700] }}>
                            {moment(item.addedAt).isValid()
                              ? moment(item.addedAt).format("LL")
                              : item.addedAt}
                          </TableCell>
                          <TableCell
                            sx={{
                              py: 2,
                              maxWidth: "150px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              color: "#101936",

                              "&:hover": {
                                textDecoration: "underline",
                                color: "#3C50B1",
                              },
                            }}
                            onClick={() => handleNoteClick(item)}
                          >
                            {item.note || "Add a note..."}
                          </TableCell>
                          <TableCell sx={{ py: 2 }}>
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <IconButton
                                size="small"
                                sx={{
                                  color: "inherit",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  },
                                }}
                                onClick={(event) => {
                                  setSelectItem(item);
                                  setAnchorEl(event.currentTarget);
                                }}
                              >
                                <MoreVertIcon fontSize="small" />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                PaperProps={{
                                  sx: {
                                    //mt: 3.5,
                                    boxShadow:
                                      "0px 4px 12px rgba(0, 0, 0, 0.1) !important",
                                    borderRadius: "8px",
                                    //zIndex: 1,
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handlePreviewClick(selectItem);
                                    setAnchorEl(null);
                                  }}
                                >
                                  <ListItemIcon>
                                    <VisibilityIcon
                                      fontSize="small"
                                      sx={{ color: blue[500] }}
                                    />
                                  </ListItemIcon>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    Preview
                                  </Typography>
                                </MenuItem>
                                {selectItem.type === "case" && (
                                  <>
                                    <MenuItem
                                      onClick={() => {
                                        let creditsToDeduct = 1;
                                        if (
                                          credits <= 0 ||
                                          credits <= creditsToDeduct
                                        ) {
                                          dispatch(
                                            showInsufficientCreditsModal()
                                          ); // Trigger modal if credits are insufficient
                                          return;
                                        }
                                        dispatch({
                                          type: "CASE_DETAIL_SUCCESS",
                                          payload: selectItem,
                                        });

                                        setOpenCaseInsights(true);
                                        setAnchorEl(null);
                                        const deductionSuccess = deductCredits(
                                          {
                                            userId,
                                            creditsToDeduct: 1,
                                            event:
                                              "Open case insights in Bookmark",
                                          },
                                          dispatch
                                        );

                                        if (!deductionSuccess) {
                                          console.warn(
                                            "Failed to deduct credits. Ensure retry or user notification."
                                          );
                                        }
                                      }}
                                    >
                                      <ListItemIcon>
                                        <InsightsIcon
                                          sx={{ color: "#4CAF50" }}
                                          fontSize="small"
                                        />
                                      </ListItemIcon>
                                      <Typography sx={{ fontSize: "13px" }}>
                                        Case Insights
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        let creditsToDeduct = 1;
                                        if (
                                          credits <= 0 ||
                                          credits <= creditsToDeduct
                                        ) {
                                          dispatch(
                                            showInsufficientCreditsModal()
                                          ); // Trigger modal if credits are insufficient
                                          return;
                                        }
                                        handleSummaryClick(
                                          selectItem?.Citation_Name
                                        );
                                        setAnchorEl(null);
                                        const deductionSuccess = deductCredits(
                                          {
                                            userId,
                                            creditsToDeduct: 1,
                                            event:
                                              "Open summary of Bookmarked item",
                                          },
                                          dispatch
                                        );

                                        if (!deductionSuccess) {
                                          console.warn(
                                            "Failed to deduct credits. Ensure retry or user notification."
                                          );
                                        }
                                      }}
                                    >
                                      <ListItemIcon>
                                        <FontAwesomeIcon
                                          icon={faRectangleList}
                                          color="#ff9800"
                                          fontSize="16px"
                                        />
                                      </ListItemIcon>
                                      <Typography sx={{ fontSize: "13px" }}>
                                        Summary
                                      </Typography>
                                    </MenuItem>
                                  </>
                                )}
                                <MenuItem
                                  onClick={() => {
                                    handleMove(selectItem);
                                    setAnchorEl(null);
                                  }}
                                >
                                  <ListItemIcon>
                                    <SwapHoriz
                                      fontSize="small"
                                      sx={{ color: "#4CAF50" }}
                                    />
                                  </ListItemIcon>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    Add to Another Group
                                  </Typography>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    let creditsToDeduct = 1;
                                    if (
                                      credits <= 0 ||
                                      credits <= creditsToDeduct
                                    ) {
                                      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
                                      return;
                                    }

                                    handleOpenConfirmDialog(
                                      selectItem.doc_id,
                                      selectedGroup?.id,
                                      selectItem.type
                                    );
                                    setAnchorEl(null);
                                    const deductionSuccess = deductCredits(
                                      {
                                        userId,
                                        creditsToDeduct: 1,
                                        event:
                                          "Open summary of Bookmarked item",
                                      },
                                      dispatch
                                    );

                                    if (!deductionSuccess) {
                                      console.warn(
                                        "Failed to deduct credits. Ensure retry or user notification."
                                      );
                                    }
                                  }}
                                >
                                  <ListItemIcon>
                                    <Delete
                                      fontSize="small"
                                      sx={{ color: red[500] }}
                                    />
                                  </ListItemIcon>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    Delete
                                  </Typography>
                                </MenuItem>
                              </Menu>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{
                            textAlign: "center",
                            padding: "16px",
                            fontSize: "14px",
                            color: "#777",
                          }}
                        >
                          No bookmark found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 1,
                }}
              >
                <TablePagination
                  component="div"
                  count={sortedItems.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontSize: "50px",
                  marginBottom: "10px",
                  color: "#303f9f",
                }}
              >
                <SearchIcon fontSize="inherit" />
              </Box>
              <Typography
                variant="h5"
                sx={{ fontSize: "24px", marginBottom: "10px" }}
              >
                No Bookmarks Found
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "16px", color: "#666" }}
              >
                Start organizing your key legal references for quick and easy
                access
              </Typography>
            </Box>
          )}
        </>
        {snackbarMessage && (
          <Snackbar
            open={!!snackbarMessage}
            autoHideDuration={6000}
            onClose={() => setSnackbarMessage(null)}
          >
            <Alert
              onClose={() => setSnackbarMessage(null)}
              severity={snackbarMessage.type}
              sx={{ width: "100%" }}
            >
              {snackbarMessage.text}
            </Alert>
          </Snackbar>
        )}
      </Box>
      <CaseSummaryDialog
        open={openSummary}
        onClose={() => setOpenSummary(false)}
      />
      <CaseDetailDialog
        open={openCaseInsights}
        onClose={() => setOpenCaseInsights(false)}
      />
      <Dialog
        open={moveModalOpen}
        // maxWidth="xs"
        // fullWidth
        PaperProps={{
          sx: {
            width: 400,
            borderRadius: "16px",
            padding: "12px",
            background: "#fff",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          },
        }}
        onClose={handleMoveCancel}
      >
        <DialogTitle
          sx={{
            fontSize: "18px",
            color: "#303f9f",
            fontWeight: "bold",
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "12px",
          }}
        >
          Copy to Another Group
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            padding: "12px",
          }}
        >
          <TextField
            label="Select Group"
            variant="outlined"
            select
            fullWidth
            disabled={
              groups?.filter((group) => group._id !== selectedGroup?.id)
                .length === 0
            }
            size="small"
            value={targetGroupId}
            onChange={(e) => handleSelectGroupToCopy(e.target.value)}
            sx={{ mt: 1 }}
            InputProps={{
              sx: {
                bgcolor: "white",
                fontSize: "13px",
                "& input:-webkit-autofill": {
                  backgroundColor: "white !important",
                  WebkitBoxShadow: "0 0 0 30px white inset !important",
                  WebkitTextFillColor: "black !important",
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          >
            {groups
              ?.filter((group) => group._id !== selectedGroup?.id)
              ?.map((group) => (
                <MenuItem
                  key={group._id}
                  value={group._id}
                  sx={{
                    fontSize: "13px",
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {group.groupName}
                </MenuItem>
              ))}
          </TextField>
          {groups?.filter((group) => group._id !== selectedGroup?.id).length ===
            0 && (
            <Typography
              sx={{
                color: "#757575",
                fontSize: "13px",
                mt: 2,
              }}
            >
              No other groups available. Please create a new group to copy this
              bookmark.
            </Typography>
          )}
          {bookmarkAlreadyExists && (
            <Typography
              sx={{
                color: "#757575",
                fontSize: "13px",
                mt: 2,
              }}
            >
              Bookmark already exists in the selected group.
            </Typography>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            padding: "12px",
          }}
        >
          <Button
            onClick={handleMoveCancel}
            color="primary"
            variant="outlined"
            sx={{
              borderRadius: "24px",
              padding: "8px 24px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleMoveConfirm}
            color="primary"
            variant="contained"
            disabled={
              groups?.filter((group) => group._id !== selectedGroup?.id)
                .length === 0 || bookmarkAlreadyExists
            }
            sx={{
              borderRadius: "24px",
              padding: "8px 24px",
              textTransform: "none",
              fontWeight: "bold",
              ml: 2,
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {selectedCase && (
        <CaseLawPreviewModal
          open={openPreview}
          onClose={handleClosePreview}
          title={selectedCase ? formatCaseTitle(selectedCase.Title) : ""}
          court={selectedCase ? selectedCase.Court : ""}
          citation={selectedCase ? selectedCase.Citation_Name : ""}
          judge={selectedCase ? selectedCase.judge_name_clean : ""}
          caseDescriptionContent={
            selectedCase ? selectedCase.Case_Description_HTML : ""
          }
        />
      )}
      {selectedStatute && (
        <StatuteDetailDialog open={openPreview} onClose={handleClosePreview} />
        // <StatutePreviewModal
        //   open={openPreview}
        //   onClose={handleClosePreview}
        //   statute={selectedStatute}
        // />
      )}
      <Dialog
        open={!!editNoteItem}
        onClose={() => setEditNoteItem(null)}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            padding: "12px",
            width: 350,
            height: 350,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "20px",
            color: "#303f9f",
            fontWeight: "bold",
            padding: "12px",
          }}
        >
          Add a Note
        </DialogTitle>

        <Divider />

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            gap: "8px",
            overflowY: "hidden",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              color: "text.secondary",
            }}
          >
            Notes
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Write your note here..."
            multiline
            rows={7}
            value={newNote}
            onChange={handleNoteChange}
            InputProps={{
              sx: {
                fontSize: "14px",
                padding: "12px",
                borderRadius: "6px",
              },
            }}
            error={Boolean(inputError)}
            helperText={inputError}
          />
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "flex-end",
            paddingTop: "16px",
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={handleRemoveNoteClick}
            sx={{
              borderRadius: "24px",
              padding: "8px 24px",
              textTransform: "none",
              fontWeight: "bold",
              ml: 2,
            }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSaveNote}
            disabled={inputError}
            sx={{
              borderRadius: "24px",
              padding: "8px 24px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            padding: "14px",
            background: "#fff",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        {/* Dialog Title */}
        <DialogTitle
          id="confirm-deletion-dialog"
          sx={{
            fontSize: "18px",
            color: "#303f9f",
            fontWeight: "bold",
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "16px",
          }}
        >
          Confirm Deletion
        </DialogTitle>

        {/* Dialog Content */}
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ marginBottom: 1 }}
          >
            Are you sure you want to delete this bookmark?
          </Typography>
          <Typography variant="body2" color="textSecondary" textAlign="center">
            This action cannot be undone.
          </Typography>
        </DialogContent>

        {/* Dialog Actions */}
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            marginTop: 2,
          }}
        >
          <Button
            onClick={handleCloseConfirmDialog}
            color="primary"
            variant="outlined"
            sx={{
              borderRadius: "24px",
              padding: "8px 24px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDelete(
                itemToDelete.itemId,
                itemToDelete.groupId,
                itemToDelete.itemType
              );
              handleCloseConfirmDialog();
            }}
            color="error"
            variant="contained"
            sx={{
              borderRadius: "24px",
              padding: "8px 24px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BookmarksTable;
