import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  Card,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

const formatCourtName = (courtName) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(courtName, "text/html");

  const formatTextContent = (name) => {
    return name
      .toLowerCase()
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const walkNodes = (node) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.nodeValue = formatTextContent(child.nodeValue);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        walkNodes(child);
      }
    });
  };

  walkNodes(doc.body);

  return doc.body.innerHTML;
};

const formatJudgeName = (names) => {
  if (!names || names.length === 0) return "";

  // Check if names is an array, if not treat it as a single name
  if (!Array.isArray(names)) {
    names = [names];
  }

  // Convert each name to title case and join them with commas
  const titleCaseNames = names
    .map((name) =>
      name
        .toLowerCase()
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
    )
    .join(", ");

  return titleCaseNames;
};

const formatCaseTitle = (title) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(title, "text/html");

  const formatTextContent = (text) => {
    return text
      .split("Honorable")[0]
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const walkNodes = (node) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.nodeValue = formatTextContent(child.nodeValue);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        walkNodes(child);
      }
    });
  };

  walkNodes(doc.body);

  return doc.body.innerHTML;
};

const CaseDetail = ({ closeOnBack }) => {
  const caseDetail = useSelector((state) => state.caseDetail);
  const { caseItem } = caseDetail;

  const [checkedItems, setCheckedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedElements, setHighlightedElements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const checkboxItems = [
    ...(caseItem?.facts && caseItem.facts.length > 0
      ? [{ label: "Facts", color: "#ffec94", value: "facts" }]
      : []),
    ...(caseItem?.issues && caseItem.issues.length > 0
      ? [{ label: "Issues", color: "#ffaeae", value: "issues" }]
      : []),
    ...(caseItem?.argument_by_petitioner &&
    caseItem.argument_by_petitioner.length > 0
      ? [
          {
            label: "Petitioner's Arguments",
            color: "#ccffbb",
            value: "argument_by_petitioner",
          },
        ]
      : []),
    ...(caseItem?.argument_by_responses &&
    caseItem.argument_by_responses.length > 0
      ? [
          {
            label: "Respondent's Arguments",
            color: "#cccc99",
            value: "argument_by_responses",
          },
        ]
      : []),
    ...(caseItem?.section_analysis && caseItem.section_analysis.length > 0
      ? [
          {
            label: "Analysis of the Law",
            color: "#56baec",
            value: "section_analysis",
          },
        ]
      : []),
    ...(caseItem?.precedent_analysis && caseItem.precedent_analysis.length > 0
      ? [
          {
            label: "Precedent Analysis",
            color: "#b4d8e7",
            value: "precedent_analysis",
          },
        ]
      : []),
    ...(caseItem?.courts_reasoning && caseItem.courts_reasoning.length > 0
      ? [
          {
            label: "Court's Reasoning",
            color: "#fce6e6",
            value: "courts_reasoning",
          },
        ]
      : []),
    ...(caseItem?.conclusion && caseItem.conclusion.length > 0
      ? [{ label: "Conclusion", color: "#c0bcb6", value: "conclusion" }]
      : []),
  ];

  useEffect(() => {
    const highlights = document.querySelectorAll(".highlight");
    setHighlightedElements(highlights);

    if (highlights.length > 0) {
      highlights.forEach((el, index) => {
        if (index === currentIndex) {
          el.style.backgroundColor = "orange";
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          el.style.backgroundColor = "yellow";
        }
      });
    }

    // Cleanup function
    return () => {};
  }, [searchTerm, currentIndex]);

  const handleNextMatch = () => {
    if (highlightedElements.length > 0) {
      const nextIndex = (currentIndex + 1) % highlightedElements.length;
      setCurrentIndex(nextIndex);
    }
  };

  const handleCheckboxChange = (value) => {
    setCheckedItems((prev) => {
      // Check if the value is already in the array
      const isValueIncluded = prev?.includes(value);

      // If included, remove it; otherwise, add it
      const updatedItems = isValueIncluded
        ? prev?.filter((item) => item !== value) // Remove the value
        : [...prev, value]; // Add the value

      return updatedItems;
    });
  };

  const highlightText = (html, search) => {
    if (!search) return html;

    // Escape special characters in the search term
    const escapedTerm = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(
      `(${escapedTerm.replace(/\s+/g, "(\\s|<[^>]+>|\\n|\\r)*")})`,
      "gi"
    );

    // Function to highlight text recursively
    const highlightNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const highlightedText = node.textContent.replace(
          regex,
          '<span class="highlight" style="background-color: orange; font-weight: bold;">$1</span>'
        );
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = highlightedText;
        return tempDiv.innerHTML;
      }

      if (node.nodeType === Node.ELEMENT_NODE) {
        const children = Array.from(node.childNodes)
          .map(highlightNode)
          .join("");
        const element = document.createElement(node.nodeName.toLowerCase());
        Array.from(node.attributes).forEach((attr) => {
          element.setAttribute(attr.name, attr.value);
        });
        element.innerHTML = children;
        return element.outerHTML;
      }

      return "";
    };

    // Parse the input HTML and highlight matches
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const highlightedHtml = Array.from(tempDiv.childNodes)
      .map(highlightNode)
      .join("");

    return highlightedHtml;
  };

  const getContentAfterHeadings = (html) => {
    // Parse the HTML string to a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Find elements that could contain "ORDER" or "JUDGEMENT"
    const allElements = Array.from(doc.querySelectorAll("h1, h2, p, b"));

    // Look for "ORDER" or "JUDGEMENT" inside these elements (including nested elements)
    const targetElement = allElements.find(
      (el) =>
        el.textContent.trim().includes("ORDER") ||
        el.textContent.trim().includes("JUDGEMENT") ||
        el.textContent.trim().includes("JUDGMENT")
    );

    // Create an array to store the content after "ORDER" or "JUDGEMENT"
    let contentAfterHeadings = [];

    if (targetElement) {
      // Traverse the sibling elements after the found target element
      let nextElement =
        targetElement.closest("p, h1, h2, b").nextElementSibling;
      while (nextElement) {
        contentAfterHeadings.push(nextElement.outerHTML);
        nextElement = nextElement.nextElementSibling;
      }
    }

    // Join the content and return
    return contentAfterHeadings.join("");
  };

  const caseDescriptionWithStyles = `
  <style>
    .MsoNormal {
      font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
      font-size: 14px;
      text-align: 'justify';
    }
     .MsoNormal b {
      color: #303f9f;
    }
  </style>
  ${highlightText(
    getContentAfterHeadings(
      caseItem?.Case_Description_HTML ||
        caseItem?.metadata.Case_Description_HTML
    ),
    searchTerm
  )}
`;

  const caseMetaData = [
    {
      label: "Title",
      value: formatCaseTitle(caseItem?.Title || caseItem?.metadata.Title),
    },
    {
      label: "Citation",
      value: caseItem?.Citation_Name || caseItem?.metadata.Citation_Name,
    },
    {
      label: "Court",
      value: formatCourtName(caseItem?.Court || caseItem?.metadata.Court),
    },
    {
      label: "Judge",
      value: formatJudgeName(
        caseItem?.judge_name_clean || caseItem?.metadata?.Judge_Name
      ),
    },
    {
      label: "Appellant",
      value: formatCaseTitle(caseItem?.Title).split("Versus")[0],
    },
    {
      label: "Respondents",
      value: formatCaseTitle(caseItem?.Title).split("Versus")[1],
    },
  ];

  const filteredMetaData = caseMetaData.filter((detail) => detail.value);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flex: 5,
        flexGrow: 1,
        p: "0px 8px",
        boxSizing: "border-box",
        bgcolor: "#f5f5f5",
      }}
    >
      <React.Fragment>
        <Box
          sx={{
            margin: "0 8px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              bgcolor: "#f5f5f5",
              margin: "16px 0px",
            }}
          >
            <Box sx={{ display: "flex", mr: "6px" }}>
              <IconButton sx={{ color: "#303f9f" }} onClick={closeOnBack}>
                <FontAwesomeIcon icon={faCircleArrowLeft} fontSize="23px" />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                backgroundColor: "white",
                //margin: '16px 2px 8px 2px',
                borderRadius: "24px",
                "& .MuiInputBase-root": {
                  height: "40px",
                  fontSize: "14px",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "24px",
                },
                "& input": {
                  padding: "10px 10px",
                  fontSize: "0.875rem",
                },
              }}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "#303f9f" }} />,
                endAdornment: (
                  <IconButton
                    onClick={handleNextMatch}
                    disabled={highlightedElements.length === 0}
                  >
                    <ExpandMore sx={{ color: "#303f9f" }} />
                  </IconButton>
                ),
              }}
            />
          </Box>
          {checkboxItems?.length > 0 && (
            <Card
              sx={{
                overflow: "visible",
                display: "flex",
                flexDirection: "column",
                p: "8px",
                //mt: 1,
                bgcolor: "#fff",
                boxShadow: "1px 2px 6px 0px #00000020",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  fontWeight: "bold",
                  marginBottom: "6px",
                }}
              >
                Select the following parts of the judgement
              </Typography>
              <Grid container spacing={2}>
                {checkboxItems.map(({ label, color, value }) => (
                  <Grid item xs={3} key={label}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        bgcolor: color,
                        p: "5px",
                        borderRadius: "4px",
                      }}
                    >
                      <Checkbox
                        size="small"
                        checked={checkedItems?.includes(value) || false}
                        onChange={() => handleCheckboxChange(value)}
                        sx={{
                          p: 0,
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#000",
                          ml: "4px",
                          fontWeight: "bold",
                          fontSize: "13px",
                        }}
                      >
                        {label}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Card>
          )}
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            bgcolor: "#fff",
            p: "12px 12px",
            m: "16px 8px 8px 8px",
            borderRadius: "8px",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "6px",
            },
          }}
        >
          {filteredMetaData.map((detail, index) => (
            <Box
              key={index}
              sx={{
                bgcolor: index % 2 === 0 ? "#f5f5f5" : "#fff",
                paddingLeft: "5px",
                marginBottom: "8px",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>
                <span style={{ fontWeight: "bold" }}>{detail.label}:</span>{" "}
                <span>{detail.value}</span>
              </Typography>
            </Box>
          ))}
          {checkedItems?.length === 0 ? (
            <Box
              dangerouslySetInnerHTML={{
                __html: caseDescriptionWithStyles,
              }}
            />
          ) : (
            <>
              <div>
                {checkedItems.map((key) => {
                  if (!caseItem[key]) return null;
                  return (
                    <div key={key} style={{ marginTop: "12px" }}>
                      <Typography
                        sx={{
                          color: "#303f9f",
                          fontSize: "14px",
                          fontWeight: "bold",
                          paddingLeft: "5px",
                        }}
                      >
                        {key
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}
                      </Typography>
                      <List>
                        {" "}
                        {caseItem[key].map((item, index) => (
                          <ListItem key={index}>
                            <ListItemText
                              primaryTypographyProps={{ fontSize: "14px" }}
                              primary={item}
                            />
                          </ListItem>
                        ))}{" "}
                      </List>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </Box>
      </React.Fragment>
    </Box>
  );
};

export default CaseDetail;
