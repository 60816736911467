import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  Slide as SlideAnimation,
  CircularProgress,
  Checkbox,
  Link,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./SignUpForm.css";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Slide, ToastContainer, toast } from "react-toastify";
import { signUp } from "../actions/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import InputMask from "inputmask";
import { phone } from "phone";
import lawgptLogo from "./../assets/images/lawgptLogo.png";
import backgroundImage from "./../assets/images/bgImage.png";
import TermsDialog from "../components/UI/TermsDialog";
import PrivacyPolicyDialog from "../components/UI/PrivacyPolicyDialog";

export default function SignUpForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cnicRef = useRef(null);

  const userSignup = useSelector((state) => state.userSignup);
  const { loading, message, error } = userSignup;

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [countryCode, setCountryCode] = useState("pk");
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [openPrivacyPolicyDialog, setOpenPrivacyPolicyDialog] = useState(false);

  const validatePhoneNumber = (inputNumber) => {
    if (!inputNumber) return "Phone Number is required";

    const { isValid } = phone(inputNumber, {
      country: countryCode,
    });
    return isValid ? true : "Invalid phone number format";
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const password = watch("password", "");

  const onSubmit = async (data) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      cnic,
      userType,
      organization,
      designation,
      officeAddress,
      barCouncil,
    } = data;

    try {
      dispatch(
        signUp(
          firstName,
          lastName,
          email,
          phoneNumber,
          password,
          cnic,
          userType,
          organization,
          designation,
          officeAddress,
          barCouncil
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const showPopup = () => {
    setDialogOpen(true);
  };

  const closePopup = () => {
    setDialogOpen(false);
    window.location.replace("https://lawgpt.pk");
  };

  useEffect(() => {
    if (cnicRef.current) {
      InputMask({ mask: "99999-9999999-9" }).mask(cnicRef.current);
    }
  }, []);

  useEffect(() => {
    if (message) {
      if (
        message === "CNIC already exists." ||
        message === "Email already exists." ||
        message === "Phone number already exists."
      ) {
        toast.error(message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });
      } else {
        showPopup();
      }
      dispatch({ type: "USER_SIGNUP_RESET" });
    }
    if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
    }
  }, [message, error, dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "50%",
          margin: "20px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "1.5rem",
          borderRadius: "16px",
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        }}
      >
        {/* Logo */}
        <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
          <img
            src={lawgptLogo}
            alt="product logo"
            style={{
              maxWidth: "150px",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
        <Typography align="center" sx={{ mb: 2, fontSize: "14px" }}>
          Register for our Early Access Beta Program! Be among the first to
          experience our application when it's released.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            alignSelf: "flex-start",
            fontWeight: "bold",
            color: "#003D78",
            position: "relative",
            mb: 2,
            ml: "8px",
            "&::before": {
              content: '""',
              position: "absolute",
              bottom: "-2px",
              left: 2,
              width: "60px",
              height: "4px",
              backgroundColor: "#2CC4EF",
              borderRadius: "2px",
            },
          }}
        >
          Create an Account
        </Typography>
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              label="First Name"
              {...register("firstName", {
                required: "First Name is required",
              })}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              FormHelperTextProps={{
                sx: { fontSize: "10px", marginTop: "2px" },
              }}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              label="Last Name"
              {...register("lastName", {
                required: "Last Name is required",
              })}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              FormHelperTextProps={{
                sx: { fontSize: "10px", marginTop: "2px" },
              }}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Email"
              variant="outlined"
              type="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address",
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
              FormHelperTextProps={{
                sx: { fontSize: "10px", marginTop: "2px" },
              }}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: "Phone Number is required",
                validate: (value) => validatePhoneNumber(value),
                pattern: {
                  value: /^[0-9\s+()-]+$/,
                  message: "Please enter a valid phone number",
                },
              }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  country={countryCode}
                  disableCountryCode={false}
                  disableSearchIcon
                  enableSearch={true}
                  placeholder="Enter phone number *"
                  value={field.value}
                  onChange={(phone, countryData) => {
                    field.onChange(phone);
                    setCountryCode(countryData.countryCode);
                  }}
                  inputProps={{
                    required: true,
                    autoFocus: true,
                  }}
                  inputStyle={{
                    width: "100%",
                    height: "36px",
                    fontSize: "14px",
                    backgroundColor: "white",
                    borderColor: errors.phoneNumber ? "red" : "#ced4da",
                    borderRadius: "4px",
                    paddingLeft: "48px",
                  }}
                  buttonStyle={{
                    borderColor: errors.phoneNumber ? "red" : "#ced4da",
                    backgroundColor: "white",
                  }}
                  containerStyle={{
                    width: "100%",
                  }}
                />
              )}
            />
            {errors.phoneNumber && (
              <Typography
                color="error"
                sx={{ fontSize: "10px", mt: "2px", ml: "14px" }}
              >
                {errors.phoneNumber.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long",
                },
              })}
              error={!!errors.password}
              helperText={errors.password?.message}
              FormHelperTextProps={{
                sx: { fontSize: "10px", marginTop: "2px" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{ color: "#003D78" }}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined fontSize="small" />
                      ) : (
                        <VisibilityOutlined fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Confirm Password"
              variant="outlined"
              type={showConfirmPassword ? "text" : "password"}
              {...register("confirmPassword", {
                required: "Please confirm your password",
                validate: (value) =>
                  value === password || "Passwords do not match",
              })}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              FormHelperTextProps={{
                sx: { fontSize: "10px", marginTop: "2px" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                      sx={{ color: "#003D78" }}
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffOutlined fontSize="small" />
                      ) : (
                        <VisibilityOutlined fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              inputRef={cnicRef}
              autoComplete="off"
              size="small"
              fullWidth
              label="CNIC"
              variant="outlined"
              {...register("cnic", {
                required: "CNIC is required",
                pattern: {
                  value: /^\d{5}-\d{7}-\d{1}$/,
                  message: "Please enter a valid CNIC (e.g., 12345-1234567-1)",
                },
                //onChange: handleCnicChange,
              })}
              error={!!errors.cnic}
              helperText={errors.cnic?.message}
              FormHelperTextProps={{
                sx: { fontSize: "10px", marginTop: "2px" },
              }}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="User Type"
              select
              variant="outlined"
              {...register("userType", {
                required: "User type is required",
                onChange: (e) =>
                  setValue("userType", e.target.value, {
                    shouldValidate: true,
                  }),
              })}
              error={!!errors.userType}
              helperText={errors.userType?.message}
              FormHelperTextProps={{
                sx: { fontSize: "10px", marginTop: "2px" },
              }}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      "& .MuiMenuItem-root": {
                        fontSize: "14px", // Apply font size to menu items
                      },
                    },
                  },
                },
              }}
              required
            >
              <MenuItem value="legal-practitioner">Legal Practitioner</MenuItem>
              <MenuItem value="law-student">Law Student</MenuItem>
              <MenuItem value="researcher">Researcher</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Organization (optional)"
              variant="outlined"
              {...register("organization")}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Designation (optional)"
              variant="outlined"
              {...register("designation")}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Office Address (optional)"
              variant="outlined"
              {...register("officeAddress")}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Bar Council"
              select
              variant="outlined"
              {...register("barCouncil", {
                required: "Bar Council is required",
                onChange: (e) =>
                  setValue("barCouncil", e.target.value, {
                    shouldValidate: true,
                  }),
              })}
              error={!!errors.barCouncil}
              helperText={errors.barCouncil?.message}
              FormHelperTextProps={{
                sx: { fontSize: "10px", marginTop: "2px" },
              }}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "14px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                      "& .MuiMenuItem-root": {
                        fontSize: "14px", // Apply font size to menu items
                      },
                    },
                  },
                },
              }}
              required
            >
              <MenuItem value="N/A">N/A</MenuItem>
              <MenuItem value="pakistan-bar-council">
                Pakistan Bar Council
              </MenuItem>
              <MenuItem value="punjab-bar-council">Punjab Bar Council</MenuItem>
              <MenuItem value="sindh-bar-council">Sindh Bar Council</MenuItem>
              <MenuItem value="khyber-pakhtunkhwa-bar-council">
                Khyber Pakhtunkhwa Bar Council
              </MenuItem>
              <MenuItem value="balochistan-bar-council">
                Balochistan Bar Council
              </MenuItem>
              <MenuItem value="islamabad-bar-council">
                Islamabad Bar Council
              </MenuItem>
              <MenuItem value="azad-jammu-kashmir-bar-council">
                Azad Jammu & Kashmir Bar Council
              </MenuItem>
              <MenuItem value="gilgit-baltistan-bar-council">
                Gilgit Baltistan Bar Council
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "12px",
            marginBottom: "4px",
          }}
        >
          <Checkbox
            size="small"
            {...register("termsAccepted", {
              required:
                "You must accept the Terms and Conditions and Privacy Policy.",
            })}
            sx={{
              color: "#00509e",
              "&.Mui-checked": {
                color: "#00509e",
              },
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              display: "inline",
              color: "#333",
            }}
          >
            I acknowledge that I have read and agree to the{" "}
            <Link
              href="#"
              onClick={() => setOpenTermsDialog(true)}
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#003D78",
                fontWeight: "bold",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Terms and Conditions{" "}
            </Link>
            and{" "}
            <Link
              href="#"
              onClick={() => setOpenPrivacyPolicyDialog(true)}
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#003D78",
                fontWeight: "bold",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Privacy Policy{" "}
            </Link>
            of this platform.
          </Typography>
        </Box>
        {errors.termsAccepted && (
          <Typography
            sx={{
              fontSize: "12px",
              color: "red",
              marginBottom: "4px",
              textAlign: "center",
            }}
          >
            {errors.termsAccepted.message}
          </Typography>
        )}
        <Button
          size="large"
          sx={{
            display: "flex",
            width: "35%",
            fontWeight: "bold",
            borderRadius: "8px",
            backgroundColor: "#003D78",
            //marginTop: 3,
            textTransform: "none",
            "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            transition: "background-color 0.3s ease-in-out",
          }}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {loading ? (
            <CircularProgress size={22} sx={{ color: "#fff" }} />
          ) : (
            "Register"
          )}
        </Button>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 1,
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Already have an account?{" "}
            <Typography
              component="span"
              variant="body2"
              sx={{ cursor: "pointer", color: "#003D78", fontWeight: "bold" }}
              onClick={() => navigate("/")}
            >
              Login
            </Typography>
          </Typography>
        </Box>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Slide"
      />
      {/* Success Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={closePopup}
        PaperProps={{
          sx: {
            borderRadius: "12px",
            padding: "20px",
            maxWidth: "500px",
          },
        }}
        TransitionComponent={SlideAnimation}
        TransitionProps={{ direction: "up", timeout: 500 }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          }}
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ width: "40px", height: "40px", color: "#4CAF50" }}
          />
          <DialogTitle
            sx={{
              color: "#003D78",
              fontWeight: "bold",
              fontSize: "24px",
              marginTop: "16px",
            }}
          >
            Account Created Successfully!
          </DialogTitle>
          <Typography
            variant="body1"
            gutterBottom
            textAlign="center"
            sx={{ fontSize: "16px", marginTop: "8px", color: "#333" }}
          >
            Thank you for joining LawGPT!
          </Typography>
          <Typography
            variant="body2"
            textAlign="center"
            sx={{
              color: "#555",
              fontSize: "14px",
              marginTop: "14px",
              textAlign: "center",
            }}
          >
            You're part of our beta program. Please verify your email using the
            link sent to your inbox. Check your spam folder if you don’t see it.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <Button
            onClick={closePopup}
            variant="contained"
            sx={{
              bgcolor: "#003D78",
              textTransform: "none",
              padding: "10px 24px",
              borderRadius: "8px",
              "&:hover": {
                bgcolor: "#002F60",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <TermsDialog
        open={openTermsDialog}
        onClose={() => setOpenTermsDialog(false)}
      />
      <PrivacyPolicyDialog
        open={openPrivacyPolicyDialog}
        onClose={() => setOpenPrivacyPolicyDialog(false)}
      />
    </Box>
  );
}
