import { faFloppyDisk, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";

const LawyerCard = ({ title, data, onEdit, editable, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(data);

  const handleEditClick = () => setIsEditing(true);
  const handleSaveClick = () => {
    setIsEditing(false);
    onSave(formData);
  };

  const handleChange = (index, key, value) => {
    setFormData((prev) => {
      // Create a new array with updated data for immutability
      const updatedLawyers = prev.map((lawyer, i) =>
        i === index ? { ...lawyer, [key]: value } : lawyer
      );

      return updatedLawyers; // Return the updated array
    });
  };

  return (
    <Card
      sx={{
        borderRadius: "10px",
        p: 1,
        maxHeight: 380,
        minHeight: 380,
        overflowY: "auto",
      }}
    >
      <CardContent sx={{ p: 0 }}>
        <Box
          sx={{
            bgcolor: "#003D78",
            color: "#FFFFFF",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            p: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"1rem"}>{title}</Typography>
          <Button
            onClick={isEditing ? handleSaveClick : handleEditClick}
            color="primary"
            sx={{
              bgcolor: "#FFFFFF",
              minWidth: "30px", // Ensures a consistent width
              minHeight: "30px", // Ensures a consistent height
              padding: 0, // Removes extra padding inside the button
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: 1, // Adds slight elevation for better visuals
              "&:hover": {
                bgcolor: "#FFFFFF", // Keeps the background color unchanged
                cursor: "pointer", // Shows the pointer cursor on hover
              },
            }}
          >
            <FontAwesomeIcon
              icon={isEditing ? faFloppyDisk : faPenToSquare}
              style={{ fontSize: "16px", color: "#003D78" }} // Adjusts icon size for better fit
            />
          </Button>
        </Box>

        <CardContent>
          <Stack spacing={1}>
            {formData.map((lawyer, index) => (
              <Box key={index}>
                {/* Lawyer Details */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  {/* Fixed-width Key */}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#303f9f",
                      width: "100px", // Fixed width for the label
                      flexShrink: 0, // Prevent shrinking of the label
                      fontSize: "13px",
                    }}
                  >
                    Lawyer {index + 1}:
                  </Typography>
                  {/* Editable or Non-editable Value */}
                  {isEditing ? (
                    <TextField
                      size="small"
                      value={lawyer.name}
                      onChange={(e) =>
                        handleChange(index, "name", e.target.value)
                      }
                      sx={{
                        ml: 1,
                        width: "60%",
                        "& .MuiInputBase-input": {
                          padding: "2px", // Removes padding inside the input field
                          paddingLeft: "5px",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "2px", // Removes padding around the input field
                          paddingLeft: "5px",
                        },
                      }}
                      InputProps={{
                        sx: {
                          bgcolor: "white",
                          fontSize: "13px",
                          "& input:-webkit-autofill": {
                            backgroundColor: "white !important",
                          },
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "#303f9f", fontSize: "13px" }}
                      component="span"
                      fontWeight="normal"
                    >
                      {lawyer.name}
                    </Typography>
                  )}
                </Box>

                {/* Lawyer Designation */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Fixed-width Key */}
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{
                      width: "100px", // Fixed width for the label
                      flexShrink: 0, // Prevent shrinking of the label
                      fontSize: "13px",
                    }}
                  >
                    Designation:
                  </Typography>
                  {/* Editable or Non-editable Value */}
                  {isEditing ? (
                    <TextField
                      size="small"
                      value={lawyer.designation}
                      onChange={(e) =>
                        handleChange(index, "designation", e.target.value)
                      }
                      sx={{
                        ml: 1,
                        width: "60%",
                        "& .MuiInputBase-input": {
                          paddingY: "2px", // Removes padding inside the input field
                          paddingLeft: "5px",
                        },
                        "& .MuiOutlinedInput-root": {
                          paddingY: "2px", // Removes padding around the input field
                          paddingLeft: "5px",
                        },
                      }}
                      InputProps={{
                        sx: {
                          bgcolor: "white",
                          fontSize: "13px",
                          "& input:-webkit-autofill": {
                            backgroundColor: "white !important",
                          },
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      component="span"
                      fontWeight="normal"
                      fontSize="13px"
                    >
                      {lawyer.designation}
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
          </Stack>
        </CardContent>
      </CardContent>
    </Card>
  );
};

export default LawyerCard;
