import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

// Custom styled components
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  width: "100%",
  maxWidth: "400px", // Add maximum width
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s",
  cursor: "pointer",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[6],
  },
  [theme.breakpoints.up("md")]: {
    minHeight: 400,
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: "100%",
    maxWidth: "100%",
  },
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#f5f5f5" : "#2d3843",
  padding: theme.spacing(3),
  borderTop: "7px solid #003D78",
}));

const FeatureList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyle: "none",
});

const Feature = styled("li")(({ theme }) => ({
  margin: theme.spacing(1, 0),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

// Plan data
const plans = [
  {
    title: "Free Plan",
    price: "Free",
    description: "Get started with basic features",
    features: [
      "50 Credits per Month",
      "1 User Seat",
      "No Client Monitoring",
      "Limited Features (Statute Chat, Case Chat, Voice Input/Output, etc.)",
    ],
    buttonText: "Coming Soon",
    buttonVariant: "outlined",
  },
  {
    title: "Standard Plan",
    price: "2,500 PKR",
    description: "Perfect for individual practitioners",
    features: [
      "500 Credits per Month",
      "1 User Seat",
      "No Client Monitoring",
      "Access to Statute Chat, Case Chat, Legal Library, and more",
    ],
    buttonText: "Coming Soon",
    buttonVariant: "contained",
  },
  {
    title: "Professional Plan",
    price: "3,500 PKR",
    description: "Ideal for small law firms",
    features: [
      "1,000 Credits per Month",
      "1 User Seat (Expandable up to 3 Clients)",
      "Client Monitoring Available",
      "Full Access: AI Drafting, Case Management, etc.",
    ],
    buttonText: "Coming Soon",
    buttonVariant: "contained",
  },
  {
    title: "Enterprise Plan",
    price: "20,000 PKR",
    description: "Custom solutions for large organizations",
    features: [
      "Unlimited Credits",
      "7 User Seats (Expandable)",
      "25 Clients Monitoring",
      "Full Access: Customization, AI Drafting, etc.",
    ],
    buttonText: "Coming Soon",
    buttonVariant: "contained",
  },
  {
    title: "Pay Per User Custom Plan",
    price: "Custom Pricing",
    description: "Build your own plan",
    features: [
      "Flexible Credits and Seats",
      "Custom Client Monitoring",
      "Full Access to All Features",
      "Customized to Your Needs",
    ],
    buttonText: "Coming Soon",
    buttonVariant: "contained",
  },
];

export default function SubscriptionPlans() {
  return (
    <Container
      maxWidth={false}
      sx={{
        py: 3,
        height: "calc(100vh - 50px)",
        width: "calc(100vw - 45px)",
        overflowY: "auto",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#fff",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#bbb",
          borderRadius: "6px",
        },
      }}
    >
      <Box
        textAlign="center"
        mb={2}
        sx={{
          width: "100%",
        }}
      >
        <Typography
          component="h2"
          //variant="h4"
          color="text.primary"
          gutterBottom
          sx={{
            fontSize: "30px",
            fontWeight: 700,
            color: "#303f9f",
          }}
        >
          Choose Your Plan
        </Typography>
        <Typography
          //variant="h6"
          color="text.secondary"
          sx={{ maxWidth: "800px", mx: "auto", fontSize: "18px" }}
        >
          Explore our plans and pick the one that best suits your legal needs.
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: "center",
          alignItems: "stretch",
          maxWidth: "1200px", // Add maximum width to container
          mx: "auto", // Center the container
        }}
      >
        {plans.map((plan) => (
          <Grid
            item
            key={plan.title}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            sx={{
              display: "flex",
              justifyContent: "center", // Center the card within its grid item
            }}
          >
            <StyledCard>
              <StyledCardHeader
                title={
                  <Typography
                    //variant="h5"
                    component="h2"
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {plan.title}
                  </Typography>
                }
                subheader={
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      //variant="h4"
                      color="primary"
                      sx={{ fontWeight: "bold", fontSize: "24px" }}
                    >
                      {plan.price}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      per month
                    </Typography>
                  </Box>
                }
                titleTypographyProps={{ align: "center" }}
                subheaderTypographyProps={{ align: "center" }}
              />
              <CardContent sx={{ flexGrow: 1, pt: 2 }}>
                {/* <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  align="center"
                  sx={{ mb: 3 }}
                >
                  {plan.description}
                </Typography> */}
                <FeatureList>
                  {plan.features.map((feature) => (
                    <Feature key={feature}>
                      <CheckIcon color="primary" />
                      <Typography variant="body2">{feature}</Typography>
                    </Feature>
                  ))}
                </FeatureList>
              </CardContent>
              <CardActions sx={{ p: 3, pt: 0 }}>
                <Button
                  fullWidth
                  variant="contained"
                  //color="primary"
                  size="large"
                  sx={{
                    backgroundColor: "#003D78",
                    borderRadius: 2,
                    textTransform: "none",
                    fontWeight: 600,
                    cursor: "not-allowed",
                  }}
                >
                  {plan.buttonText}
                </Button>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          mt: "3rem",
          boxSizing: "border-box",
          // backgroundColor: "#f5f5f5",
          //borderTop: "1px solid #ccc",
          // border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "8px 0",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontStyle: "italic",
            // animation: "scroll-left 25s linear infinite",
            // color: "#333",
          }}
        >
          <Typography sx={{ fontWeight: "600", display: "inline" }}>
            Important Notice:
          </Typography>{" "}
          The subscription plans displayed reflect our future pricing structure.
          Current access during the beta phase is provided under temporary terms
          for evaluation purposes.
        </Typography>

        {/* CSS Keyframes */}
        {/* <style>
          {`
          @keyframes scroll-left {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}
        </style> */}
      </Box>
    </Container>
  );
}
