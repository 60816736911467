import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  List,
  ListItem,
  Typography,
  IconButton,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Select,
  ListItemText,
} from "@mui/material";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import CloseIcon from "@mui/icons-material/Close";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { KeyboardVoice } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/free-solid-svg-icons";

import {
  searchSections,
  statuteSearchResults,
} from "../actions/statuteSearchActions";
import InfoDialog from "./UI/InfoDialog";
import config from "../config/api";
import StatutePdfViewer from "./StatutePdfViewer";
import StatuteDetailDialog from "./StatuteDetailDialog";
import { showInsufficientCreditsModal } from "../actions/creditActions";
import { deductCredits } from "../utils/HelperFunction/deductCredits";
import { fetchBookmarkGroups } from "../store/Bookmarks/bookmarksActions";

const deleteCaseUrl =
  "https://rg-law-gpt-be-auth2.azurewebsites.net/api/deleteCaseOrStatuteFromGroup";

const StatuteSearch = ({
  selectedLetter,
  selectedYear,
  searchMode,
  resultsCount,
  updateResultsCount,
  currentPage,
  updateCurrentPage,
  statuteSearchValue,
  updateStatuteSearchValue,
}) => {
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);
  const [selectedStatute, setSelectedStatute] = useState(null);
  const [bookmarkGroupName, setBookmarkGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [statuteNote, setStatuteNote] = useState("");
  const [statuteNoteError, setStatuteNoteError] = useState("");
  const [bookmarkedStatuteIds, setBookmarkedStatuteIds] = useState(new Set());
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [placeholder, setPlaceholder] = useState("Search for statutes...");
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [isStatuteSearch, setIsStatuteSearch] = useState(false);
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [openSectionsDialog, setOpenSectionsDialog] = useState(false);
  const [isMount, setIsMount] = useState(false);
  const credits = useSelector((state) => state.credit.credits);
  const userId = useSelector((state) => state.credit.userId);
  const isInitialSearchDone = useRef(false);

  const silenceTimeoutRef = useRef(null);
  const finalTranscriptRef = useRef("");

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const bookmarkGroups = useSelector((state) => state.bookmarkGroups);
  const { groups } = bookmarkGroups;

  const statuteSearch = useSelector((state) => state.statuteSearch);
  const { loading: statuteLoading, results } = statuteSearch;

  const isStatuteFilterApplied = useSelector(
    (state) => state.isStatuteFilterApplied
  );
  const { isStatuteSearchFilterApplied } = isStatuteFilterApplied;

  const totalResults = results?.count || 0;

  const handleClickInfoDialog = () => {
    setOpenInfoDialog(true);
    setIsStatuteSearch(true);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
    //setIsStatuteSearch(false);
  };

  const handlePrimarySearchValueChange = (e) => {
    updateStatuteSearchValue(e.target.value);
  };

  const formatStatuteTitleHtml = (htmlString) => {
    if (!htmlString) return "";

    // Parse the HTML string into a DOM element
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Function to apply title case to each word
    const titleCaseText = (text) => {
      return text
        .toLowerCase()
        .split(" ")
        .map((word) => {
          // Preserve acronyms (fully uppercase words)
          if (word.length === 1 || word === word.toUpperCase()) {
            return word;
          }
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    };

    // Function to traverse the DOM and apply title case to text nodes only
    const traverseAndTitleCase = (node) => {
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          // Only apply title case to the text content, not HTML tags
          child.textContent = titleCaseText(child.textContent);
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          // Recurse through element nodes
          traverseAndTitleCase(child);
        }
      });
    };

    // Start traversal and title case application
    traverseAndTitleCase(doc.body);

    // Return the modified HTML string
    return doc.body.innerHTML;
  };

  const formatStatuteTitle = (title) => {
    if (!title) return;
    const titleCaseTitle = title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return titleCaseTitle;
  };

  const handlePageChange = (event, value) => {
    updateCurrentPage(value);
    dispatch({
      type: "STATUTE_PAGINATION_SUCCESS",
      payload: { pageNumber: value, resultsCount },
    });
  };

  const handleNoteChange = (value) => {
    if (value.length <= 2000) {
      setStatuteNote(value);
      setStatuteNoteError("");
    } else {
      setStatuteNoteError("You can only input 2000 characters");
    }
  };

  const handleBookmarkClick = (statute) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    setSelectedStatute(statute);
    const defaultGroup = groups?.find(
      (group) => group.groupName === "Bookmarks"
    );
    setSelectedGroup(defaultGroup?._id);
    //fetchBookmarkGroups();
    setOpenBookmarkModal(true);
    const deductionSuccess = deductCredits(
      { userId, creditsToDeduct: 1, event: "Bookmark on Statute Page" },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleCloseBookmarkModal = () => {
    setOpenBookmarkModal(false);
    setSelectedGroup("");
    setStatuteNote("");
    setStatuteNoteError("");
  };

  // const fetchBookmarkGroups = async () => {
  //   try {
  //     const userId = userInfo._id;
  //     const response = await fetch(
  //       `${config.backendUrl}/getUserBookmarkGroups?userId=${userId}`
  //     );
  //     const data = await response.json();
  //     if (response.ok) {
  //       // Extract all statute IDs from the statutes array in each group document
  //       const allStatuteIds = data.flatMap((group) =>
  //         group.statutes.map((statute) => statute.id)
  //       );

  //       // Set the bookmark groups and statute IDs
  //       setBookmarkedStatuteIds(new Set(allStatuteIds)); // Store IDs in a Set for fast lookup
  //     } else {
  //       throw new Error(data.message || "Failed to fetch bookmark groups.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching bookmark groups:", error);
  //   }
  // };

  const handleCreateBookmarkGroup = async () => {
    if (!bookmarkGroupName) return;
    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }
      const userId = userInfo._id;
      const response = await fetch(`${config.backendUrl}/createBookmarkGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          groupName: bookmarkGroupName,
          statuteId: selectedStatute.doc_id,
          statuteName: selectedStatute.Act_Ordinance,
          statuteNote,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${bookmarkGroupName}`,
        });
        setBookmarkedStatuteIds(
          (prevIds) => new Set([...prevIds, selectedStatute.doc_id])
        );

        setOpenBookmarkModal(false);
        setBookmarkGroupName("");
        setStatuteNote("");
        dispatch(fetchBookmarkGroups(userInfo._id));
        // Deduct credits using the helper
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct: 1, event: "Create Bookmark Group" },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      } else {
        throw new Error(data.message || "Failed to create bookmark group.");
      }
    } catch (error) {
      console.error("Error creating bookmark group:", error);
    }
  };

  const handleAddStatuteToGroup = async () => {
    if (!selectedGroup) return;
    try {
      const userId = userInfo._id;
      const response = await fetch(`${config.backendUrl}/addStatuteToGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          groupId: selectedGroup,
          statuteId: selectedStatute.doc_id,
          statuteName: selectedStatute.Act_Ordinance,
          statuteNote,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${
            groups?.find((group) => group._id === selectedGroup).groupName
          }`,
        });
        setBookmarkedStatuteIds(
          (prevIds) => new Set([...prevIds, selectedStatute.doc_id])
        );

        setOpenBookmarkModal(false);
        setStatuteNote(""); // Clear notes after submission
      } else {
        throw new Error(data.message || "Failed to add statute to group.");
      }
    } catch (error) {
      console.error("Error adding statute to group:", error);
    }
  };

  const findGroupIdByStatuteId = (documents, statuteId) => {
    const group = documents.find((doc) =>
      doc.statutes.some((statuteItem) => statuteItem.id === statuteId)
    );

    return group ? group._id : null;
  };

  const handleUnBookmarkStatute = async (statuteId) => {
    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }
      //fetchBookmarkGroups();
      const groupId = findGroupIdByStatuteId(groups, statuteId);
      const response = await fetch(deleteCaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id, // User ID from context or state
          groupId, // Group ID where the statute is bookmarked
          statuteId, // Statute ID to delete
        }),
      });

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: "Bookmark removed",
        });
        // Update the state by removing the statute ID from bookmarked statute IDs
        setBookmarkedStatuteIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(statuteId);
          return updatedIds;
        });
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct: 1, event: "UnBookmark on Statute Page" },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      } else {
        console.error("Error deleting bookmarked statute");
      }
    } catch (error) {
      console.error("Error deleting bookmarked statute:", error);
    }
  };

  const handleStatuteNameClick = (name) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    const cleanedName = name.replace(/<\/?[^>]+(>|$)/g, "");
    dispatch(searchSections("", cleanedName, searchMode));
    setOpenSectionsDialog(true);
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Sections button click on statute page",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleSearchClick = (keyword = statuteSearchValue) => {
    if (!keyword) return;
    setIsSearchClicked(true);
    dispatch(
      statuteSearchResults(
        statuteSearchValue,
        searchMode,
        resultsCount,
        (currentPage - 1) * resultsCount,
        { year: selectedYear, letter: selectedLetter }
      )
    );
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      if (!statuteSearchValue) {
        return;
      } else {
        const pageNumber = 1;
        updateCurrentPage(1);
        setIsSearchClicked(true);
        dispatch(
          statuteSearchResults(
            statuteSearchValue,
            searchMode,
            resultsCount,
            (pageNumber - 1) * resultsCount,
            { year: selectedYear, letter: selectedLetter }
          )
        );
      }
    }
  };

  const recognition = new window.webkitSpeechRecognition();
  recognition.continuous = true;
  recognition.interimResults = true;
  recognition.lang = "en-US";

  recognition.onresult = (event) => {
    const transcript = Array.from(event.results)
      .map((result) => result[0].transcript)
      .join("");
    updateStatuteSearchValue(transcript);
    finalTranscriptRef.current = transcript;
    resetSilenceTimer();
  };

  recognition.onerror = (event) => {
    console.error(event.error);
    stopListening();
  };

  const startListening = () => {
    setIsListening(true);
    setPlaceholder("Speak...");
    recognition.start();

    startSilenceTimer();
  };

  const stopListening = () => {
    setIsProcessing(true);
    recognition.stop();
  };

  recognition.onend = () => {
    setIsListening(false);
    setIsProcessing(false);
    setPlaceholder("Search for case law...");
    clearSilenceTimer();
    if (finalTranscriptRef.current) {
      handleSearchClick(finalTranscriptRef.current);
    }
  };

  const toggleListening = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const startSilenceTimer = () => {
    silenceTimeoutRef.current = setTimeout(() => {
      stopListening();
    }, 3000);
  };

  const resetSilenceTimer = () => {
    clearTimeout(silenceTimeoutRef.current);
    startSilenceTimer();
  };

  const clearSilenceTimer = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };

  useEffect(() => {
    if (!isInitialSearchDone.current) {
      dispatch(
        statuteSearchResults(
          statuteSearchValue,
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount,
          { year: selectedYear, letter: selectedLetter }
        )
      );
      isInitialSearchDone.current = true;
      setIsMount(true);
    }
  }, [currentPage, statuteSearchValue, searchMode, resultsCount, dispatch]);

  useEffect(() => {
    if (!isStatuteSearchFilterApplied && isMount) {
      dispatch(
        statuteSearchResults(
          statuteSearchValue,
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount,
          { year: selectedYear, letter: selectedLetter }
        )
      );
    }
  }, [currentPage, resultsCount]);

  // Default search results on clearing search query
  useEffect(() => {
    if (!statuteSearchValue && isSearchClicked) {
      dispatch(
        statuteSearchResults(
          "",
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount,
          { year: selectedYear, letter: selectedLetter }
        )
      );
      setIsSearchClicked(false);
    }
  }, [
    dispatch,
    statuteSearchValue,
    isSearchClicked,
    searchMode,
    resultsCount,
    currentPage,
  ]);

  useEffect(() => {
    // Fetch bookmark groups when the component mounts or when userInfo changes
    if (userInfo._id) {
      dispatch(fetchBookmarkGroups(userInfo._id));
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (groups) {
      // Extract all statute IDs from the statutes array in each group document
      const allStatuteIds = groups?.flatMap((group) =>
        group.statutes.map((statute) => statute.id)
      );

      // Set the bookmark groups and statute IDs
      setBookmarkedStatuteIds(new Set(allStatuteIds)); // Store IDs in a Set for fast lookup
      const defaultGroup = groups?.find(
        (group) => group.groupName === "Bookmarks"
      );
      setSelectedGroup(defaultGroup?._id);
    }
  }, [groups]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          boxSizing: "border-box",
          flexDirection: "column",
          height: "calc(100vh - 50px)",
          width: "80%",
          flex: 5,
          p: "12px 12px 0 4px",
          paddingBottom: "4px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            padding: "8px",
            borderRadius: "8px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TextField
              variant="outlined"
              autoFocus
              placeholder={placeholder}
              value={statuteSearchValue}
              onChange={handlePrimarySearchValueChange}
              onKeyDown={handleEnter}
              fullWidth
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "20px",
                mx: "8px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#ccc",
                  },
                  "&:hover fieldset": {
                    borderColor: "#00509e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#00509e",
                  },
                },
                "& input": {
                  padding: "10px 10px",
                  fontSize: "0.875rem",
                },
              }}
              InputProps={{
                sx: { borderRadius: 8, p: 0 },
                startAdornment: (
                  <SearchIcon sx={{ color: "#303f9f", padding: "8px" }} />
                ),
                endAdornment: (
                  <IconButton
                    sx={{
                      color: isListening ? "#FF4081" : "#303f9f",
                      animation: isListening ? "pulse 1.5s infinite" : "none",
                    }}
                    variant="contained"
                    onClick={toggleListening}
                    disabled={isProcessing}
                  >
                    <KeyboardVoice />
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Box>
        {results?.count === 0 ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                fontSize: "50px",
                marginBottom: "10px",
                color: "#303f9f",
              }}
            >
              <SearchIcon fontSize="inherit" />
            </Box>
            <Typography
              variant="h5"
              sx={{ fontSize: "24px", marginBottom: "10px" }}
            >
              No Results Found
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", color: "#666" }}
            >
              Please review your search term or filter criteria.
            </Typography>
          </Box>
        ) : statuteLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <CircularProgress size={18} />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                m: "12px 0",
              }}
            >
              <Typography
                sx={{ ml: 0.5, fontWeight: "bold", fontSize: "18px" }}
              >
                <span style={{ color: "#d2042d", fontWeight: "bold" }}>
                  {totalResults.toLocaleString()}
                </span>{" "}
                {totalResults === 9981 ? "Total Statutes" : "Statutes Found"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputLabel
                  sx={{
                    marginRight: "4px",
                    fontSize: "0.875rem",
                    color: "#555",
                    fontWeight: "500",
                  }}
                  htmlFor="resultsCount"
                >
                  Show:
                </InputLabel>
                <FormControl
                  variant="outlined"
                  sx={{
                    width: 100,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "6px",
                      height: "32px",
                      padding: "8px",
                    },
                    "& fieldset": {
                      borderColor: "#ddd",
                      borderWidth: "1px",
                    },
                    "& .MuiSelect-icon": {
                      right: "4px",
                    },
                    "& .MuiSelect-select": {
                      padding: "0px",
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                >
                  <Select
                    id="resultsCount"
                    value={resultsCount}
                    onChange={(e) => updateResultsCount(e.target.value)}
                    sx={{
                      fontSize: "0.875rem",
                      height: "32px",
                      bgcolor: "#fff",
                    }}
                  >
                    <MenuItem value={10} sx={{ fontSize: "0.875rem" }}>
                      10 results
                    </MenuItem>
                    <MenuItem value={20} sx={{ fontSize: "0.875rem" }}>
                      20 results
                    </MenuItem>
                    <MenuItem value={50} sx={{ fontSize: "0.875rem" }}>
                      50 results
                    </MenuItem>
                    {/* <MenuItem value={100} sx={{ fontSize: "0.875rem" }}>
                      100 results
                    </MenuItem> */}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <List
              sx={{
                flexGrow: 1,
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "8px",
                overflowY: "auto",
                scrollBehavior: "smooth",
                "::-webkit-scrollbar": {
                  width: "5px",
                },
                "::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#bbb",
                  borderRadius: "6px",
                },
              }}
            >
              {results &&
                results?.["search_results"]?.map((statute) => (
                  <ListItem
                    key={statute.id}
                    sx={{
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        p: 1,
                        width: "100%",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        transform: "translateY(-2px)",
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              color: "#303f9f",
                              ml: 0.5,
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                statute?.highlight?.Act_Ordinance?.length > 0
                                  ? statute?.highlight?.Act_Ordinance?.[0]
                                  : statute?.Act_Ordinance
                                  ? formatStatuteTitle(statute.Act_Ordinance)
                                  : "",
                            }}
                          />
                        }
                        secondary={
                          <>
                            <Typography
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                overflow: "hidden",
                                bgcolor: "#f5f5f5",
                                color: "black",
                                p: "8px 8px 0px 8px",
                                mt: 1,
                                borderRadius: "4px",
                                fontSize: "13px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  statute?.highlight?.Statute?.length > 0
                                    ? statute?.highlight?.Statute?.map(
                                        (text) => text
                                      )
                                    : statute
                                    ? statute?.Statute
                                    : "",
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                mt: 1,
                                gap: 1,
                              }}
                            >
                              {/* <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  dispatch({
                                    type: "STATUTE_PDF_PREVIEW_SUCCESS",
                                    payload: samplePdf,
                                  });
                                  setOpenPdfPreview(true);
                                }}
                              >
                                <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                  <MenuBookIcon
                                    sx={{ color: "#4CAF50" }}
                                    fontSize="small"
                                  />
                                </IconButton>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    mr: 0.5,
                                    color: "#303f9f",
                                    cursor: "pointer",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  Read
                                </Typography>
                              </Box> */}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                  <FontAwesomeIcon
                                    icon={faRectangleList}
                                    color="#ff9800"
                                    fontSize="16px"
                                  />
                                </IconButton>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    mr: 0.5,
                                    color: "#303f9f",
                                    cursor: "pointer",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={() => {
                                    handleStatuteNameClick(
                                      statute.Act_Ordinance
                                    );
                                  }}
                                >
                                  Sections
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  sx={{
                                    p: 0,
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  {bookmarkedStatuteIds.has(statute.doc_id) ? (
                                    <TurnedInIcon
                                      onClick={() =>
                                        handleUnBookmarkStatute(statute.doc_id)
                                      }
                                      sx={{ color: "#d2042d" }}
                                      fontSize="small"
                                    />
                                  ) : (
                                    <TurnedInNotIcon
                                      onClick={() =>
                                        handleBookmarkClick(statute)
                                      }
                                      sx={{ color: "#d2042d" }}
                                      fontSize="small"
                                    />
                                  )}
                                </IconButton>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    ml: 0.5,
                                    color: "#303f9f",
                                    cursor: "pointer",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={() =>
                                    bookmarkedStatuteIds.has(statute.doc_id)
                                      ? handleUnBookmarkStatute(statute.doc_id)
                                      : handleBookmarkClick(statute)
                                  }
                                >
                                  Bookmark
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        }
                      />
                    </Box>
                  </ListItem>
                ))}
            </List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Pagination
                count={Math.ceil(totalResults / resultsCount)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  m: "5px 0",
                  "& .MuiPaginationItem-root": {
                    fontSize: "13px",
                    minWidth: "24px",
                    height: "24px",
                    "&:hover": {
                      color: "#fff",
                      backgroundColor: "#303f9f",
                    },
                    "&.Mui-selected": {
                      color: "#fff",
                      backgroundColor: "#213165",
                      "&:hover": {
                        backgroundColor: "#303f9f",
                      },
                    },
                  },
                }}
              />
              {snackbarMessage && (
                <Snackbar
                  open={!!snackbarMessage}
                  autoHideDuration={6000}
                  onClose={() => setSnackbarMessage(null)}
                >
                  <Alert
                    onClose={() => setSnackbarMessage(null)}
                    severity={snackbarMessage.type}
                    sx={{ width: "100%" }}
                  >
                    {snackbarMessage.text}
                  </Alert>
                </Snackbar>
              )}
            </Box>
          </>
        )}
      </Box>
      <StatuteDetailDialog
        open={openSectionsDialog}
        onClose={() => setOpenSectionsDialog(false)}
      />
      <StatutePdfViewer
        open={openPdfPreview}
        onClose={() => setOpenPdfPreview(false)}
      />
      <Dialog
        open={openBookmarkModal}
        onClose={handleCloseBookmarkModal}
        sx={{
          "& .MuiModal-root": {
            outline: "none",
          },
          "& .MuiPaper-root": {
            width: "400px",
            borderRadius: 2,
            padding: 2,
            boxShadow: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            p: 0.5,
            color: "#303f9f",
            fontSize: "18px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Bookmark Statute
          <IconButton
            color="error"
            onClick={handleCloseBookmarkModal}
            sx={{
              padding: 0,
              color: "gray",
              "&:hover": { color: "#d32f2f" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            width: "100%",
            overflow: "visible",
            padding: 0,
            marginTop: 1,
          }}
        >
          <TextField
            sx={{
              mt: 1,
              mb: 1,
              bgcolor: "white",
              "& .MuiInputBase-root": {
                fontSize: "13px",
              },
            }}
            label="Add to existing group"
            select
            fullWidth
            size="small"
            value={selectedGroup}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === "create-new") {
                setBookmarkGroupName("");
              }
              setSelectedGroup(selectedValue);
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          >
            <MenuItem
              sx={{
                color: "#303f9f",
                fontSize: "13px",
                fontWeight: "bold",
              }}
              value="create-new"
            >
              Create New Group
            </MenuItem>
            {groups
              ?.sort((a, b) =>
                a.groupName === "Bookmarks"
                  ? -1
                  : b.groupName === "Bookmarks"
                  ? 1
                  : 0
              )
              ?.map((group) => (
                <MenuItem
                  sx={{
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  key={group._id}
                  value={group._id}
                >
                  {group.groupName}
                </MenuItem>
              ))}
          </TextField>

          {/* Conditionally render the input field if "Create New Group" is selected */}
          {selectedGroup === "create-new" && (
            <TextField
              label="New Group Name"
              fullWidth
              size="small"
              value={bookmarkGroupName}
              onChange={(e) => setBookmarkGroupName(e.target.value)}
              sx={{
                mt: 2,
                mb: 2,
                bgcolor: "white",
                "& .MuiInputBase-root": {
                  fontSize: "13px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                },
              }}
            />
          )}
          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            multiline
            rows={3}
            value={statuteNote}
            error={Boolean(statuteNoteError)}
            helperText={statuteNoteError}
            onChange={(e) => handleNoteChange(e.target.value)}
            sx={{
              mt: 1,
              bgcolor: "white",
              "& .MuiInputBase-root": {
                fontSize: "13px",
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            mt: 1,
            justifyContent: "flex-end",
            padding: "8px 0px 8px 8px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleCloseBookmarkModal}
            color="error"
            sx={{
              borderRadius: "8px",
              backgroundColor: "#d32f2f",
              textTransform: "none",
              fontSize: "14px",
              "&:hover": { backgroundColor: "#b71c1c" },
              transition: "background-color 0.3s ease-in-out",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              backgroundColor: "#003D78",
              textTransform: "none",
              fontSize: "14px",
              "&:hover": { backgroundColor: "primary.main" },
              transition: "background-color 0.3s ease-in-out",
            }}
            onClick={
              selectedGroup === "create-new"
                ? handleCreateBookmarkGroup
                : handleAddStatuteToGroup
            }
            disabled={
              selectedGroup === "create-new"
                ? !bookmarkGroupName
                : !selectedGroup || statuteNoteError
            }
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <InfoDialog
        open={openInfoDialog}
        handleClose={handleCloseInfoDialog}
        isStatuteSearch={isStatuteSearch}
      />
    </>
  );
};

export default StatuteSearch;
