import {
  FETCH_BOOKMARK_GROUPS_REQUEST,
  FETCH_BOOKMARK_GROUPS_SUCCESS,
  FETCH_BOOKMARK_GROUPS_FAILURE,
  FETCH_BOOKMARK_GROUPS_RESET,
  EDIT_GROUP_TITLE,
  DELETE_GROUP,
  FETCH_CASES_REQUEST,
  FETCH_CASES_SUCCESS,
  FETCH_CASES_FAILURE,
  FETCH_STATUTES_REQUEST,
  FETCH_STATUTES_SUCCESS,
  FETCH_STATUTES_FAILURE,
  UPDATE_BOOKMARK_CASE_NOTES,
  UPDATE_NOTES_IN_GROUP,
  REMOVE_BOOKMARK_CASE_NOTES,
  UPDATE_BOOKMARK_STATUTE_NOTES,
  REMOVE_BOOKMARK_STATUTE_NOTES,
  COPY_BOOKMARK_TO_GROUP,
  DELETE_BOOKMARK_CASE,
  DELETE_BOOKMARK_STATUTE,
  DELETE_BOOKMARK_FROM_GROUP,
  FETCH_CASES_RESET,
  FETCH_STATUTES_RESET,
} from "./bookmarksTypes";

const bookmarkGroupsInitialState = {
  groups: [],
  loading: false,
  error: null,
};

const bookmarkCasesInitialState = {
  cases: [],
  loadingCases: false,
  error: null,
};

const bookmarkStatutesInitialState = {
  statutes: [],
  loadingStatutes: false,
  error: null,
};

export const bookmarkGroupsReducer = (
  state = bookmarkGroupsInitialState,
  action
) => {
  switch (action.type) {
    case FETCH_BOOKMARK_GROUPS_REQUEST:
      return { ...state, loading: state?.groups?.length === 0, error: null };

    case FETCH_BOOKMARK_GROUPS_SUCCESS:
      return { ...state, loading: false, groups: action.payload };

    case EDIT_GROUP_TITLE:
      return {
        ...state,
        groups: state.groups?.map((group) =>
          group._id === action.payload.groupId
            ? { ...group, groupName: action.payload.newGroupName }
            : group
        ),
      };

    case DELETE_GROUP:
      return {
        ...state,
        groups: state.groups?.filter(
          (group) => group._id !== action.payload.groupId
        ),
      };

    case UPDATE_NOTES_IN_GROUP:
      return {
        ...state,
        groups: state.groups.map((group) =>
          group._id === action.payload.groupId
            ? {
                ...group,
                [action.payload.itemType === "case" ? "cases" : "statutes"]:
                  group[
                    action.payload.itemType === "case" ? "cases" : "statutes"
                  ].map((item) =>
                    item.id === action.payload.itemId
                      ? { ...item, note: action.payload.note }
                      : item
                  ),
              }
            : group
        ),
      };

    case DELETE_BOOKMARK_FROM_GROUP:
      return {
        ...state,
        groups: state.groups.map((group) =>
          group._id === action.payload.groupId
            ? {
                ...group,
                [action.payload.itemType === "case" ? "cases" : "statutes"]:
                  group[
                    action.payload.itemType === "case" ? "cases" : "statutes"
                  ].filter((item) => item.id !== action.payload.itemId),
              }
            : group
        ),
      };

    case COPY_BOOKMARK_TO_GROUP: {
      const { groupId, selectedItem } = action.payload;

      const getNewItem = (item) => {
        if (item.type === "case") {
          return {
            id: item.doc_id,
            addedAt: item.addedAt,
            note: item.note || "",
          };
        } else if (item.type === "statute") {
          return {
            id: item.doc_id,
            name: item.name,
            addedAt: item.addedAt,
            note: item.note || "",
          };
        }
        return null;
      };

      const newItem = getNewItem(selectedItem);

      if (!newItem) {
        console.error("New item creation failed:", selectedItem);
        return state;
      }

      return {
        ...state,
        groups: state.groups.map((group) =>
          group._id === groupId
            ? {
                ...group,
                [selectedItem.type === "case" ? "cases" : "statutes"]: [
                  ...(group[
                    selectedItem.type === "case" ? "cases" : "statutes"
                  ] || []),
                  newItem,
                ],
              }
            : group
        ),
      };
    }

    case FETCH_BOOKMARK_GROUPS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_BOOKMARK_GROUPS_RESET:
      return { ...bookmarkGroupsInitialState };
    default:
      return state;
  }
};

export const bookmarkCasesReducer = (
  state = bookmarkCasesInitialState,
  action
) => {
  switch (action.type) {
    case FETCH_CASES_REQUEST:
      return {
        cases: [],
        loadingCases: true,
        error: null,
      };

    case FETCH_CASES_SUCCESS:
      return {
        ...state,
        cases: action.payload,
        loadingCases: false,
      };

    case UPDATE_BOOKMARK_CASE_NOTES:
      return {
        ...state,
        cases: state.cases?.map((caseItem) =>
          caseItem.doc_id === action.payload.itemId
            ? { ...caseItem, note: action.payload.updatedNote }
            : caseItem
        ),
      };

    case REMOVE_BOOKMARK_CASE_NOTES:
      return {
        ...state,
        cases: state.cases?.map((caseItem) =>
          caseItem.doc_id === action.payload.itemId
            ? { ...caseItem, note: "" }
            : caseItem
        ),
      };

    case DELETE_BOOKMARK_CASE:
      return {
        ...state,
        cases: state.cases?.filter(
          (caseItem) => caseItem.doc_id !== action.payload.itemId
        ),
      };

    case FETCH_CASES_FAILURE:
      return {
        ...state,
        loadingCases: false,
        error: action.payload,
      };
    case FETCH_CASES_RESET:
      return { ...bookmarkCasesInitialState };
    default:
      return state;
  }
};

export const bookmarkStatutesReducer = (
  state = bookmarkStatutesInitialState,
  action
) => {
  switch (action.type) {
    case FETCH_STATUTES_REQUEST:
      return {
        statutes: [],
        loadingStatutes: true,
        error: null,
      };

    case FETCH_STATUTES_SUCCESS:
      return {
        ...state,
        statutes: action.payload,
        loadingStatutes: false,
      };

    case UPDATE_BOOKMARK_STATUTE_NOTES:
      return {
        ...state,
        statutes: state.statutes?.map((statute) =>
          statute.doc_id === action.payload.itemId
            ? { ...statute, note: action.payload.updatedNote }
            : statute
        ),
      };

    case REMOVE_BOOKMARK_STATUTE_NOTES:
      return {
        ...state,
        statutes: state.statutes?.map((statute) =>
          statute.doc_id === action.payload.itemId
            ? { ...statute, note: "" }
            : statute
        ),
      };

    case DELETE_BOOKMARK_STATUTE:
      return {
        ...state,
        cases: state.statutes?.filter(
          (statute) => statute.doc_id !== action.payload.itemId
        ),
      };

    case FETCH_STATUTES_FAILURE:
      return {
        ...state,
        loadingStatutes: false,
        error: action.payload,
      };
    case FETCH_STATUTES_RESET:
      return { ...bookmarkStatutesInitialState };
    default:
      return state;
  }
};
