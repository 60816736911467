import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  IconButton,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import {
  advancedSearchResults,
  searchResults,
} from "../../actions/searchActions";
import { KeyboardVoice } from "@mui/icons-material";
import InfoDialog from "./InfoDialog";
import { showInsufficientCreditsModal } from "../../actions/creditActions";
import { deductCredits } from "../../utils/HelperFunction/deductCredits";

const CaseLawSearchBar = ({
  resultsCount,
  updateResultsCount,
  sortBy,
  updateSortBy,
  searchMode,
  updateSearchMode,
  searchKeyword,
  setSearchKeyword,
  updateCurrentPage,
  setIsNewCaseLawSearch,
}) => {
  const dispatch = useDispatch();

  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSpeechSupported, setIsSpeechSupported] = useState(true);
  const [placeholder, setPlaceholder] = useState("Search for case law...");
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  // Check if sufficient credits are available
  const credits = useSelector((state) => state.credit.credits); // Get credits from Redux store
  const userId = useSelector((state) => state.credit.userId); // Get userId from Redux store
  const handleClickInfoDialog = () => {
    setOpenInfoDialog(true);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  const silenceTimeoutRef = useRef(null);
  const finalTranscriptRef = useRef("");

  const handleSearch = async (keyword = searchKeyword) => {
    if (!keyword) return;
    // if (isAdvancedSearch) {
    //   dispatch(searchResults(keyword, 0, resultsCount, "vector", sortBy));
    // }
    //else {

    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    dispatch(searchResults(keyword, 0, resultsCount, searchMode, sortBy));
    // Deduct credits using the helper
    const deductionSuccess = deductCredits(
      { userId, creditsToDeduct: 1, event: "Search bar on Case Search" },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
    //}
    setIsNewCaseLawSearch(true);
    updateCurrentPage(1);
    setIsSearchClicked(true);
  };

  const handleSwitchChange = (event) => {
    setIsAdvancedSearch(event.target.checked);
    if (event.target.checked) {
      updateSearchMode("vector");
      setIsSearchClicked(false);
    } else {
      updateSearchMode("keyword");
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const getSpeechRecognition = () => {
    return window.SpeechRecognition || window.webkitSpeechRecognition || null;
  };

  const SpeechRecognitionConstructor = getSpeechRecognition();

  let recognition;
  if (SpeechRecognitionConstructor) {
    recognition = new SpeechRecognitionConstructor();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join("");
      setSearchKeyword(transcript);
      finalTranscriptRef.current = transcript;
      resetSilenceTimer();
    };

    recognition.onerror = (event) => {
      console.error(event.error);
      stopListening();
    };

    recognition.onend = () => {
      setIsListening(false);
      setIsProcessing(false);
      setPlaceholder("Search for case law...");
      clearSilenceTimer();
      if (finalTranscriptRef.current) {
        handleSearch(finalTranscriptRef.current);
      }
    };
  }

  const startListening = () => {
    if (recognition) {
      setIsListening(true);
      setPlaceholder("Speak...");
      recognition.start();
      startSilenceTimer();
    }
  };

  const stopListening = () => {
    if (recognition) {
      setIsProcessing(true);
      recognition.stop();
    }
  };

  const toggleListening = () => {
    if (!isSpeechSupported) {
      alert("Voice recognition is not supported on this browser.");
      return;
    }

    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const startSilenceTimer = () => {
    silenceTimeoutRef.current = setTimeout(() => {
      stopListening();
    }, 3000);
  };

  const resetSilenceTimer = () => {
    clearTimeout(silenceTimeoutRef.current);
    startSilenceTimer();
  };

  const clearSilenceTimer = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };

  useEffect(() => {
    const isSupported =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    setIsSpeechSupported(!!isSupported);
  }, []);

  useEffect(() => {
    if (searchKeyword === "" && isSearchClicked) {
      dispatch(
        searchResults(searchKeyword, 0, resultsCount, searchMode, sortBy)
      );
      setIsSearchClicked(false);
      updateCurrentPage(1);
    }
  }, [
    dispatch,
    resultsCount,
    sortBy,
    searchKeyword,
    searchMode,
    isSearchClicked,
    updateCurrentPage,
  ]);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: "8px",
        borderRadius: "8px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: "0 8px",
        }}
      >
        <TextField
          placeholder={placeholder}
          variant="outlined"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onKeyDown={handleEnter}
          InputProps={{
            sx: {
              p: 0,
              backgroundColor: "#ffffff",
              borderRadius: "20px",
              //paddingRight: '5px',
            },
            startAdornment: (
              <SearchIcon sx={{ color: "#303f9f", padding: "8px" }} />
            ),
            endAdornment: (
              <IconButton
                sx={{
                  color: isListening ? "#FF4081" : "#303f9f",
                  animation: isListening ? "pulse 1.5s infinite" : "none",
                }}
                variant="contained"
                onClick={toggleListening}
                disabled={!isSpeechSupported || isProcessing}
              >
                <KeyboardVoice />
              </IconButton>
            ),
          }}
          sx={{
            flexGrow: 1,
            marginRight: "16px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#ccc",
              },
              "&:hover fieldset": {
                borderColor: "#00509e",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00509e",
              },
            },
            "& input": {
              padding: "10px 10px",
              fontSize: "0.875rem",
            },
          }}
        />
        <FormControl variant="outlined">
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={isAdvancedSearch}
                onChange={handleSwitchChange}
              />
            }
            label="AI Search"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "13px",
              },
            }}
          />
        </FormControl>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton size="small" onClick={handleClickInfoDialog}>
            <InfoIcon fontSize="small" color="info" />
          </IconButton>
          <Typography sx={{ fontSize: "13px" }}>Search Modes</Typography>
        </Box>
      </Box>
      <InfoDialog open={openInfoDialog} handleClose={handleCloseInfoDialog} />
    </Box>
  );
};

export default CaseLawSearchBar;
