import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Box,
  Stack,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbTack,
  faThumbTackSlash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  editChatTitle,
  getChatHistory,
  deleteChat,
  getChatTitles,
  togglePinChat,
} from "../../actions/chatActions";
import DeleteDialog from "../UI/DeleteDialog";
import { deductCredits } from "../../utils/HelperFunction/deductCredits";
import { showInsufficientCreditsModal } from "../../actions/creditActions";

const Sidebar = ({ isVisible }) => {
  const dispatch = useDispatch();

  const [selectedChat, setSelectedChat] = useState({ category: "", index: -1 });
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [newTitle, setNewTitle] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedChatTitle, setSelectedChatTitle] = useState(null);
  const [isEditing, setIsEditing] = useState({ category: "", index: -1 });
  const credits = useSelector((state) => state.credit.credits); // Get credits from Redux store
  const userId = useSelector((state) => state.credit.userId); // Get userId from Redux store
  const chatTitles = useSelector((state) => state.chatTitles);
  const { loading, titles, error } = chatTitles;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const inputRef = useRef(null);

  const pinnedChats = titles?.filter((chat) => chat.is_pinned);
  const recentChats = titles?.filter((chat) => !chat.is_pinned);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  };

  const handleTogglePin = (event, category, chatId) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    event.stopPropagation();
    const isPinned = category === "recent" ? false : true;
    dispatch(togglePinChat(userInfo._id, chatId, isPinned));
    setMenuAnchorEl(null);
    setSelectedChat({ category: "", index: -1 });
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Pin/Unpin chat on Legal Chat Page",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleListItemClick = (category, index, chatId) => {
    if (selectedChat.index === index && selectedChat.category === category)
      return;
    setSelectedChat({ category, index });
    dispatch(getChatHistory(userInfo._id, chatId));
  };

  const handleNewChatClick = () => {
    setSelectedChat(-1);
    dispatch({ type: "CHAT_HISTORY_RESET" });
    dispatch({ type: "NEW_CHAT_SUCCESS" });
  };

  const handleEditTitle = (event, category, index, title) => {
    event.stopPropagation();
    setIsEditing({ category, index });
    setNewTitle(title);
  };

  const handleSaveEdit = (chatId) => {
    if (newTitle.trim()) {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }
      dispatch(editChatTitle(userInfo._id, chatId, newTitle));
      setNewTitle("");
      setIsEditing({ category: "", index: -1 });
      const deductionSuccess = deductCredits(
        { userId, creditsToDeduct: 1, event: "Edit title on Legal Chat Page" },
        dispatch
      );

      if (!deductionSuccess) {
        console.warn(
          "Failed to deduct credits. Ensure retry or user notification."
        );
      }
    }
  };

  const handleCancelEdit = (index) => {
    setNewTitle("");
    setIsEditing({ category: "", index: -1 });
  };

  const handleConfirmDelete = (event, chatId) => {
    event.preventDefault();
    event.stopPropagation();
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }

    dispatch(deleteChat(userInfo._id, chatId));
    dispatch({ type: "CHAT_HISTORY_RESET" });

    // Handle resetting the selected chat if it's the one being deleted
    setSelectedChat({ category: "", index: -1 });

    // Close the delete dialog
    setOpenDeleteDialog(false);
    const deductionSuccess = deductCredits(
      { userId, creditsToDeduct: 1, event: "Delete Chat in Legal Chat Page" },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleDeleteChat = (event, category, index) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(null);

    // Determine the correct chat list (pinned or recent)
    const findChat =
      category === "pinned" ? pinnedChats[index] : recentChats[index];

    setSelectedChatTitle({
      title: findChat.chat_title,
      index,
      isPinned: findChat.isPinned,
      chatId: findChat.chat_id,
    });

    // Open delete dialog
    setOpenDeleteDialog(true);
  };

  const handleInputChange = (e) => {
    const cursorPosition = inputRef.current.selectionStart;
    setNewTitle(e.target.value);

    // Set the cursor back to where it was
    setTimeout(() => {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  useEffect(() => {
    if (error) {
      toast.error(error, { position: "top-right" });
    }
  }, [error]);

  useEffect(() => {
    dispatch(getChatTitles(userInfo?._id));
  }, [userInfo, dispatch]);

  return (
    <Box
      sx={{
        display: { xs: isVisible ? "flex" : "none", sm: "flex" },
        flexDirection: "column",
        boxSizing: "border-box",
        width: "16.6%",
        // minWidth: '278px',
        height: "calc(100vh - 50px)",
        p: "12px 0px 0 12px",
        bgcolor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          boxSizing: "border-box",
          flexDirection: "column",
          bgcolor: "#fff",
          p: "8px",
          borderRadius: "8px",
          flexGrow: 1,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#003D78",
            display: "flex",
            justifyContent: "center",
            marginBottom: "8px",
            mr: 0.5,
            marginTop: "8px",
            textTransform: "none",
            "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            transition: "background-color 0.3s ease-in-out",
          }}
          onClick={handleNewChatClick}
        >
          New Chat
        </Button>
        {loading ? (
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={22} />
          </Box>
        ) : titles?.length > 0 ? (
          <List
            sx={{
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            {pinnedChats?.length > 0 && (
              <>
                <Typography
                  variant="overline"
                  sx={{
                    display: "flex",
                    boxSizing: "border-box",
                    gap: 0.5,
                    alignItems: "center",
                    padding: "4px 16px 0px 12px",
                    color: "#3C50B1",
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  Pinned
                  <FontAwesomeIcon icon={faThumbTack} fontSize="12px" />
                </Typography>
                {pinnedChats?.map((title, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      sx={{
                        mr: 1,
                        height: "38px",
                        padding: "8px 12px",
                        borderRadius: "8px",
                        "&.Mui-selected": {
                          backgroundColor: "#ECECEC",
                          "&:hover": {
                            backgroundColor: "#ECECEC",
                          },
                        },
                        "&:hover": {
                          backgroundColor: "#ECECEC",
                        },
                      }}
                      selected={
                        selectedChat.category === "pinned" &&
                        selectedChat.index === index
                      }
                      onClick={() =>
                        handleListItemClick("pinned", index, title.chat_id)
                      }
                    >
                      {isEditing.category === "pinned" &&
                      isEditing.index === index ? (
                        <Stack direction="row">
                          <input
                            style={{ width: "100%", marginLeft: "0" }}
                            type="text"
                            autoFocus
                            ref={inputRef}
                            value={newTitle}
                            onClick={(e) => e.stopPropagation()}
                            onFocus={(e) => e.stopPropagation()}
                            onChange={handleInputChange}
                          />
                          <IconButton
                            edge="end"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSaveEdit(title.chat_id);
                              setMenuAnchorEl(null);
                            }}
                          >
                            <DoneIcon fontSize="small" />{" "}
                          </IconButton>
                          <IconButton
                            edge="end"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCancelEdit();
                              setMenuAnchorEl(null);
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                      ) : (
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                            }}
                          >
                            {title.chat_title}
                          </Typography>
                          {selectedChat.category === "pinned" &&
                            selectedChat.index === index && (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={handleMenuOpen}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={menuAnchorEl}
                                  id="basic-menu"
                                  open={Boolean(menuAnchorEl)}
                                  onClose={handleMenuClose}
                                >
                                  <MenuItem
                                    sx={{ fontSize: "14px" }}
                                    onClick={(e) =>
                                      handleTogglePin(
                                        e,
                                        selectedChat.category,
                                        title.chat_id
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faThumbTackSlash}
                                      fontSize="14px"
                                      style={{ marginRight: "8px" }}
                                    />
                                    Unpin
                                  </MenuItem>
                                  <MenuItem
                                    sx={{ fontSize: "14px" }}
                                    onClick={(e) =>
                                      handleEditTitle(
                                        e,
                                        "pinned",
                                        index,
                                        title.chat_title
                                      )
                                    }
                                  >
                                    <EditIcon sx={{ fontSize: 16, mr: 1 }} />
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    sx={{
                                      fontSize: "14px",
                                      color: "#d32f2f",
                                    }}
                                    onClick={(e) =>
                                      handleDeleteChat(e, "pinned", index)
                                    }
                                  >
                                    <DeleteIcon
                                      color="error"
                                      sx={{ fontSize: 16, mr: 1 }}
                                    />
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                        </Stack>
                      )}
                    </ListItemButton>
                  </ListItem>
                ))}
              </>
            )}
            {recentChats?.length > 0 && (
              <>
                <Typography
                  variant="overline"
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                    padding: "4px 16px 0px 12px",
                    color: "#3C50B1",
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  Recent
                </Typography>
                {recentChats?.map((title, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      sx={{
                        mr: 1,
                        height: "38px",
                        padding: "8px 12px",
                        borderRadius: "8px",
                        "&.Mui-selected": {
                          backgroundColor: "#ECECEC",
                          "&:hover": {
                            backgroundColor: "#ECECEC",
                          },
                        },
                        "&:hover": {
                          backgroundColor: "#ECECEC",
                        },
                      }}
                      selected={
                        selectedChat.category === "recent" &&
                        selectedChat.index === index
                      }
                      onClick={() =>
                        handleListItemClick("recent", index, title.chat_id)
                      }
                    >
                      {isEditing.category === "recent" &&
                      isEditing.index === index ? (
                        <Stack direction="row">
                          <input
                            style={{ width: "100%", marginLeft: "0" }}
                            type="text"
                            autoFocus
                            ref={inputRef}
                            value={newTitle}
                            onClick={(e) => e.stopPropagation()}
                            onFocus={(e) => e.stopPropagation()}
                            onChange={handleInputChange}
                          />
                          <IconButton
                            edge="end"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSaveEdit(title.chat_id);
                              setMenuAnchorEl(null);
                            }}
                          >
                            <DoneIcon fontSize="small" />{" "}
                          </IconButton>
                          <IconButton
                            edge="end"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCancelEdit();
                              setMenuAnchorEl(null);
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                      ) : (
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                            }}
                          >
                            {title.chat_title}
                          </Typography>
                          {selectedChat.category === "recent" &&
                            selectedChat.index === index && (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={handleMenuOpen}
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={menuAnchorEl}
                                  id="basic-menu"
                                  open={Boolean(menuAnchorEl)}
                                  onClose={handleMenuClose}
                                >
                                  <MenuItem
                                    sx={{ fontSize: "14px" }}
                                    onClick={(e) =>
                                      handleTogglePin(
                                        e,
                                        selectedChat.category,
                                        title.chat_id
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faThumbTack}
                                      fontSize="14px"
                                      style={{ marginRight: "8px" }}
                                    />
                                    Pin
                                  </MenuItem>
                                  <MenuItem
                                    sx={{ fontSize: "14px" }}
                                    onClick={(e) =>
                                      handleEditTitle(
                                        e,
                                        "recent",
                                        index,
                                        title.chat_title
                                      )
                                    }
                                  >
                                    <EditIcon sx={{ fontSize: 16, mr: 1 }} />
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    sx={{
                                      fontSize: "14px",
                                      color: "#d32f2f",
                                    }}
                                    onClick={(e) =>
                                      handleDeleteChat(e, "recent", index)
                                    }
                                  >
                                    <DeleteIcon
                                      color="error"
                                      sx={{ fontSize: 16, mr: 1 }}
                                    />
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                        </Stack>
                      )}
                    </ListItemButton>
                  </ListItem>
                ))}
              </>
            )}
          </List>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              m: "auto",
            }}
          >
            <Typography
              variant="subtitle2"
              color="textSecondary"
              textAlign="center"
            >
              Start a new chat to see your history here 🚀
            </Typography>
          </Box>
        )}
        <DeleteDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onDelete={handleConfirmDelete}
          chatTitle={selectedChatTitle}
        />
        <ToastContainer />
      </Box>
    </Box>
  );
};

export default Sidebar;
