import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  List,
  Typography,
  ListItemButton,
  IconButton,
  CircularProgress,
  ListItemText,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const StatuteDetailSidebar = ({ onSectionClick, selectedSection, onClose }) => {
  const navigate = useNavigate();

  const statuteSections = useSelector((state) => state.statuteSections);
  const { loading, sections } = statuteSections;

  const formatStatuteSection = (title) => {
    if (!title) return;
    const titleCaseTitle = title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return titleCaseTitle;
  };

  return (
    <Box
      sx={{
        width: "280px",
        height: "100%",
        boxSizing: "border-box",
        bgcolor: "#f5f5f5",
        p: "8px 4px 8px 12px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "white",
          borderRadius: "8px",
          width: "100%",
          height: "100%",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            padding: "8px",
            borderBottom: "1px solid #e6e6e6",
            mb: 1,
          }}
        >
          <IconButton sx={{ color: "#303f9f" }} onClick={onClose}>
            <FontAwesomeIcon icon={faCircleArrowLeft} fontSize="23px" />
          </IconButton>
          <Typography
            sx={{
              fontSize: "18px",
              color: "#303f9f",
              fontWeight: "bold",
            }}
          >
            Table of Contents
          </Typography>
        </Box>
        {/* <Button
          sx={{
            width: '40%',
            textTransform: 'none',
            m: '12px 16px',
            color: '#303f9f',
            fontWeight: '500',
            fontSize: '0.875rem',
            padding: '8px 12px',
            backgroundColor: '#f5f5f5',
            borderRadius: '6px',
            '&:hover': {
              backgroundColor: '#e6e6e6',
            },
          }}
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/app/statute-search')}
        >
          Back
        </Button>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            fontSize: '1rem',
            padding: '10px 16px',
            borderBottom: '1px solid #e0e0e0',
            color: '#333',
          }}
        >
          Navigation
        </Typography> */}
        <List
          sx={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            paddingTop: 1,
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "6px",
            },
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <CircularProgress size={18} />
            </Box>
          ) : sections?.length === 0 ? (
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                color: "grey",
                mt: 2,
                fontStyle: "italic",
              }}
            >
              No sections available for this Statute.
            </Typography>
          ) : (
            sections?.[0]?.related_data
              ?.sort((a, b) => {
                if (a.Section === "PREAMBLE") return -1;
                if (b.Section === "PREAMBLE") return 1;
                const sectionA = parseFloat(a.Section);
                const sectionB = parseFloat(b.Section);
                return sectionA - sectionB;
              })
              .map((result, index) => (
                <React.Fragment key={result.id}>
                  <ListItemButton
                    sx={{
                      pl: 2,
                      pt: "6px",
                      pb: "6px",
                      backgroundColor:
                        selectedSection ===
                        `${result.Section}. ${result.Definition}`
                          ? "#e0f2f1"
                          : "inherit",
                      borderLeft:
                        selectedSection ===
                        `${result.Section}. ${result.Definition}`
                          ? "3px solid #00796b"
                          : "",
                      transition: "background-color 0.2s ease",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                    onClick={() =>
                      onSectionClick(`${result.Section}. ${result.Definition}`)
                    }
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        color: "#333",
                      }}
                    >
                      {result.Section} -{" "}
                      {formatStatuteSection(result.Definition)}
                    </ListItemText>
                  </ListItemButton>
                </React.Fragment>
              ))
          )}
        </List>
      </Box>
    </Box>
  );
};

export default StatuteDetailSidebar;
