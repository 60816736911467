import React, { useContext, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Paper,
  Tabs,
  Tab,
  Typography,
  Fade,
  styled,
} from "@mui/material";
import {
  Notifications as NotificationsIcon,
  WhatsApp as WhatsAppIcon,
  Email as EmailIcon,
  Send as SendIcon,
  Comment as CommentIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { CMSContext } from "../context/CaseManagmentContext";

// Styled components
const ChatBubble = styled(Box)(({ isUser }) => ({
  maxWidth: "70%",
  padding: "8px 12px",
  borderRadius: "12px",
  marginBottom: "8px",
  backgroundColor: isUser ? "#e3f2fd" : "#f5f5f5",
  alignSelf: isUser ? "flex-end" : "flex-start",
  animation: "fadeIn 0.3s ease-in",
  "@keyframes fadeIn": {
    from: { opacity: 0, transform: "translateY(10px)" },
    to: { opacity: 1, transform: "translateY(0)" },
  },
}));

const TimeStamp = styled(Typography)({
  fontSize: "0.75rem",
  color: "#999",
  marginTop: "4px",
});

const TaskItem = styled(ListItem)({
  flexDirection: "column",
  alignItems: "flex-start",
  borderBottom: "1px solid #f0f0f0",
  "&:last-child": {
    borderBottom: "none",
  },
});

export default function RightSideBar() {
  const { data, setData, handleAddComment } = useContext(CMSContext);
  const [activeTab, setActiveTab] = useState(0);
  const [message, setMessage] = useState("");
  const [comment, setComment] = useState("");
  const [messages, setMessages] = useState([
    {
      text: "Hello! I wanted to discuss the details of the project.",
      isUser: false,
      timestamp: "10:30 AM",
    },
    {
      text: "Sure, I'm here to help. Could you please let me know the specifics?",
      isUser: true,
      timestamp: "10:32 AM",
    },
    {
      text: "We need a chat interface similar to WhatsApp for our web application.",
      isUser: false,
      timestamp: "10:33 AM",
    },
  ]);

  const tasks = [
    { title: "Review Evidence - Smith v. Johnson", dueDate: "2024-11-15" },
    { title: "Prepare Documents - Brown v. Wilson", dueDate: "2024-11-18" },
    { title: "Client Meeting - Doe v. Green", dueDate: "2024-11-20" },
    { title: "Task Review - Smith v. Johnson", dueDate: "2024-11-22" },
  ];

  const handleSendMessage = () => {
    if (message.trim()) {
      const newMessage = {
        text: message,
        isUser: true,
        timestamp: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };
      setMessages([...messages, newMessage]);
      setMessage("");

      // Simulate reply after 1 second
      setTimeout(() => {
        const reply = {
          text: "Thank you for your message. I'll get back to you soon.",
          isUser: false,
          timestamp: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
        setMessages((prev) => [...prev, reply]);
      }, 1000);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };
  console.log(data);

  return (
    <Paper elevation={3} sx={{ height: "100%" }}>
      {data ? (
        <>
          <div style={{ position: "relative" }}>
            <Tabs
              variant="fullWidth"
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: 0,
                "& .MuiTabs-flexContainer": {
                  margin: 0,
                  padding: "5px",
                },
                "& .MuiTab-root": {
                  padding: "0px",
                  minHeight: "36px",
                },
              }}
            >
              <Tab
                icon={<CommentIcon sx={{ color: "#ff9800" }} />}
                label={<span style={{ color: "#003D78" }}>Comments</span>}
                sx={{
                  minWidth: 60,
                  fontSize: "10px",
                  "&.Mui-selected": {
                    color: "#003D78",
                  },
                  "& .MuiTab-wrapper": {
                    color: "#003D78",
                  },
                }}
              />
            </Tabs>
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                right: 8,
                top: "50%",
                transform: "translateY(-50%)",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={() => {
                setData(null)
              }}
            >
              <CloseIcon sx={{ fontSize: 16, color: "#003D78" }} />
            </IconButton>
          </div>
          <Box
            sx={{
              height: "calc(100% - 72px)",
              display: "flex",
              flexDirection: "column",
              bgcolor: "white",
            }}
          >
            <Box
              sx={{
                flex: 1,
                overflow: "auto",
                p: 2,
                bgcolor: "white",
                "::-webkit-scrollbar": {
                  width: "5px",
                },
                "::-webkit-scrollbar-track": {
                  background: "#fff",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#bbb",
                  borderRadius: "6px",
                },
              }}
            >
              {Array.isArray(data?.comments) && data.comments.length > 0 ? (
                data.comments.map((msg, index) => (
                  <Fade in={true} timeout={300} key={index}>
                    <ChatBubble>
                      <Typography variant="subtitle2">{msg}</Typography>
                    </ChatBubble>
                  </Fade>
                ))
              ) : (
                <Typography>No comments available</Typography>
              )}
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Add a comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    handleAddComment(comment); // Pass the correct message
                    setComment(""); // Clear the input
                  }
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleAddComment(comment); // Pass the correct message
                        setComment("");
                      }}
                      disabled={!comment.trim()}
                    >
                      <SendIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant="fullWidth"
            sx={{
              borderBottom: 1,
              borderColor: "divider",

              padding: 0,
              "& .MuiTabs-flexContainer": {
                margin: 0, // Ensures no margin around the tabs container
                padding: "5px", // Removes padding around the flex container
              },
              "& .MuiTab-root": {
                padding: "0px", // Removes padding inside each tab
                minHeight: "36px", // Optional: Adjust the tab height if needed
              },
            }}
          >
            <Tab
              icon={<NotificationsIcon sx={{ color: "#ff9800" }} />} // Icon color
              label={<span style={{ color: "#003D78" }}>Notifications</span>}
              sx={{
                minWidth: 60,
                fontSize: "10px",
                "&.Mui-selected": {
                  color: "#003D78",
                },
                "& .MuiTab-wrapper": {
                  color: "#003D78",
                },
              }}
            />
            <Tab
              icon={<WhatsAppIcon sx={{ color: "#25D366" }} />}
              label={<span style={{ color: "#003D78" }}>Send Message</span>}
              sx={{ minWidth: 60, fontSize: "10px" }}
            />
            <Tab
              icon={<EmailIcon sx={{ color: "#EA4335" }} />}
              label={<span style={{ color: "#003D78" }}>Send Email</span>}
              sx={{ minWidth: 60, fontSize: "10px" }}
            />
          </Tabs>

          {/* Notifications Tab */}
          <Box
            role="tabpanel"
            hidden={activeTab !== 0}
            sx={{
              height: "calc(100% - 72px)",
              overflow: "auto",
              display: activeTab === 0 ? "block" : "none",
              bgcolor: "white",
            }}
          >
            <List>
              {tasks.map((task, index) => (
                <Fade in={activeTab === 0} timeout={300} key={index}>
                  <TaskItem>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "#303f9f", fontWeight: 500 }}
                    >
                      {task.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Due Date: {task.dueDate}
                    </Typography>
                  </TaskItem>
                </Fade>
              ))}
            </List>
          </Box>

          {/* Chat Tab */}
          <Box
            role="tabpanel"
            hidden={activeTab !== 1}
            sx={{
              height: "calc(100% - 72px)",
              display: activeTab === 1 ? "flex" : "none",
              flexDirection: "column",
              bgcolor: "white",
            }}
          >
            <Box
              sx={{
                flex: 1,
                overflow: "auto",
                p: 2,
                bgcolor: "white",
                "::-webkit-scrollbar": {
                  width: "5px",
                },
                "::-webkit-scrollbar-track": {
                  background: "#fff",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#bbb",
                  borderRadius: "6px",
                },
              }}
            >
              {messages.map((msg, index) => (
                <Fade in={true} timeout={300} key={index}>
                  <ChatBubble isUser={msg.isUser}>
                    <Typography variant="subtitle2">{msg.text}</Typography>
                    <TimeStamp variant="caption">{msg.timestamp}</TimeStamp>
                  </ChatBubble>
                </Fade>
              ))}
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Type a message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                size="small"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      color="primary"
                      onClick={handleSendMessage}
                      disabled={!message.trim()}
                    >
                      <SendIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Box>

          {/* Email Tab */}
          <Box
            role="tabpanel"
            hidden={activeTab !== 2}
            sx={{
              height: "calc(100% - 72px)",
              display: activeTab === 2 ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "white",
            }}
          >
            <Typography variant="h6" color="text.secondary">
              Email Component
            </Typography>
          </Box>
        </>
      )}
    </Paper>
  );
}
