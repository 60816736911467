import React from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { useDispatch, useSelector } from "react-redux";
import {
  setHasSeenLowCreditAlert,
  showLowCreditModal,
} from "../../actions/creditActions";

const CreditAlertModal = () => {
  const dispatch = useDispatch();
  const { showLowCreditModalFlag, credits } = useSelector(
    (state) => state.credit
  );

  const handleClose = () => {
    dispatch(setHasSeenLowCreditAlert(true));
    dispatch(showLowCreditModal(false));
  };

  return (
    <Modal
      open={showLowCreditModalFlag}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: 400,
          maxWidth: "95%",
          bgcolor: "background.paper",
          borderRadius: 4,
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#003D78",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box display="flex" flexDirection="column" alignItems="center">
          <WarningIcon sx={{ fontSize: 60, color: "#FFA500", mb: 2 }} />
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ fontWeight: "bold", textAlign: "center", color: "#003D78" }}
          >
            Low Credit Alert
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
            Your credit balance is running low. You currently have less than{" "}
            <strong>10 credits</strong> remaining.
          </Typography>
          <Typography variant="body2" sx={{ mb: 3, textAlign: "center" }}>
            Please monitor your usage or request more credits.
          </Typography>
          <Button
            onClick={handleClose}
            variant="contained"
            size="large"
            sx={{
              borderRadius: 30,
              px: 4,
              py: 1.5,
              textTransform: "none",
              fontSize: "1rem",
              bgcolor: "#003D78",
              "&:hover": {
                bgcolor: "#002a54",
              },
              transition: "background-color 0.3s",
            }}
          >
            Got It
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreditAlertModal;
