import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "./Sidebar";
import ApplicationForm from "./ApplicationForm";
import ApplicationPreview from "./ApplicationPreview";
import { Box } from "@mui/material";
import HistoryTable from "./HistoryTable";

const ApplicationDrafting = () => {
  const dispatch = useDispatch();
  const newApplicationRequest = useSelector(
    (state) => state.newApplicationRequest
  );
  const { isClicked } = newApplicationRequest;

  const [generatedApplication, setGeneratedApplication] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isHistoryClicked, setIsHistoryClicked] = useState(false);
  const [applicationToEdit, setApplicationToEdit] = useState({});

  useEffect(() => {
    if (isClicked) {
      setGeneratedApplication("");
      dispatch({ type: "SELECTED_APPLICATION_RESET" });
      dispatch({ type: "NEW_APPLICATION_RESET" });
    }
    if (isHistoryClicked) {
      dispatch({ type: "SELECTED_APPLICATION_RESET" });
    }
    return () => {
      dispatch({ type: "SELECTED_APPLICATION_RESET" });
    };
  }, [isClicked, dispatch, isHistoryClicked]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
      }}
    >
      <Sidebar
        setIsHistoryClicked={setIsHistoryClicked}
        setApplicationToEdit={setApplicationToEdit}
      />
      {isHistoryClicked ? (
        <HistoryTable
          setApplicationToEdit={setApplicationToEdit}
          setIsHistoryClicked={setIsHistoryClicked}
        />
      ) : (
        <React.Fragment>
          <ApplicationForm
            setGeneratedApplication={setGeneratedApplication}
            setIsGenerating={setIsGenerating}
            isGenerating={isGenerating}
          />
          <ApplicationPreview
            generatedApplication={generatedApplication}
            setGeneratedApplication={setGeneratedApplication}
            isGenerating={isGenerating}
            applicationToEdit={applicationToEdit}
            setApplicationToEdit={setApplicationToEdit}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default ApplicationDrafting;
