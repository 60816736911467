import React from "react";
import { Box, Typography, Modal, Divider } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const ComingSoonModal = () => {
  return (
    <Modal
      open={true}
      sx={{
        position: "absolute",
        width: "calc(100% - 45px)",
        height: "calc(100vh - 50px)",
        top: "50px",
        left: "45px",
        backdropFilter: "blur(8px)",
        /* Full height */
        //inset: 0, // Covers the entire viewport
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional backdrop effect
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1, // Higher z-index to overlay on top of other elements
      }}
      BackdropProps={{
        sx: {
          position: "fixed",
          //   top: "50px",
          //   left: "45px",
          right: "0",
          bottom: "0",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 4,
          boxShadow: 24,
          p: 4,
          "@media (max-width:600px)": {
            width: "90%",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <AccessTimeIcon
            sx={{
              fontSize: 64,
              color: "#003D78",
              mb: 1,
            }}
          />
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#003D78" }}
          >
            Coming Soon
          </Typography>
          <Divider sx={{ width: "100%", my: 1 }} />
          {/* <Typography
            variant="h6"
            sx={{ mb: 2, color: "#003D78", fontWeight: "bold" }}
          >
            Feature Under Development
          </Typography> */}
          <Typography variant="body1" sx={{ mb: 3 }}>
            This feature is currently under development. <br />
            Stay tuned for updates!
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default ComingSoonModal;
