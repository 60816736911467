import { Box, Typography, Button, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import config from "../../config/api";
import DynamicForm from "../UI/DynamicForm";
import DropdownField from "../UI/DropdownField";
import appDraftingImage from "./../../assets/images/appDrafting.png";
import { getApplicationTitles } from "./../../actions/applicationActions";
import TitleInputModal from "../Modal/TitleInputModal";
import { showInsufficientCreditsModal } from "../../actions/creditActions";
import { deductCredits } from "../../utils/HelperFunction/deductCredits";

const ApplicationForm = ({
  setGeneratedApplication,
  isGenerating,
  setIsGenerating,
}) => {
  const dispatch = useDispatch();

  const credits = useSelector((state) => state.credit.credits); // Get credits from Redux store
  const userId = useSelector((state) => state.credit.userId);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const newApplicationRequest = useSelector(
    (state) => state.newApplicationRequest
  );
  const { isClicked } = newApplicationRequest;

  const [selectedApplicationType, setSelectedApplicationType] = useState("");
  const [formConfig, setFormConfig] = useState({});
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);
  const [openTitleInputModal, setOpenTitleInputModal] = useState(false);

  const [formData, setFormData] = useState(() => {
    const initialData = {};
    formConfig.fields?.forEach((field) => {
      initialData[field.name] = field.value || "";
    });
    return initialData;
  });

  const handleSaveTitleInput = async (title) => {
    if (!title) return;

    let creditsToDeduct = 2;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }

    setIsGenerating(true);
    const newChatId = uuidv4();
    const currentTime = dayjs().format("DD-MM-YYYY");
    const date = formData.Date;
    let updatedFormData;
    if (date) {
      // Parse the date using dayjs
      const day = dayjs(date).date().toString();
      const year = dayjs(date).year().toString();
      const monthName = dayjs(date).format("MMMM");

      updatedFormData = {
        ...formData,
        user_id: userInfo._id,
        chat_id: newChatId,
        timecreated: currentTime,
        Category: selectedApplicationType.Name,
        Title: title,
        Day: day,
        Month: monthName,
        Year: year,
      };
    } else {
      updatedFormData = {
        ...formData,
        user_id: userInfo._id,
        chat_id: newChatId,
        timecreated: currentTime,
        Category: selectedApplicationType.Name,
        Title: title,
      };
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const endpoint = `${config.petitionForm}/${selectedApplicationType.Endpoint}`;
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(updatedFormData),
        redirect: "follow",
      });
      if (response.ok) {
        const generatedApplication = await response.json();
        setGeneratedApplication(generatedApplication);
        setIsGenerating(false);
        dispatch(getApplicationTitles(userInfo?._id));
        // Deduct credits
        const deductionSuccess = deductCredits(
          {
            userId,
            creditsToDeduct: 1,
            event: "Generate application on application drafting page",
          },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      }
    } catch (error) {
      setIsGenerating(false);
      console.log(error);
    }
  };

  const getApplicationTypes = async () => {
    setIsLoadingTypes(true);
    const endpoint = `${config.petitionForm}/Applications`;
    try {
      const response = await fetch(endpoint, {
        method: "GET",
        redirect: "follow",
      });
      if (response.ok) {
        setIsLoadingTypes(false);
        const applicationTypesData = await response.json();
        setApplicationTypes(applicationTypesData.Applications);
      }
    } catch (error) {
      setIsLoadingTypes(false);
      console.log(error);
    }
  };

  const handleLoadTemplate = async (value) => {
    // if (!selectedApplicationType) return;
    setIsFormLoading(true);
    const endpoint = `${config.petitionForm}/${value.Endpoint}`;
    try {
      const response = await fetch(endpoint, {
        method: "GET",
        redirect: "follow",
      });
      if (response.ok) {
        const config = await response.json();
        setFormConfig(config);
        setIsFormLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRadioChange = (e, fieldName) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value,
    }));
  };

  const generateApplication = async () => {
    // Validate required fields
    const missingFields = formConfig.form.fields.filter(
      (field) => field.required && !formData[field.name]
    );

    if (missingFields.length > 0) {
      toast.info("Please fill all the required fields.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      setOpenTitleInputModal(true);
    }
  };

  const handleSelectTemplate = (value) => {
    setSelectedApplicationType(value);
    handleLoadTemplate(value);
  };

  useEffect(() => {
    if (isClicked) {
      setSelectedApplicationType("");
      setFormConfig({});
      setFormData({});
      dispatch({ type: "NEW_APPLICATION_RESET" });
    }
  }, [isClicked, dispatch]);

  useEffect(() => {
    getApplicationTypes();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "41%",
        height: "calc(100vh - 50px)",
        bgcolor: "#f5f5f5",
        boxSizing: "border-box",
        p: "12px 0px 0px 12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          boxSizing: "border-box",
          flexGrow: 1,
          bgcolor: "#fff",
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            mb: 2,
            color: "#3C50B1",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Select Template
        </Typography>
        <DropdownField
          label={"Select a Template"}
          selectedValue={selectedApplicationType}
          options={applicationTypes}
          handleChange={handleSelectTemplate}
          isLoading={isLoadingTypes}
        />
        {!isFormLoading &&
        selectedApplicationType &&
        Object.keys(selectedApplicationType).length > 0 &&
        Object.keys(formConfig).length !== 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflowY: "auto",
              mt: 2,
              paddingRight: "8px",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            <Typography
              sx={{
                color: "#303f9f",
                mb: 1,
                fontSize: "16px",
              }}
            >
              {selectedApplicationType.Name}
            </Typography>
            <DynamicForm
              formConfig={formConfig}
              formData={formData}
              handleInputChange={handleInputChange}
              handleRadioChange={handleRadioChange}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="success"
                endIcon={
                  isGenerating ? (
                    <CircularProgress size={18} sx={{ color: "#fff" }} />
                  ) : (
                    <SendIcon />
                  )
                }
                sx={{
                  borderRadius: "8px",
                  mt: 3,
                  textTransform: "none",
                }}
                onClick={generateApplication}
              >
                Generate
              </Button>
            </Box>
          </Box>
        ) : isFormLoading ? (
          <CircularProgress
            size={20}
            sx={{
              mt: 2,
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              gap: 1,
            }}
          >
            <img
              width="120px"
              height="auto"
              src={appDraftingImage}
              alt="moduleLogo"
            />
            <Typography sx={{ fontSize: "13px" }}>
              Choose a template to begin drafting your application
            </Typography>
          </Box>
        )}
      </Box>
      <TitleInputModal
        open={openTitleInputModal}
        onClose={() => {
          setOpenTitleInputModal(false);
        }}
        onSave={handleSaveTitleInput}
      />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
};

export default ApplicationForm;
