import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  TablePagination,
} from "@mui/material";
import { SwapVert } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faDownload,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import HTMLDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import DocumentPreview from "../UI/DocumentPreview";

const HistoryTable = ({ setPetitionToEdit, setIsHistoryClicked }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [openDocPreview, setOpenDocPreview] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "title",
    direction: "asc",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const petitionTitles = useSelector((state) => state.petitionTitles);
  const { titles } = petitionTitles;

  // Handle sorting logic
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const sortedData =
    titles &&
    [...titles]?.sort((a, b) => {
      const isAsc = sortConfig.direction === "asc";

      if (sortConfig.key === "timecreated") {
        // Handle date sorting using Moment.js
        const dateA = moment(a.timecreated, "DD-MM-YYYY");
        const dateB = moment(b.timecreated, "DD-MM-YYYY");

        // Handle invalid dates gracefully
        if (!dateA.isValid() || !dateB.isValid()) return 0;

        return isAsc ? dateA - dateB : dateB - dateA;
      }

      // Default sorting for other keys
      if (sortConfig.key === "Title") {
        return isAsc
          ? a.Title.localeCompare(b.Title)
          : b.Title.localeCompare(a.Title);
      }

      return 0; // If no valid sort key, return 0 (no sorting)
    });

  // Handle search functionality
  const filteredData = sortedData?.filter((item) =>
    item.Title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle pagination
  const paginatedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const cleanQuillOutput = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    div.querySelectorAll(".ql-align-center, .ql-align-right").forEach((el) => {
      if (el.classList.contains("ql-align-center")) {
        el.style.textAlign = "center";
        el.classList.remove("ql-align-center");
      }
      if (el.classList.contains("ql-align-right")) {
        el.style.textAlign = "right";
        el.classList.remove("ql-align-right");
      }
    });
    return div.innerHTML;
  };

  const handleDownload = () => {
    const cleanedHtml = cleanQuillOutput(selectedItem.Document);
    const docxBlob = HTMLDocx.asBlob(cleanedHtml);
    saveAs(
      docxBlob,
      `${selectedItem.Title || "Petition"} ${moment().format(
        "DD-MM-YYYY"
      )} .docx`
    );
  };

  const handlePrint = (petition) => {
    const printContent = cleanQuillOutput(petition);

    // Create a hidden iframe
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "0";
    document.body.appendChild(iframe);

    // Load the print content into the iframe
    const iframeDoc = iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>${printContent}</body>
      </html>
    `);
    iframeDoc.close();

    // Trigger the print dialog
    iframe.contentWindow.focus();
    iframe.contentWindow.print();

    // Ensure the iframe is cleared and removed after printing
    iframe.contentWindow.onafterprint = () => {
      iframeDoc.body.innerHTML = ""; // Clear content
      document.body.removeChild(iframe); // Remove the iframe
    };
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "82%",
        height: "calc(100vh - 50px)",
        bgcolor: "#f5f5f5",
        boxSizing: "border-box",
        p: "12px 0px 0px 12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          boxSizing: "border-box",
          flexGrow: 1,
          bgcolor: "#fff",
          borderRadius: "8px",
          p: "16px 16px 8px 16px",
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          sx={{
            width: "100%",
            maxWidth: "400px",
            mt: 1,
            mb: 2,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#ccc",
              },
              "&:hover fieldset": {
                borderColor: "#00509e",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00509e",
              },
            },
            "& input": {
              padding: "10px 10px",
              fontSize: "0.875rem",
            },
          }}
          placeholder="Search by title"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <SearchIcon sx={{ color: "#303f9f", padding: "8px" }} />
            ),
            sx: {
              p: 0,
              backgroundColor: "#ffffff",
              borderRadius: "20px",
            },
          }}
        />
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: "12px",
            overflowY: "auto",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#fff",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "6px",
            },
          }}
        >
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#f5f5f5",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <TableCell sx={{ fontWeight: 600, color: "#101936", py: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Title
                    <IconButton
                      onClick={() => handleSort("Title")}
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <SwapVert fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: "#101936", py: 2 }}>
                  Applicant Name
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: "#101936", py: 2 }}>
                  Template
                </TableCell>
                <TableCell sx={{ fontWeight: 600, color: "#101936", py: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Last Updated
                    <IconButton
                      onClick={() => handleSort("timecreated")}
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <SwapVert fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>

                <TableCell sx={{ fontWeight: 600, color: "#101936", py: 2 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData?.length > 0 ? (
                paginatedData?.map((item, index) => (
                  <TableRow
                    key={item.chat_id}
                    sx={{
                      backgroundColor: "white",
                      transition: "all 0.3s",
                      "&:hover": {
                        backgroundColor: "#f9f9f9",
                        transform: "translateY(-2px)",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
                      },
                      "&:not(:last-child)": {
                        borderBottom: "1px solid #f0f0f0",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        py: 2,
                        color: "#101936",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                          color: "#3C50B1",
                        },
                      }}
                      onClick={() => {
                        setOpenDocPreview(true);
                        setSelectedItem(item);
                      }}
                    >
                      {truncateText(item.Title, 100)}
                    </TableCell>
                    <TableCell sx={{ py: 2 }}>{item.ApplicantName}</TableCell>
                    <TableCell
                      sx={{
                        py: 2,
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: "#101936",
                      }}
                    >
                      {item.Category}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 2,
                        maxWidth: "150px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: "#101936",
                      }}
                    >
                      {moment(item.timecreated, "DD-MM-YYYY").isValid()
                        ? moment(item.timecreated, "DD-MM-YYYY").format("LL")
                        : item.timecreated}
                    </TableCell>
                    <TableCell sx={{ py: 2 }}>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <IconButton
                          size="small"
                          sx={{
                            color: "inherit",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.04)",
                            },
                          }}
                          onClick={(event) => {
                            setSelectedItem(item);
                            setAnchorEl(event.currentTarget);
                          }}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          PaperProps={{
                            sx: {
                              //mt: 3.5,
                              boxShadow:
                                "0px 4px 12px rgba(0, 0, 0, 0.1) !important",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              setOpenDocPreview(true);
                              setAnchorEl(null);
                            }}
                          >
                            <ListItemIcon>
                              <VisibilityIcon
                                sx={{
                                  color: "#4CAF50",
                                  width: "15px",
                                  height: "auto",
                                }}
                              />
                            </ListItemIcon>
                            <Typography sx={{ fontSize: "13px" }}>
                              Preview
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setPetitionToEdit(selectedItem);
                              setIsHistoryClicked(false);
                              setAnchorEl(null);
                            }}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                size="xs"
                                icon={faPenToSquare}
                                style={{ color: "#2196f3" }}
                              />
                            </ListItemIcon>
                            <Typography sx={{ fontSize: "13px" }}>
                              Edit
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleDownload();
                              setAnchorEl(null);
                            }}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                size="xs"
                                icon={faDownload}
                                style={{ color: "#ff9800" }}
                              />
                            </ListItemIcon>
                            <Typography sx={{ fontSize: "13px" }}>
                              Download
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handlePrint(selectedItem.Document);
                              setAnchorEl(null);
                            }}
                          >
                            <ListItemIcon>
                              <FontAwesomeIcon
                                size="xs"
                                icon={faPrint}
                                style={{ color: "#d2042d" }}
                              />
                            </ListItemIcon>
                            <Typography sx={{ fontSize: "13px" }}>
                              Print
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography sx={{ fontSize: "14px" }}>
                      No records found matching your search
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <TablePagination
            component="div"
            count={filteredData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <DocumentPreview
        open={openDocPreview}
        onClose={() => setOpenDocPreview(false)}
        content={selectedItem.Document}
      />
    </Box>
  );
};

export default HistoryTable;
