import {
  SET_CREDITS,
  REDUCE_CREDITS,
  SHOW_INSUFFICIENT_CREDITS_MODAL,
  HIDE_INSUFFICIENT_CREDITS_MODAL,
  HAS_SEEN_CREDITS_MODAL,
  RESET_CREDITS_STATES,
  HAS_SEEN_LOW_CREDIT_ALERT,
  UPDATE_CREDITS,
  SHOW_LOW_CREDIT_MODAL,
} from "../actions/creditActions";

const initialState = {
  credits: 0,
  userId: null,
  insufficientCreditsModalOpen: false,
  showLowCreditModalFlag: false,
  hasSeenCreditModal: false, // New field
  creditExpiration: null, // New field
  creditRequestCount: 0,  // New field
  isCreditsInitialized: false,
};

const creditReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CREDITS:
      return {
        ...state,
        credits: action.payload.credits,
        userId: action.payload.userId,
        creditExpiration: action.payload.creditExpiration, // Update expiration
        creditRequestCount: action.payload.creditRequestCount, // Update request count
        isCreditsInitialized: true,
      };
    case UPDATE_CREDITS:
        return {
          ...state,
          credits: action.payload.credits,
          expirationDate: action.payload.expirationDate,
          creditRequestCount: action.payload.creditRequestCount,
        };
    case REDUCE_CREDITS:
      return { ...state, credits:  action.payload };
    case SHOW_INSUFFICIENT_CREDITS_MODAL:
      return { ...state, insufficientCreditsModalOpen: true };
    case HIDE_INSUFFICIENT_CREDITS_MODAL:
      return { ...state, insufficientCreditsModalOpen: false };
      case SHOW_LOW_CREDIT_MODAL:
        return { ...state, showLowCreditModalFlag: action.payload };
    case HAS_SEEN_CREDITS_MODAL:
      return { ...state, hasSeenCreditModal: action.payload.hasSeenModal }; // Set flag to true when modal is shown
    case HAS_SEEN_LOW_CREDIT_ALERT:
      return { ...state, hasSeenLowCreditAlert: action.payload };
      // need to do modal thing
    case RESET_CREDITS_STATES:
      return { ...initialState }; // Reset to the initial state
    default:
      return state;
  }
};

export default creditReducer;
