import React from 'react';
import { TextField, MenuItem } from '@mui/material';

const DropdownField = ({
  label,
  selectedValue,
  options,
  handleChange,
  isLoading,
}) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      select
      size="small"
      value={selectedValue}
      onChange={(event) => handleChange(event.target.value)}
      InputProps={{
        sx: {
          bgcolor: 'white',
          fontSize: '13px',
        },
      }}
      InputLabelProps={{
        sx: {
          fontSize: '13px',
        },
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root': {
                fontSize: '13px',
              },
              '& .MuiMenu-paper': {
                maxHeight: '180px',
              },
            },
          },
        },
      }}
    >
      {isLoading ? (
        <MenuItem value="" disabled>
          Loading...
        </MenuItem>
      ) : options?.length > 0 ? (
        options.map((option) => (
          <MenuItem key={option.Name} value={option}>
            {option.Name}
          </MenuItem>
        ))
      ) : (
        <MenuItem value="" disabled>
          No Options Available
        </MenuItem>
      )}
    </TextField>
  );
};

export default DropdownField;
