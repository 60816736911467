import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Paper, Stack, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faFloppyDisk,
  faDownload,
  faPrint,
  faXmark,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import HTMLDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import appDraftingImage from "./../../assets/images/appDrafting.png";
import StyledTooltip from "../UI/StyledTooltip";
import TextEditor from "../TextEditor";
import config from "../../config/api";
import { getApplicationTitles } from "./../../actions/applicationActions";
import ConfirmationDialog from "../Modal/ConfirmationDialog";
import { showInsufficientCreditsModal } from "../../actions/creditActions";
import { deductCredits } from "../../utils/HelperFunction/deductCredits";

const ApplicationPreview = ({
  generatedApplication,
  // selectedApplication,
  setGeneratedApplication,
  setSelectedApplication,
  isGenerating,
  applicationToEdit,
  setApplicationToEdit,
}) => {
  const dispatch = useDispatch();

  const credits = useSelector((state) => state.credit.credits); // Get credits from Redux store
  const userId = useSelector((state) => state.credit.userId);

  const selectedApplication = useSelector((state) => state.selectedApplication);
  const { selectedApp } = selectedApplication;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState("");
  const [isApplicationEdited, setIsApplicationEdited] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const cleanQuillOutput = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    div.querySelectorAll(".ql-align-center, .ql-align-right").forEach((el) => {
      if (el.classList.contains("ql-align-center")) {
        el.style.textAlign = "center";
        el.classList.remove("ql-align-center");
      }
      if (el.classList.contains("ql-align-right")) {
        el.style.textAlign = "right";
        el.classList.remove("ql-align-right");
      }
    });
    return div.innerHTML;
  };

  const handleEditApplication = (content) => {
    setEditedContent(content);
    setIsApplicationEdited(true);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }

    const chatId =
      generatedApplication?.metadata?.chat_id ||
      selectedApp?.chat_id ||
      applicationToEdit?.chat_id;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const endpoint = `${config.petitionUrl}/SaveApplication`;

    try {
      await toast.promise(
        (async () => {
          const response = await fetch(endpoint, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ user_id: userInfo._id, chat_id: chatId }),
          });

          if (!response.ok) {
            // Parse the error response if it's JSON
            const errorResponse = await response.json();
            throw new Error(
              errorResponse.message ||
                "Failed to save application. Please try again."
            );
          }
          if (!selectedApp?.isSaved) {
            dispatch({
              type: "SET_SELECTED_APPLICATION",
              payload: { ...selectedApp, isSaved: true },
            });
          }

          if (generatedApplication?.html) {
            setGeneratedApplication((prev) => ({
              ...prev,
              metadata: { ...prev?.metadata, isSaved: true },
            }));
          }

          // Dispatch the action only if the API call succeeds
          dispatch({
            type: "UPDATE_IS_SAVED_APPLICATION",
            payload: { chatId },
          });

          // Deduct credits
          const deductionSuccess = deductCredits(
            {
              userId,
              creditsToDeduct: 1,
              event: "Save generated application on application drafting page",
            },
            dispatch
          );

          if (!deductionSuccess) {
            console.warn(
              "Failed to deduct credits. Ensure retry or user notification."
            );
          }
        })(),
        {
          pending: { render: "Saving petition...", position: "top-right" },
          success: {
            render: "Application saved successfully! 🎉",
            position: "top-right",
          },
          error: {
            render: ({ data }) =>
              data?.message || "Failed to save application. 🚨",
            position: "top-right",
          },
        }
      );
    } catch (error) {
      console.error("Error in saving application:", error?.message);
    }
  };

  const handleDownload = () => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    try {
      const cleanedHtml = cleanQuillOutput(
        generatedApplication?.html ||
          selectedApp?.Document ||
          applicationToEdit?.Document
      );
      const docxBlob = HTMLDocx.asBlob(cleanedHtml);
      saveAs(
        docxBlob,
        `${
          selectedApp?.Title ||
          generatedApplication?.metadata?.Title ||
          applicationToEdit?.Title ||
          "Application"
        } ${dayjs().format("DD-MM-YYYY")}.docx`
      );

      // Deduct credits
      const deductionSuccess = deductCredits(
        {
          userId,
          creditsToDeduct: 1,
          event: "Download application on application drafting page",
        },
        dispatch
      );

      if (!deductionSuccess) {
        console.warn(
          "Failed to deduct credits. Ensure retry or user notification."
        );
      }
    } catch (error) {
      toast.error("Failed to download document. Please try again.", {
        position: "top-right",
      });
    }
  };

  const handlePrint = () => {
    const printContent = cleanQuillOutput(
      generatedApplication?.html || selectedApp?.Document
    );

    // Create a hidden iframe
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "0";
    document.body.appendChild(iframe);

    // Load the print content into the iframe
    const iframeDoc = iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>${printContent}</body>
      </html>
    `);
    iframeDoc.close();

    // Trigger the print dialog
    iframe.contentWindow.focus();
    iframe.contentWindow.print();

    // Ensure the iframe is cleared and removed after printing
    iframe.contentWindow.onafterprint = () => {
      iframeDoc.body.innerHTML = ""; // Clear content
      document.body.removeChild(iframe); // Remove the iframe
    };
  };

  const handleSaveClick = () => {
    if (editedContent) {
      setOpenConfirmationDialog(true);
    } else {
      return;
    }
  };

  const handleConfirmSave = () => {
    setOpenConfirmationDialog(false);
    handleSaveChanges();
  };

  const handleSaveChanges = async () => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const endpoint = `${config.petitionForm}/UpdateApplication`;
    const requestPayload = {
      user_id: userInfo._id,
      chat_id: selectedApp?.chat_id || applicationToEdit?.chat_id || "",
      Document: editedContent,
      Timestamp: dayjs().format("DD-MM-YYYY"),
    };

    // Use toast.promise to manage toast notifications
    await toast.promise(
      fetch(endpoint, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestPayload),
        redirect: "follow",
      }).then(async (response) => {
        if (!response.ok) {
          throw new Error("Failed to save changes");
        }
        const updatedApplication = await response.text();
        if (generatedApplication?.html) {
          setGeneratedApplication((prev) => ({
            ...prev,
            html: updatedApplication,
          }));
        } else {
          dispatch({
            type: "SET_SELECTED_APPLICATION",
            payload: { ...selectedApp, Document: updatedApplication },
          });
          // setSelectedApplication((prev) => ({
          //   ...prev,
          //   document: updatedApplication,
          // }));
        }
        dispatch(getApplicationTitles(userInfo._id));
        setEditedContent("");
        setIsEditing(false);

        // Deduct credits
        const deductionSuccess = deductCredits(
          {
            userId,
            creditsToDeduct: 1,
            event: "Edit generated application on application drafting page",
          },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      }),
      {
        pending: { render: "Saving changes...", position: "top-right" },
        success: {
          render: "Changes saved successfully! 🎉",
          position: "top-right",
        },
        error: {
          render: "Failed to save changes. Please try again. 🚨",
          position: "top-right",
        },
      }
    );
  };

  const handleCancel = () => {
    setIsEditing(false);
    setApplicationToEdit({});
  };

  useEffect(() => {
    if (Object.keys(applicationToEdit).length > 0) {
      setIsEditing(true);
    }
    return () => {
      setIsEditing(false);
    };
  }, [applicationToEdit]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "41%",
        height: "calc(100vh - 50px)",
        bgcolor: "#f5f5f5",
        boxSizing: "border-box",
        p: "12px 12px 0 12px",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          height: "100%",
          flexGrow: 1,
          bgcolor: "#fff",
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            mb:
              generatedApplication?.html || selectedApp?.Document
                ? "0px"
                : "16px",
            color: "#3C50B1",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Generated Application
        </Typography>
        {(generatedApplication?.html ||
          selectedApp?.Document ||
          Object.keys(applicationToEdit).length > 0) && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {isEditing
              ? [
                  {
                    title: "Save Changes",
                    icon: faCheck,
                    color: "#4CAF50",
                    onClick: handleSaveClick,
                    disabled: !isApplicationEdited,
                  },
                  {
                    title: "Cancel",
                    icon: faXmark,
                    color: "#d2042d",
                    onClick: handleCancel,
                  },
                ].map(({ title, icon, color, onClick, disabled }) => (
                  <StyledTooltip key={title} title={title}>
                    <IconButton onClick={onClick} disabled={disabled}>
                      <FontAwesomeIcon
                        size="xs"
                        icon={icon}
                        style={{ color }}
                      />
                    </IconButton>
                  </StyledTooltip>
                ))
              : [
                  {
                    title: "Edit",
                    icon: faPenToSquare,
                    color: "#2196f3",
                    onClick: handleEdit,
                  },
                  {
                    title: "Save",
                    icon: faFloppyDisk,
                    color: "#4CAF50",
                    onClick: handleSave,
                    disabled:
                      generatedApplication?.metadata?.isSaved ||
                      selectedApp?.isSaved ||
                      applicationToEdit?.isSaved,
                  },
                  {
                    title: "Download",
                    icon: faDownload,
                    color: "#ff9800",
                    onClick: handleDownload,
                  },
                  {
                    title: "Print",
                    icon: faPrint,
                    color: "#d2042d",
                    onClick: handlePrint,
                  },
                ].map(({ title, icon, color, onClick, disabled }) => (
                  <StyledTooltip key={title} title={title}>
                    <IconButton onClick={onClick} disabled={disabled}>
                      <FontAwesomeIcon
                        size="xs"
                        icon={icon}
                        style={{ color }}
                      />
                    </IconButton>
                  </StyledTooltip>
                ))}
          </Stack>
        )}
        <Paper
          sx={{
            flexGrow: 1,
            p: 2,
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
            overflowY: "auto",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#fff",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "6px",
            },
          }}
        >
          {isEditing ? (
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <TextEditor
                initialContent={
                  generatedApplication?.html ||
                  selectedApp?.Document ||
                  applicationToEdit.Document
                }
                handleEdit={handleEditApplication}
              />
            </Box>
          ) : generatedApplication?.html || selectedApp?.Document ? (
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <ReactQuill
                value={generatedApplication?.html || selectedApp?.Document}
                readOnly
                theme="bubble"
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                width="120px"
                height="auto"
                src={appDraftingImage}
                alt="moduleLogo"
              />
              <Typography sx={{ fontSize: "13px" }}>
                {isGenerating
                  ? "Application is being generated..."
                  : "Preview of the draft will be shown here..."}
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        onConfirm={handleConfirmSave}
        title={"Confirm Save"}
        warningMessage={"Saving this document will replace the existing one."}
        confirmationPromptMessage={"Do you want to proceed?"}
        buttonText={"Confirm Save"}
      />
    </Box>
  );
};

export default ApplicationPreview;
