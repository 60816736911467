import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Checkbox,
  Pagination,
  IconButton,
  TextField,
  CardActions,
  CircularProgress,
} from "@mui/material";
import { Stack, styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import config from "../config/api";
import { Slide, ToastContainer, toast } from "react-toastify";
import { showInsufficientCreditsModal } from "../actions/creditActions";
import { deductCredits } from "../utils/HelperFunction/deductCredits";

// Custom styled components
const SearchCard = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
  borderRadius: "8px",
  padding: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const ResultContainer = styled(Box)(({ theme }) => ({
  padding: "30px",
  backgroundColor: "#fff",
  flexGrow: 1,
  marginTop: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
}));

const ResultCard = styled(Card)(({ theme }) => ({
  padding: "10px",
  borderRadius: "8px",
  transition: "box-shadow 0.3s ease, transform 0.2s ease",
  "&:hover": {
    boxShadow: "0 6px 14px rgba(0, 0, 0, 0.3)",
    transform: "translateY(-5px)",
  },
}));

const cleanAndFormatTitle = (title) => {
  if (!title) return "";

  // Remove .pdf suffix if present
  let formattedTitle = title.replace(/\.pdf$/i, "");

  // Remove any leading numbers (e.g., "1960_31_")
  formattedTitle = formattedTitle.replace(/^\d+[_-]?\d*[_-]?/, "");

  // Replace underscores and dashes with spaces
  formattedTitle = formattedTitle.replace(/[_-]/g, " ");

  // Check if the title already ends with a year preceded by a comma
  const yearMatch = formattedTitle.match(/,\s?\d{4}$/);

  // If no year at the end, find the year and add a comma before it
  if (!yearMatch) {
    formattedTitle = formattedTitle.replace(/(\d{4})$/, ", $1");
  }

  // Convert to title case, skipping the year part
  formattedTitle = formattedTitle.replace(/\b\w+/g, (word, index) => {
    // Capitalize each word except already capitalized parts or the year
    if (/\d{4}/.test(word)) {
      return word; // Don't change the year
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return formattedTitle.trim();
};

const LegalLibrary = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const credits = useSelector((state) => state.credit.credits); // Get credits from Redux store
  const userId = useSelector((state) => state.credit.userId); // Get userId from Redux store
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStatutes, setFilteredStatutes] = useState([]);
  const [statuteNames, setStatuteNames] = useState([]);
  const [isStatuteNamesLoading, setIsStatuteNamesLoading] = useState(false);
  const [isFavoriteChecked, setIsFavoriteChecked] = useState(false);
  const [hasNoSearchResults, setHasNoSearchResults] = useState(false);
  const [hasNoFavorites, setHasNoFavorites] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const cardsPerPage = 12;

  // Calculate total pages
  const totalPages = Math.ceil(
    filteredStatutes.length > 0
      ? filteredStatutes.length / cardsPerPage
      : statuteNames.length / cardsPerPage
  );

  // Get the cards to display on the current page
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentStatutes =
    filteredStatutes.length > 0
      ? filteredStatutes.slice(indexOfFirstCard, indexOfLastCard)
      : statuteNames.slice(indexOfFirstCard, indexOfLastCard);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCheckboxChange = (event) => {
    setIsFavoriteChecked(event.target.checked);
    if (event.target.checked) {
      const filtered = statuteNames.filter((statute) => statute.isFavorite);
      setFilteredStatutes(filtered);
      if (filtered.length === 0) {
        setHasNoFavorites(true);
      } else {
        setHasNoFavorites(false);
      }
      setCurrentPage(1);
    } else {
      setHasNoFavorites(false);
      setFilteredStatutes([]);
    }
  };

  const getStatuteNames = async () => {
    const statutesEndpoint = `${config.apiUrl}/get_statute_and_book_pdfs`;
    const favoritesEndpoint = `${config.backendUrl}/getUserFavorites?userId=${userInfo._id}`;

    try {
      setIsStatuteNamesLoading(true);

      // Fetch statutes and favorites in parallel
      const [statutesResponse, favoritesResponse] = await Promise.all([
        fetch(statutesEndpoint, { method: "POST" }),
        fetch(favoritesEndpoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ]);

      if (statutesResponse.ok) {
        const statutesData = await statutesResponse.json();

        let favoriteTitles = [];

        if (favoritesResponse.ok) {
          const favoritesData = await favoritesResponse.json();
          favoriteTitles = favoritesData.map((fav) => fav);
        }

        const updatedStatuteData = statutesData
          .filter((obj) => obj.name !== "...")
          .map((statute) => ({
            title: cleanAndFormatTitle(statute.name),
            url: statute.url,
            type: statute.type,
            isFavorite: favoriteTitles.includes(
              cleanAndFormatTitle(statute.name)
            ),
          }))
          .sort((a, b) =>
            a.title.localeCompare(b.title, undefined, { sensitivity: "base" })
          );

        setStatuteNames(updatedStatuteData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsStatuteNamesLoading(false);
    }
  };

  const getBookPdf = async (url) => {
    const endpoint = `${config.apiUrl}/get_pdf_from_blob_storage`;
    const bookData = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url }),
    });

    return await bookData.blob();
  };

  const toggleFavorite = async (statuteItem) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    const endpoint = statuteItem.isFavorite
      ? `${config.backendUrl}/removeFavorite`
      : `${config.backendUrl}/addFavorite`;

    const requestBody = {
      userId: userInfo._id,
      statuteId: statuteItem.title,
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast.success(
          endpoint.includes("remove")
            ? "Removed from Favorites"
            : "Added to Favorites!",
          {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Slide,
          }
        );
        const updatedStatuteNames = statuteNames.map((statute) =>
          statute.title === statuteItem.title
            ? { ...statute, isFavorite: !statute.isFavorite }
            : statute
        );
        setStatuteNames(updatedStatuteNames);
        if (filteredStatutes.length > 0) {
          const filtered = updatedStatuteNames.filter(
            (statute) => statute.isFavorite
          );
          if (filtered.length === 0) {
            setHasNoFavorites(true);
          }
          setFilteredStatutes(filtered);
        }
        // Deduct credits using the helper
        const deductionSuccess = deductCredits(
          {
            userId,
            creditsToDeduct: 1,
            event: "Toggle Favourite in Legal Library",
          },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReadNow = async (obj) => {
    try {
      if (obj.type === "book") {
        const book = await getBookPdf(obj.url);
        const pdfBlob = new Blob([book], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      } else {
        window.open(obj.url, "_blank");
      }
    } catch (error) {
      console.error("Error retrieving PDF:", error);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      setFilteredStatutes([]);
      setHasNoSearchResults(false);
    }
  };

  const handleSearch = () => {
    if (searchTerm === "") return;
    const filtered = statuteNames.filter((statute) =>
      statute.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filtered.length === 0) {
      setHasNoSearchResults(true);
    } else {
      setHasNoSearchResults(false);
    }
    setFilteredStatutes(filtered);
    setCurrentPage(1);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    getStatuteNames();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f4f6f9",
        boxSizing: "border-box",
        padding: "24px",
        width: "100%",
        height: "calc(100vh - 50px)",
      }}
    >
      {isStatuteNamesLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <SearchCard>
            <TextField
              variant="outlined"
              placeholder="Search by title"
              fullWidth
              InputProps={{
                sx: {
                  p: 0,
                  backgroundColor: "#ffffff",
                  borderRadius: "20px",
                },
                startAdornment: (
                  <SearchIcon sx={{ color: "#303f9f", padding: "8px" }} />
                ),
              }}
              sx={{
                flexGrow: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#ccc",
                  },
                  "&:hover fieldset": {
                    borderColor: "#00509e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#00509e",
                  },
                },
                "& input": {
                  padding: "10px 10px",
                  fontSize: "0.875rem",
                },
              }}
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
            />
            {/* <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#00509e',
                '&:hover': {
                  backgroundColor: '#003366',
                },
                padding: '8px 25px',
                fontSize: '0.875rem',
                textTransform: 'none',
              }}
              startIcon={<SearchIcon />}
              onClick={handleSearch}
            >
              Search
            </Button> */}
            <Typography
              sx={{ marginLeft: 3, marginRight: "2px", fontSize: "14px" }}
            >
              Favorites
            </Typography>
            <Checkbox
              size="small"
              checked={isFavoriteChecked}
              onChange={handleCheckboxChange}
            />
          </SearchCard>
          <ResultContainer>
            {hasNoSearchResults || hasNoFavorites ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    fontSize: "50px",
                    marginBottom: "10px",
                    color: hasNoSearchResults ? "#303f9f" : "#f39c12",
                  }}
                >
                  {hasNoSearchResults ? (
                    <SearchIcon fontSize="inherit" />
                  ) : (
                    <StarIcon fontSize="inherit" />
                  )}
                </Box>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "24px", marginBottom: "10px" }}
                >
                  {hasNoSearchResults
                    ? "No Results Found"
                    : "No Favorites Added"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "16px", color: "#666" }}
                >
                  {hasNoSearchResults
                    ? "Please review your search term or try using broader keywords."
                    : "Mark important books as favorites for quick access later."}
                </Typography>
              </Box>
            ) : (
              <Grid container spacing={3} rowGap={2}>
                {currentStatutes.map((statute) => (
                  <Grid item xs={12} sm={6} md={3} key={statute.id}>
                    <ResultCard>
                      <CardContent
                        sx={{
                          padding: 0,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#00509e",
                            fontWeight: "bold",
                            fontSize: "14px",
                            textAlign: "center",
                            backgroundColor: "#e8f0fe",
                            padding: "4px",
                            borderRadius: "4px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            lineHeight: "1.5",
                            height: "3em",
                          }}
                        >
                          {statute.title}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                          onClick={() => handleReadNow(statute)}
                        >
                          <IconButton size="small">
                            <MenuBookIcon
                              fontSize="small"
                              sx={{ color: "#7cbb00" }}
                            />
                          </IconButton>
                          <Typography
                            variant="body2"
                            sx={{
                              mr: 0.5,
                              mt: 0.5,
                              color: "#303f9f",
                              cursor: "pointer",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            Read
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                          onClick={() => toggleFavorite(statute)}
                        >
                          <IconButton size="small">
                            {statute.isFavorite ? (
                              <StarIcon
                                fontSize="small"
                                sx={{ color: "#ffbb00" }}
                              />
                            ) : (
                              <StarOutlineIcon
                                fontSize="small"
                                sx={{ color: "#ffbb00" }}
                              />
                            )}
                          </IconButton>
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 0.5,
                              color: "#303f9f",
                              cursor: "pointer",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            Favorite
                          </Typography>
                        </Stack>
                      </CardActions>
                    </ResultCard>
                  </Grid>
                ))}
              </Grid>
            )}
          </ResultContainer>
          {statuteNames.length > 0 &&
            !hasNoSearchResults &&
            !hasNoFavorites && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 2,
                  "& .MuiPaginationItem-root": {
                    "&:hover": {
                      color: "#fff",
                      backgroundColor: "#303f9f",
                    },
                    "&.Mui-selected": {
                      color: "#fff",
                      backgroundColor: "#213165",
                      "&:hover": {
                        backgroundColor: "#303f9f",
                      },
                    },
                  },
                }}
              />
            )}
        </>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Slide"
      />
    </Box>
  );
};

export default LegalLibrary;
