import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { fetchOptions } from "../../actions/searchActions";
import CustomYearRangePicker from "../layout/CustomYearRangePicker";
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
const Facet = ({
  facetsData,
  handleChange,
  selectedValues,
  showChips,
  handleClearChip,
  filterMode,
  startYear,
  setStartYear,
  endYear,
  setEndYear,
}) => {
  const [options, setOptions] = useState([]);
  const [lawyerOptions, setLawyerOptions] = useState([]);
  const [judgeOptions, setJudgeOptions] = useState([]);
  const [journalOptions, setJournalOptions] = useState([]);
  const [yearError, setYearError] = useState("");
  const [pageError, setPageError] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingJudge, setLoadingJudge] = React.useState(false);
  const [loadingCourt, setLoadingCourt] = React.useState(false);
  const [loadingJournal, setLoadingJournal] = React.useState(false);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [inputJudge, setInputJudge] = useState("");
  const [inputCourt, setInputCourt] = useState("");
  const [inputJournal, setInputJournal] = useState("");
  const searchResultsState = useSelector((state) => state.searchResults);
  const { dataFetched } = searchResultsState;
  const isFirstRender = useRef(true);
  const handleDeleteChip = (category) => {
    handleClearChip(category);
  };

  const handleSelect = (category, value) => {
 
    handleChange(category, value);
    setInputValue("");
    setInputJudge("");
    setLoading(false);
    setLoadingCourt(false);
    setLoadingJudge(false);
    setLoadingJournal(false);
  };

  // const fetchSuggestions = async (input) => {
  //   try {
  //     setLoading(true);
  //     const requestBody = {
  //       count: true,
  //       search: `Court:${input}*`,
  //       select: 'Court',
  //       queryType: 'full',
  //     };
  //     const response = await fetch(
  //       `${config.searchUrl}/indexes/case_laws_indexing_v5/docs/search?api-version=2024-05-01-preview`,
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'api-key': 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv',
  //         },
  //         body: JSON.stringify(requestBody),
  //       }
  //     );
  //     const data = await response.json();
  //     const suggestions = data.value.map((item) => formatCourtName(item.Court));
  //     const uniqueSuggestions = Array.from(new Set(suggestions)).slice(0, 5);
  //     setOptions(uniqueSuggestions);
  //   } catch (error) {
  //     console.error('Error fetching suggestions:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // Function to filter and set the top 5 relevant options
  // Debounced API call
  const debouncedFetchOptions = useCallback(
    debounce((value, collection, selectedValues) => {
      dispatch(fetchOptions(value, collection, selectedValues));
    }, 500),
    [] // Delay in milliseconds
  );
  const filterOptions = useCallback(
    (input, category) => {
      const optionsForCategory = facetsData?.find(
        (facet) => facet.category === category
      )?.options;

      const filteredStartsWith = optionsForCategory?.filter((option) => {
        const optionText =
          typeof option === "string" ? option : option.name || option.formatted;
        return optionText?.toLowerCase().startsWith(input.toLowerCase());
      });
      const filteredIncludes = optionsForCategory?.filter((option) => {
        const optionText =
          typeof option === "string" ? option : option.name || option.formatted;
        return optionText?.toLowerCase().includes(input.toLowerCase());
      });

      return [
        ...new Set([
          ...(filteredStartsWith || []),
          ...(filteredIncludes || []),
        ]),
      ].slice(0, 5);
    },
    [facetsData]
  );

  const handleInputChange = useCallback(
    (event, value) => {
      setInputCourt(value);
      setLoadingCourt(true);
      if (value && value.length > 2) {
        debouncedFetchOptions(value, "Court", selectedValues);
      } else {
        setOptions([]);
        setLoadingCourt(false);
      }
    },
    [dispatch, facetsData, dataFetched]
  );

  const handleLawyerInputChange = useCallback(
    (event, value) => {
      setInputValue(value);
      setLoading(true);
      if (value && value.length > 2) {
        debouncedFetchOptions(value, "Lawyer_Name", selectedValues);
      } else {
        setLawyerOptions([]);
        setLoading(false);
      }
    },
    [dispatch, facetsData, dataFetched]
  );

  const handleJudgeInputChange = useCallback(
    (event, value) => {
      setInputJudge(value);
      setLoadingJudge(true);
      if (value && value.length > 2) {
        debouncedFetchOptions(value, "Judge_Name", selectedValues);
      } else {
        setJudgeOptions([]);
        setLoadingJudge(false);
      }
    },
    [dispatch, facetsData, dataFetched]
  );

  const handleJournalInputChange = useCallback(
    (event, value) => {
      setInputJournal(value);
      setLoadingJournal(true);
      if (value && value.length > 1) {
        debouncedFetchOptions(value, "Section", selectedValues);
      } else {
        setJournalOptions([]);
        setLoadingJournal(false);
      }
    },
    [dispatch, facetsData, dataFetched]
  );

  const handleYearInput = (event, category) => {
    const value = event.target.value;
    // Only allow numeric input (non-decimal digits)
    if (/^\d*$/.test(value)) {
      debouncedFetchOptions(value, "Year", selectedValues);
      handleSelect(category, value);
      if (value.length === 4 || value === "") {
        // If exactly 4 digits, reset the error and update selected values
        setYearError("");
        //handleSelect(category, value);
      } else if (value.length < 4 || value.length > 4) {
        // If less than 4 digits, show a warning but allow typing
        setYearError("Year must be exactly 4 digits.");
      }
    } else {
      // For non-numeric input, show an error
      setYearError("Please enter a valid 4-digit year");
    }

    // Optional: Clear the chip if value is empty
    if (!value && showChips[category] && filterMode === "standard") {
      handleDeleteChip(category);
    }
  };

  const handlePageInput = (event, category) => {
    const value = event.target.value;
    // Only allow non-decimal numeric input
    // Only allow numeric input (non-decimal digits)
    if (/^\d*$/.test(value)) {
      handleSelect(category, value);
      if ((value.length > 0 && value.length < 5) || value === "") {
        // If exactly 4 digits, reset the error and update selected values
        setPageError("");
        //handleSelect(category, value);
      } else if (value.length > 4) {
        // If less than 4 digits, show a warning but allow typing
        setPageError("Page number must be exactly 4 digits.");
      }
    } else {
      // For non-numeric input, show an error
      setPageError("Please enter a valid 4-digit page number");
    }
    if (!value && showChips[category] && filterMode === "standard") {
      handleDeleteChip(category);
    }
  };

  useEffect(() => {
    if (dataFetched) {
      const filteredLawyerOptions = filterOptions(inputValue, "Lawyer");
      setLawyerOptions(filteredLawyerOptions);
      setLoading(false);
      const filteredJudgeOptions = filterOptions(inputJudge, "Judge");
      setJudgeOptions(filteredJudgeOptions);
      setLoadingJudge(false);
      const filteredCourtOptions = filterOptions(inputCourt, "Court");
      setOptions(filteredCourtOptions);
      setLoadingCourt(false);
      const filteredJournalOptions = filterOptions(inputJournal, "Journal");
      setJournalOptions(filteredJournalOptions);
      setLoadingJournal(false);
    }
  }, [facetsData, dataFetched]);

  useEffect(() => {
    setYearError("");
    setPageError("");
  }, [filterMode]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Mark first render as complete
      return; // Skip initial render
    }
    handleYear(startYear, endYear);
  }, [startYear, endYear]);

  const handleYear = (startYear, endYear) => {
    if (!startYear && !endYear) {
      if (isFirstRender.current) {
        isFirstRender.current = false; // Mark first render as complete
        return; // Skip initial render
      }
      // User cleared both values
      setYearError(""); // Clear any existing errors
      dispatch(fetchOptions(null, "Year", selectedValues)); // Reset Year filter
      handleSelect("Year", null); // Clear Year selection
      return;
    }

    if (!startYear || !endYear) {
      return;
    }

    // Ensure valid year range
    if (startYear > endYear) {
    } else {
      // Dispatch both actions for valid year range
      dispatch(fetchOptions({ startYear, endYear }, "Year", selectedValues));
      handleSelect("Year", { startYear, endYear });
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {filterMode === "standard" ? (
        <Box>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              mb: "4px",
              ml: "5px",
            }}
          >
            Journal
          </Typography>
          <FormControl fullWidth variant="outlined" sx={{ mb: "6px" }}>
            <Autocomplete
              sx={{ mb: "6px" }}
              size="small"
              value={selectedValues?.["Journal"] || ""}
              options={journalOptions}
              onChange={(event, newValue) => handleSelect("Journal", newValue)}
              onInputChange={(e, newValue) => {
                handleJournalInputChange(e, newValue);
                if (
                  !newValue &&
                  showChips?.["Journal"] &&
                  filterMode === "standard"
                ) {
                  handleDeleteChip("Journal");
                }
              }}
              loading={loadingJournal}
              forcePopupIcon={false}
              disableClearable={!selectedValues?.["Journal"]}
              clearIcon={
                selectedValues?.["Journal"] ? (
                  <ClearIcon fontSize="small" />
                ) : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter Journal Name"
                  variant="outlined"
                  sx={{
                    width: "95%",
                    ml: "5px",
                    mb: "8px",
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "12px",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingJournal ? (
                          <CircularProgress color="inherit" size={16} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              componentsProps={{
                paper: {
                  sx: {
                    "& .MuiAutocomplete-listbox": {
                      fontSize: "13px",
                    },
                  },
                },
              }}
            />
          </FormControl>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              mb: "4px",
              ml: "5px",
            }}
          >
            Court
          </Typography>
          <Autocomplete
            sx={{ mb: "6px" }}
            size="small"
            value={selectedValues?.["Court"] || ""}
            isOptionEqualToValue={(option, value) =>
              option.formatted === value?.formatted
            }
            getOptionLabel={(option) => option.formatted || ""}
            options={options}
            onChange={(event, newValue) => {
              handleSelect("Court", newValue);
            }}
            onInputChange={(e, newValue) => {
              handleInputChange(e, newValue);
              if (!newValue && showChips?.["Court"]) {
                handleDeleteChip("Court");
              }
            }}
            loading={loadingCourt}
            forcePopupIcon={false}
            disableClearable={!selectedValues?.["Court"]}
            clearIcon={
              selectedValues?.["Court"] ? <ClearIcon fontSize="small" /> : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter Court Name"
                variant="outlined"
                sx={{
                  width: "95%",
                  ml: "5px",
                  mb: "8px",
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "12px",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingCourt ? (
                        <CircularProgress color="inherit" size={16} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            componentsProps={{
              paper: {
                sx: {
                  "& .MuiAutocomplete-listbox": {
                    fontSize: "13px",
                  },
                },
              },
            }}
          />
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              mb: "4px",
              ml: "5px",
            }}
          >
            Year
          </Typography>
          {/* <TextField
            size="small"
            fullWidth
            placeholder="Enter Year (e.g. 2023)"
            variant="outlined"
            value={selectedValues?.["Year"] || ""}
            onChange={(e) => handleYearInput(e, "Year")}
            error={!!yearError}
            helperText={yearError ? yearError : ""}
            sx={{
              width: "95%",
              ml: "5px",
              mb: "12px",
              "& .MuiInputBase-input": {
                fontSize: "13px",
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "12px",
              },
            }}
          /> */}
          <Box
            sx={{
              paddingRight: "8px",
            }}
          >
            <CustomYearRangePicker
              startYear={startYear}
              endYear={endYear}
              onStartYearChange={setStartYear}
              onEndYearChange={setEndYear}
              Journal={selectedValues?.["Journal"]}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              mb: "4px",
              ml: "5px",
            }}
          >
            Judge
          </Typography>
          <Autocomplete
            sx={{ mb: "6px" }}
            size="small"
            isOptionEqualToValue={(option, value) =>
              option.formatted === value?.formatted
            }
            getOptionLabel={(option) => option.formatted || ""}
            // open={openLawyerOptions && options.length > 0}
            value={selectedValues?.["Judge"] || ""}
            options={judgeOptions}
            onChange={(event, newValue) => {
              handleSelect("Judge", newValue);
            }}
            onInputChange={(e, newValue) => {
              handleJudgeInputChange(e, newValue);
              if (!newValue && showChips?.["Judge"]) {
                handleDeleteChip("Judge");
              }
            }}
            loading={loadingJudge}
            forcePopupIcon={false}
            clearIcon={
              selectedValues?.["Judge"] ? <ClearIcon fontSize="small" /> : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter Judge Name"
                variant="outlined"
                sx={{
                  width: "95%",
                  ml: "5px",
                  mb: "8px",
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "12px",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingJudge ? (
                        <CircularProgress color="inherit" size={16} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            componentsProps={{
              paper: {
                sx: {
                  "& .MuiAutocomplete-listbox": {
                    fontSize: "13px",
                  },
                },
              },
            }}
          />
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              mb: "4px",
              ml: "5px",
            }}
          >
            Lawyer
          </Typography>
          <Autocomplete
            sx={{ mb: "6px" }}
            size="small"
            // open={openLawyerOptions && options.length > 0}
            value={selectedValues?.["Lawyer"] || ""}
            isOptionEqualToValue={(option, value) =>
              option.formatted === value?.formatted
            }
            getOptionLabel={(option) => option.formatted || ""}
            options={lawyerOptions}
            onChange={(event, newValue) => {
              handleSelect("Lawyer", newValue);
            }}
            onInputChange={(e, newValue) => {
              handleLawyerInputChange(e, newValue);
              if (!newValue && showChips?.["Lawyer"]) {
                handleDeleteChip("Lawyer");
              }
            }}
            loading={loading}
            forcePopupIcon={false}
            clearIcon={
              selectedValues?.["Lawyer"] ? <ClearIcon fontSize="small" /> : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter Lawyer Name"
                variant="outlined"
                sx={{
                  width: "95%",
                  ml: "5px",
                  mb: "8px",
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "12px",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={16} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            componentsProps={{
              paper: {
                sx: {
                  "& .MuiAutocomplete-listbox": {
                    fontSize: "13px",
                  },
                },
              },
            }}
          />
        </Box>
      ) : (
        <Box sx={{ mb: 1 }}>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              mb: "4px",
              ml: "5px",
            }}
          >
            Journal
          </Typography>
          <FormControl fullWidth variant="outlined" sx={{ mb: "6px" }}>
            <Autocomplete
              sx={{ mb: "6px" }}
              size="small"
              value={selectedValues?.["Journal"] || ""}
              options={journalOptions}
              onChange={(event, newValue) => handleSelect("Journal", newValue)}
              onInputChange={(e, newValue) => {
                handleJournalInputChange(e, newValue);
                if (
                  !newValue &&
                  showChips?.["Journal"] &&
                  filterMode === "standard"
                ) {
                  handleDeleteChip("Journal");
                }
              }}
              loading={loadingJournal}
              forcePopupIcon={false}
              disableClearable={!selectedValues?.["Journal"]}
              clearIcon={
                selectedValues?.["Journal"] ? (
                  <ClearIcon fontSize="small" />
                ) : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter Journal Name"
                  variant="outlined"
                  sx={{
                    width: "95%",
                    ml: "5px",
                    mb: "8px",
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "12px",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingJournal ? (
                          <CircularProgress color="inherit" size={16} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              componentsProps={{
                paper: {
                  sx: {
                    "& .MuiAutocomplete-listbox": {
                      fontSize: "13px",
                    },
                  },
                },
              }}
            />
          </FormControl>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              mb: "4px",
              ml: "5px",
            }}
          >
            Year
          </Typography>
          <TextField
            size="small"
            fullWidth
            placeholder="Enter Year (e.g. 2023)"
            variant="outlined"
            value={selectedValues?.["Year"] || ""}
            onChange={(e) => handleYearInput(e, "Year")}
            error={!!yearError}
            helperText={yearError ? yearError : ""}
            sx={{
              width: "95%",
              ml: "5px",
              mb: "12px",
              "& .MuiInputBase-input": {
                fontSize: "13px",
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "12px",
              },
            }}
          />
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              mb: "4px",
              ml: "5px",
            }}
          >
            Page
          </Typography>
          <TextField
            size="small"
            fullWidth
            placeholder="Enter Page Number (e.g. 0123)"
            variant="outlined"
            value={selectedValues?.["Page"] || ""}
            onChange={(e) => handlePageInput(e, "Page")}
            error={!!pageError}
            helperText={pageError ? pageError : ""}
            sx={{
              width: "95%",
              ml: "5px",
              mb: "12px",
              "& .MuiInputBase-input": {
                fontSize: "13px",
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "12px",
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Facet;
