import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Pagination,
  CircularProgress,
  InputAdornment,
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import { DateRangePicker } from "mui-daterange-picker";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import config from "../../config/api";

const CreditsHistoryModal = ({ open, onClose, userInfo }) => {
  const [creditHistory, setCreditHistory] = useState([]);
  const [filters, setFilters] = React.useState({
    start_datetime: moment().startOf("day").toISOString(),
    end_datetime: moment().endOf("day").toISOString(),
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const openProfile = Boolean(anchorEl);
  const itemsPerPage = 10;

  useEffect(() => {
    if (open) {
      setPage(1);
      fetchCreditHistory();
    }
  }, [open, userInfo._id, filters]);

  useEffect(() => {
    return () => {
      setFilters({
        start_datetime: moment().startOf("day").toISOString(),
        end_datetime: moment().endOf("day").toISOString(),
      });
    };
  }, []);

  const fetchCreditHistory = async () => {
    setLoading(true);
    setCreditHistory([]);

    // Use filters for effective dates
    const effectiveStartDate = moment(filters.start_datetime).isValid()
      ? moment(filters.start_datetime)
      : null; // Handle invalid dates
    const effectiveEndDate = moment(filters.end_datetime).isValid()
      ? moment(filters.end_datetime)
      : null;

    if (!effectiveStartDate || !effectiveEndDate) {
      console.error("Invalid date range in filters.");
      toast.error("Please select a valid date range.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${config.backendUrl}/getCreditHistory?userId=${userInfo._id}&startDate=${effectiveStartDate}&endDate=${effectiveEndDate}`
      );
      const data = await response.json();
      if (data.creditHistory) {
        // Sort the credit history by timestamp in descending order
        const sortedCreditHistory = data.creditHistory.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        setCreditHistory(sortedCreditHistory);
      }
    } catch (error) {
      setCreditHistory([]);
      if (error.response) {
        const errorMessage = error.response.data.message || "Request failed.";
        console.log(errorMessage);
        toast.error(errorMessage); // Display error message in toast
      } else {
        console.error("Unexpected error in requestCredits API:", error);
        toast.error("An unexpected error occurred. Please try again.");
      }
    }

    setLoading(false);
  };

  const handleSearch = () => {
    setPage(1);
    fetchCreditHistory();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedHistory = creditHistory.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setFilters({
          start_datetime: moment().startOf("day").toISOString(),
          end_datetime: moment().endOf("day").toISOString(),
        });
      }}
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          padding: "24px",
          borderRadius: "8px",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            textAlign: "center",
            flex: 1,
            fontSize: "18px",
            fontWeight: "bold",
            color: "#00509e",
          }}
        >
          Credits History
        </Typography>
        <IconButton
          onClick={() => {
            onClose();
            setFilters({
              start_datetime: moment().startOf("day").toISOString(),
              end_datetime: moment().endOf("day").toISOString(),
            });
          }}
          sx={{ position: "absolute", right: 16, color: "#00509e" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          overflowY: "auto",
          scrollBehavior: "smooth",
          "::-webkit-scrollbar": {
            width: "4px",
          },
          "::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "6px",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#00509e",
            borderRadius: "6px",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "14px",
              mb: 2,
            }}
          >
            {/* <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            sx={{ width: "30%" }}
          /> */}
            <>
              <div
                style={{
                  border: "1px solid rgb(118, 118, 118, 0.5)",
                  // color: "rgb(118, 118, 118, 1)",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  // height: "55px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 20px 10px 10px",
                  background: "white",
                }}
                onClick={filters.disabled ? () => {} : handleClick}
              >
                <span style={{ marginRight: "10px" }}>
                  {filters?.label || "Date Range"}:{" "}
                </span>{" "}
                {moment(filters?.start_datetime).format("YYYY/MM/DD")} to{" "}
                {moment(filters?.end_datetime).format("YYYY/MM/DD")}
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openProfile}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "& .MuiList-root": {
                    overflowX: "hidden",
                    overflowY: "hidden",
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                  "& .MuiPaper-root": {
                    overflowX: "hidden",
                    overflowY: "hidden",
                    top: "153px !important",
                  },
                }}
              >
                <MenuItem sx={{ p: 0 }}>
                  <div style={{ position: "relative" }}>
                    <DateRangePicker
                      open={true}
                      initialDateRange={{
                        startDate: new Date(filters?.start_datetime),
                        endDate: new Date(filters?.end_datetime),
                      }}
                      toggle={() => handleClose()} // Close the picker on toggle
                      onChange={({ startDate, endDate }) => {
                        if (startDate && endDate) {
                          setFilters({
                            start_datetime: moment(startDate)
                              .startOf("day")
                              .toISOString(),
                            end_datetime: moment(endDate)
                              .endOf("day")
                              .toISOString(),
                          });
                          setAnchorEl(null);
                        } else {
                          console.error("Invalid date range selected");
                        }
                      }}
                    />
                  </div>
                </MenuItem>
              </Menu>
            </>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#003D78",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
          <Box
            sx={{
              overflowY: "auto", // Enable scroll only for this box
              maxHeight: "400px", // Set max height for the table body
              position: "relative",
              scrollBehavior: "smooth",
              "::-webkit-scrollbar": {
                width: "4px",
              },
              "::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "6px",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#00509e",
                borderRadius: "6px",
              },
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                borderRadius: "12px",
                overflow: "hidden",
                minHeight: 400,
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="credits history table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 600, color: "#101936", py: 2 }}
                    >
                      Transaction Type
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 600, color: "#101936", py: 2 }}
                    >
                      Credits
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 600, color: "#101936", py: 2 }}
                    >
                      Remaining Credits
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 600, color: "#101936", py: 2 }}
                    >
                      Timestamp
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: 600, color: "#101936", py: 2 }}
                    >
                      Event
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 200,
                        position: "absolute",
                        width: "100%",
                        bgcolor: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : creditHistory.length === 0 ? (
                    <TableRow>
                      <TableCell
                        sx={{ py: 2, color: "#101936", fontWeight: 500 }}
                        colSpan={5}
                        align="center"
                      >
                        <Typography
                          variant="h6"
                          color="text.secondary"
                          sx={{ py: 5 }}
                        >
                          No records found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    paginatedHistory.map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{ py: 2, color: "#101936", fontWeight: 500 }}
                          component="th"
                          scope="row"
                        >
                          {row.transactionType}
                        </TableCell>
                        <TableCell
                          sx={{ py: 2, color: "#101936", fontWeight: 500 }}
                          align="center"
                        >
                          {row.credits}
                        </TableCell>
                        <TableCell
                          sx={{ py: 2, color: "#101936", fontWeight: 500 }}
                          align="center"
                        >
                          {row.remainingCredits}
                        </TableCell>
                        <TableCell
                          sx={{ py: 2, color: "#101936", fontWeight: 500 }}
                        >
                          {moment(row.timestamp).format("lll")}
                        </TableCell>
                        <TableCell
                          sx={{ py: 2, color: "#101936", fontWeight: 500 }}
                        >
                          {row.event}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {creditHistory.length > 0 && !loading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Pagination
                count={Math.ceil(creditHistory.length / itemsPerPage)}
                page={page}
                onChange={handlePageChange}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 2,
                  "& .MuiPaginationItem-root": {
                    "&:hover": {
                      color: "#fff",
                      backgroundColor: "#303f9f",
                    },
                    "&.Mui-selected": {
                      color: "#fff",
                      backgroundColor: "#213165",
                      "&:hover": {
                        backgroundColor: "#303f9f",
                      },
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      {/* </Box> */}
    </Dialog>
  );
};

export default CreditsHistoryModal;
