import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  TextField,
} from "@mui/material";

const TitleInputModal = ({ open, onClose, onSave }) => {
  const [title, setTitle] = useState("");

  const handleSave = () => {
    if (title.trim()) {
      onSave(title);
      setTitle("");
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
    setTitle("");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            padding: "14px",
            background: "#fff",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        {/* Dialog Title */}
        <DialogTitle
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "16px",
          }}
        >
          Enter Title
        </DialogTitle>

        {/* Dialog Content */}
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            //justifyContent: "center",
            //alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            textAlign="left"
            sx={{ marginBottom: 2, fontSize: "14px" }}
          >
            Please provide a title for your document
          </Typography>
          <TextField
            size="small"
            required
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            InputProps={{
              sx: { fontSize: "13px" },
            }}
            InputLabelProps={{ sx: { fontSize: "13px" } }}
            sx={{
              marginBottom: 2,
            }}
          />
        </DialogContent>

        {/* Dialog Actions */}
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            marginTop: 2,
          }}
        >
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            sx={{
              borderRadius: "24px",
              padding: "8px 24px",
              textTransform: "none",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            sx={{
              borderRadius: "24px",
              padding: "8px 24px",
              textTransform: "none",
              fontWeight: "bold",
            }}
            disabled={!title.trim()} // Disable save if input is empty
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TitleInputModal;
