import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dialog, DialogContent, Box, Typography } from "@mui/material";

const DocumentPreview = ({ open, onClose, content }) => {
  const cleanQuillOutput = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    div.querySelectorAll(".ql-align-center, .ql-align-right").forEach((el) => {
      if (el.classList.contains("ql-align-center")) {
        el.style.textAlign = "center";
        el.classList.remove("ql-align-center");
      }
      if (el.classList.contains("ql-align-right")) {
        el.style.textAlign = "right";
        el.classList.remove("ql-align-right");
      }
    });
    return div.innerHTML;
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          overflowY: "auto",
          "::-webkit-scrollbar": {
            width: "5px",
          },
          "::-webkit-scrollbar-track": {
            background: "#fff",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#bbb",
            borderRadius: "6px",
          },
        }}
      >
        {!content ? (
          <Typography>No data found</Typography>
        ) : (
          <Typography
            sx={{ padding: 1, fontSize: "13px" }}
            dangerouslySetInnerHTML={{
              __html: cleanQuillOutput(content),
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentPreview;
