import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CelebrationIcon from "@mui/icons-material/Celebration";

const CongratulationsModal = ({ open, onClose, credits = 150 }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 4,
          boxShadow: 24,
          p: 4,
          "@media (max-width:600px)": {
            width: "90%",
          },
        }}
      >
        <IconButton
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#003D78",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <CelebrationIcon
            sx={{
              fontSize: 64,
              color: "#003D78",
              mb: 2,
            }}
          />
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Congratulations!
          </Typography>
          <Divider sx={{ width: "100%", my: 2 }} />
          <Typography
            variant="h6"
            sx={{ mb: 2, color: "#003D78", fontWeight: "bold" }}
          >
           {` You've Received ${credits} Free Credits!`}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
           {` Your account has been topped up with  free ${credits}. Enjoy using
            our services with your new balance!`}
          </Typography>
          <Button
            onClick={onClose}
            variant="contained"
            color="primary"
            size="large"
            sx={{
              borderRadius: 30,
              px: 4,
              py: 1,
              textTransform: "none",
              fontSize: "1rem",
              bgcolor: "#003D78",
              "&:hover": {
                bgcolor: "#003D78",
              },
            }}
          >
            Start Using Credits
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CongratulationsModal;
