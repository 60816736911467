import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  warningMessage,
  confirmationPromptMessage,
  buttonText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
          padding: "16px",
          background: "#fff",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      {/* Dialog Title */}
      <DialogTitle
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "center",
          paddingBottom: "12px",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        {title}
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ marginBottom: 1, fontSize: "16px" }}
        >
          {warningMessage}
        </Typography>
        <Typography variant="body2" color="textSecondary" textAlign="center">
          {confirmationPromptMessage}
        </Typography>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          marginTop: 2,
        }}
      >
        <Button
          onClick={onClose}
          color="error"
          variant="outlined"
          sx={{
            borderRadius: "24px",
            padding: "8px 24px",
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          sx={{
            borderRadius: "24px",
            padding: "8px 24px",
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
