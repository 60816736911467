import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Pagination,
  IconButton,
  CircularProgress,
  Modal,
  Button,
  Snackbar,
  Alert,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import CaseLawPreviewModal from "./UI/CaseLawPreviewModal";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findCaseSummary, searchResults } from "../actions/searchActions";
import CaseLawSearchBar from "./UI/CaseLawSearchBar";
import { faRectangleList } from "@fortawesome/free-solid-svg-icons";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InsightsIcon from "@mui/icons-material/Insights";
import CaseDetailDialog from "./CaseDetailDialog";
import CaseSummaryDialog from "./CaseSummaryDialog";
import { showInsufficientCreditsModal } from "../actions/creditActions";
import { deductCredits } from "../utils/HelperFunction/deductCredits";
import { fetchBookmarkGroups } from "../store/Bookmarks/bookmarksActions";

const deleteCaseUrl =
  "https://rg-law-gpt-be-auth2.azurewebsites.net/api/deleteCaseOrStatuteFromGroup";

const getOriginalJudgement = (caseDescription) => {
  const parts = caseDescription.split(/ORDER|JUDGEMENT|JUDGMENT/);

  const originalJudgement = parts[1] ? parts[1].trim() : "";

  return originalJudgement ? originalJudgement : caseDescription;
};

const SearchResults = ({
  searchKeyword,
  setSearchKeyword,
  currentPage,
  setCurrentPage,
  setIsNewCaseLawSearch,
  searchMode,
  updateSearchMode,
  sortBy,
  updateSortBy,
  resultsCount,
  updateResultsCount,
}) => {
  const dispatch = useDispatch();

  const searchResultsState = useSelector((state) => state.searchResults);
  const { loading, results } = searchResultsState;

  const searchSelectedFilters = useSelector((state) => state.selectedFilters);
  const { selectedFilters } = searchSelectedFilters;

  const bookmarkGroups = useSelector((state) => state.bookmarkGroups);
  const { groups } = bookmarkGroups;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [openPreview, setOpenPreview] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);
  const [bookmarkGroupName, setBookmarkGroupName] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [caseNote, setCaseNote] = useState("");
  const [caseNoteError, setCaseNoteError] = useState("");
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
  const [bookmarkedCaseIds, setBookmarkedCaseIds] = useState(new Set());
  const [openSummary, setOpenSummary] = useState(false);
  const [openCaseInsights, setOpenCaseInsights] = useState(false);
  const [isMount, setIsMount] = useState(false);
  const isInitialSearchDone = useRef(false);

  // Check if sufficient credits are available
  const credits = useSelector((state) => state.credit.credits); // Get credits from Redux store
  const userId = useSelector((state) => state.credit.userId); // Get userId from Redux store
  const handleCloseSummary = () => {
    setOpenSummary(false);
  };

  const handleCloseCaseInsights = () => {
    setOpenCaseInsights(false);
  };

  const handleSearchModeChange = (value) => {
    updateSearchMode(value);
  };

  const handleSortByChange = (value) => {
    updateSortBy(value);
  };

  const handleChangeResultsCount = (value) => {
    updateResultsCount(value);
  };

  const handlePreviewClick = (caseItem) => {
    setSelectedCase(caseItem);
    setOpenPreview(true);
  };

  const handleSummaryClick = async (citation) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    dispatch(findCaseSummary(citation));
    setOpenSummary(true);
    // Deduct credits using the helper
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Fetch case summary on Case Search",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleBookmarkClick = async (caseItem) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }

    setSelectedCase(caseItem);
    const defaultGroup = groups.find(
      (group) => group.groupName === "Bookmarks"
    );

    setSelectedGroup(defaultGroup._id);
    setOpenBookmarkModal(true);
    // Deduct credits using the helper
    const deductionSuccess = deductCredits(
      { userId, creditsToDeduct: 1, event: "Bookmark on case Search" },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleCloseBookmarkModal = () => {
    setOpenBookmarkModal(false);
    setSelectedGroup("");
    setCaseNote("");
    setCaseNoteError("");
    setIsCreatingNewGroup(false);
  };

  const handleCreateBookmarkGroup = async () => {
    if (!bookmarkGroupName) return;

    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }
      const userId = userInfo._id;
      const response = await fetch(
        "https://rg-law-gpt-be-auth2.azurewebsites.net/api/createBookmarkGroup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            groupName: bookmarkGroupName,
            caseId: selectedCase.doc_id,
            caseNote,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${bookmarkGroupName}`,
        });
        setBookmarkedCaseIds(
          (prevIds) => new Set([...prevIds, selectedCase.doc_id])
        );
        setOpenBookmarkModal(false);
        setBookmarkGroupName("");
        setCaseNote("");
        setIsCreatingNewGroup(false);
        dispatch(fetchBookmarkGroups(userInfo._id));
        // Deduct credits using the helper
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct: 1, event: "Create Bookmark Group" },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      } else {
        throw new Error(data.message || "Failed to create bookmark group.");
      }
    } catch (error) {
      setSnackbarMessage({ type: "error", text: error.message });
    }
  };

  const handleAddCaseToGroup = async () => {
    if (!selectedGroup) return;

    try {
      const userId = userInfo._id;
      const response = await fetch(
        "https://rg-law-gpt-be-auth2.azurewebsites.net/api/addCaseToGroup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            groupId: selectedGroup,
            caseId: selectedCase.doc_id,
            caseNote,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${
            groups?.find((group) => group._id === selectedGroup).groupName
          }`,
        });
        setBookmarkedCaseIds(
          (prevIds) => new Set([...prevIds, selectedCase.doc_id])
        );
        setOpenBookmarkModal(false);
        setCaseNote("");
      } else {
        throw new Error(data.message || "Failed to add case to group.");
      }
    } catch (error) {
      setSnackbarMessage({ type: "error", text: error.message });
    }
  };

  const findGroupIdByCaseId = (documents, caseId) => {
    // Find the document where the caseId exists in the 'cases' array
    const group = documents.find((doc) =>
      doc.cases.some((caseItem) => caseItem.id === caseId)
    );

    // Return the _id of the group or null if not found
    return group ? group._id : null;
  };

  const handleUnBookmark = async (caseId) => {
    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }

      //fetchBookmarkGroups();
      const groupId = findGroupIdByCaseId(groups, caseId);
      const response = await fetch(deleteCaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id, // User ID from context or state
          groupId, // Group ID where the case is bookmarked
          caseId, // Case ID to delete
        }),
      });

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: "Bookmark removed",
        });
        // Update the state by removing the case ID from bookmarked case IDs
        setBookmarkedCaseIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(caseId);
          return updatedIds;
        });
        // Deduct credits using the helper
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct: 1, event: "Un Bookmark on Case Search" },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      } else {
        console.error("Error deleting bookmarked case");
      }
    } catch (error) {
      console.error("Error deleting bookmarked case:", error);
    }
  };

  const handleNoteChange = (value) => {
    if (value.length <= 2000) {
      setCaseNote(value);
      setCaseNoteError("");
    } else {
      setCaseNoteError("You can only input 2000 characters");
    }
  };

  const handlePageChange = async (event, value) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    setCurrentPage(value);
    dispatch({
      type: "SEARCH_FILTER_PAGINATION_SET",
      payload: { pageNumber: value, resultsCount },
    });

    dispatch(
      searchResults(
        searchKeyword,
        (value - 1) * resultsCount,
        resultsCount,
        searchMode,
        sortBy
      )
    );
    // Deduct credits using the helper
    const deductionSuccess = deductCredits(
      { userId, creditsToDeduct: 1, event: "Pagination Click on Case Search" },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const formatCourtName = (courtName) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(courtName, "text/html");

    const formatTextContent = (name) => {
      return name
        .toLowerCase()
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const walkNodes = (node) => {
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          child.nodeValue = formatTextContent(child.nodeValue);
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          walkNodes(child);
        }
      });
    };

    walkNodes(doc.body);

    return "&bull; " + doc.body.innerHTML;
  };

  const formatCourtNameWithoutTags = (courtName) => {
    if (!courtName) return "";

    return courtName
      .toLowerCase()
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatCaseTitle = (title) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(title, "text/html");

    const formatTextContent = (text) => {
      return text
        .split("Honorable")[0]
        .toLowerCase()
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    };

    const walkNodes = (node) => {
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          child.nodeValue = formatTextContent(child.nodeValue);
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          walkNodes(child);
        }
      });
    };

    walkNodes(doc.body);

    return doc.body.innerHTML;
  };

  const getHighlightedSentences = (description) => {
    if (!description || description.trim() === "") return "";

    let sentences = [];

    // Split the string into sentences using delimiters like ., ?, !, etc.
    const splitSentences = description.split(/(?<=[.!?,])\s+/); // Split on punctuation followed by space
    sentences = sentences.concat(splitSentences); // Merge into one array of sentences

    // Prioritize sentences that contain <span> tags (highlighted parts)
    const highlightedSentences = sentences.filter((sentence) =>
      /<span/.test(sentence)
    );
    const unhighlightedSentences = sentences.filter(
      (sentence) => !/<span/.test(sentence)
    );

    // Combine the highlighted sentences first, followed by the unhighlighted ones
    const resultSentences = [
      ...highlightedSentences,
      ...unhighlightedSentences,
    ];

    // Join the resulting sentences back together into a single string
    return resultSentences.join(" ").trim();
  };

  const totalResults = results?.count || results?.length || 0;

  useEffect(() => {
    if (!isInitialSearchDone.current) {
      dispatch(
        searchResults(
          searchKeyword,
          (currentPage - 1) * resultsCount,
          resultsCount,
          searchMode,
          sortBy
        )
      );
      isInitialSearchDone.current = true;
      setIsMount(true);
    }
  }, [dispatch, searchKeyword, currentPage, resultsCount, searchMode, sortBy]);

  useEffect(() => {
    if (!selectedFilters && isMount) {
      dispatch(
        searchResults(
          searchKeyword,
          (currentPage - 1) * resultsCount,
          resultsCount,
          searchMode,
          sortBy
        )
      );
    }
  }, [currentPage, dispatch, resultsCount, sortBy, searchMode]);

  useEffect(() => {
    // Fetch bookmark groups when the component mounts or when userInfo changes
    if (userInfo._id) {
      dispatch(fetchBookmarkGroups(userInfo._id));
    }
    return () => {
      setSearchKeyword("");
    };
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (groups) {
      // Extract all case IDs from the cases array in each group document
      const allCaseIds = groups?.flatMap((group) =>
        group.cases.map((caseItem) => caseItem.id)
      );

      setBookmarkedCaseIds(new Set(allCaseIds)); // Store IDs in a Set for fast lookup
      const defaultGroup = groups?.find(
        (group) => group.groupName === "Bookmarks"
      );
      setSelectedGroup(defaultGroup?._id);
    }
  }, [groups]);

  return (
    <Box
      sx={{
        width: "80%",
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 50px)",
        flex: 5,
        boxSizing: "border-box",
        padding: "12px 12px 0 4px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <CaseLawSearchBar
        resultsCount={resultsCount}
        updateResultsCount={handleChangeResultsCount}
        sortBy={sortBy}
        updateSortBy={handleSortByChange}
        searchMode={searchMode}
        updateSearchMode={handleSearchModeChange}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        updateCurrentPage={setCurrentPage}
        setIsNewCaseLawSearch={setIsNewCaseLawSearch}
      />
      {results?.count === 0 ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              fontSize: "50px",
              marginBottom: "10px",
              color: "#303f9f",
            }}
          >
            <SearchIcon fontSize="inherit" />
          </Box>
          <Typography
            variant="h5"
            sx={{ fontSize: "24px", marginBottom: "10px" }}
          >
            No Results Found
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "16px", color: "#666" }}>
            Please review your search term or filter criteria.
          </Typography>
        </Box>
      ) : (
        <React.Fragment>
          {loading ? (
            <Box
              sx={{
                height: "10%",
                marginTop: "24px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={24} />
            </Box>
          ) : (
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  m: "12px 0",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", ml: 0.5, fontSize: "18px" }}
                >
                  <span style={{ color: "#d2042d", fontWeight: "bold" }}>
                    {totalResults.toLocaleString()}
                  </span>{" "}
                  {totalResults === 182814 ? "Total Cases" : "Cases Found"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    sx={{
                      marginRight: "4px",
                      fontSize: "13px",
                      color: "#555",
                      fontWeight: "500",
                    }}
                    htmlFor="resultsCount"
                  >
                    Show:
                  </InputLabel>
                  <FormControl
                    variant="outlined"
                    sx={{
                      width: 100,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "6px",
                        height: "30px",
                        padding: "8px",
                      },
                      "& fieldset": {
                        borderColor: "#ddd",
                        borderWidth: "1px",
                      },
                      "& .MuiSelect-icon": {
                        right: "4px",
                      },
                      "& .MuiSelect-select": {
                        padding: "0px",
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Select
                      id="resultsCount"
                      value={resultsCount}
                      onChange={async (e) => {
                        let creditsToDeduct = 1;
                        if (credits <= 0 || credits <= creditsToDeduct) {
                          dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
                          return;
                        }
                        updateResultsCount(e.target.value);
                        setCurrentPage(1);
                        // Deduct credits using the helper
                        const deductionSuccess = deductCredits(
                          {
                            userId,
                            creditsToDeduct: 1,
                            event: "Change Page Size on Case Search",
                          },
                          dispatch
                        );

                        if (!deductionSuccess) {
                          console.warn(
                            "Failed to deduct credits. Ensure retry or user notification."
                          );
                        }
                      }}
                      sx={{
                        fontSize: "13px",
                        height: "30px",
                        bgcolor: "#fff",
                      }}
                    >
                      <MenuItem value={10} sx={{ fontSize: "0.875rem" }}>
                        10 results
                      </MenuItem>
                      <MenuItem value={20} sx={{ fontSize: "0.875rem" }}>
                        20 results
                      </MenuItem>
                      <MenuItem value={50} sx={{ fontSize: "0.875rem" }}>
                        50 results
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <List
                sx={{
                  flexGrow: 1,
                  backgroundColor: "rgb(255, 255, 255)",
                  borderRadius: "8px",
                  overflowY: "auto",
                  overflowX: "hidden",

                  scrollBehavior: "smooth",
                  "::-webkit-scrollbar": {
                    width: "5px",
                  },
                  "::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#bbb",
                    borderRadius: "6px",
                  },
                }}
              >
                {results?.["search_results"]
                  ? results?.["search_results"]?.results?.map((caseItem) => (
                      <ListItem
                        key={caseItem.doc_id}
                        sx={{
                          alignItems: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            p: 1,
                            width: "99%",
                            border: "1px solid #ddd",
                            maxWidth: "99%",
                            minWidth: "99%",
                            borderRadius: "5px",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
                            backgroundColor: "#fff",
                          }}
                        >
                          <ListItemText
                            primary={
                              <>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    color: "#303f9f",
                                    ml: 0.5,
                                    cursor: "pointer",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={() => {
                                    dispatch({
                                      type: "CASE_DETAIL_SUCCESS",
                                      payload: caseItem,
                                    });
                                    setOpenCaseInsights(true);
                                    // dispatch(
                                    //   findCaseSummary(caseItem.Citation_Name)
                                    // );
                                    // navigate('/app/case-detail');
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: formatCaseTitle(
                                      caseItem["@search.highlights"]
                                        ?.Title?.[0] ||
                                        caseItem?.Title ||
                                        caseItem?.highlighted?.Title
                                    ),
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 1,
                                    alignItems: "center",
                                    color: "#021657",
                                    p: 1,
                                  }}
                                >
                                  {caseItem["@search.highlights"]
                                    ?.Citation_Name ? (
                                    <Typography
                                      variant="body2"
                                      dangerouslySetInnerHTML={{
                                        __html: `&#8226; ${caseItem["@search.highlights"]?.Citation_Name}`,
                                      }}
                                    />
                                  ) : (
                                    <Typography variant="body2">
                                      &bull; {caseItem.Citation_Name}
                                    </Typography>
                                  )}

                                  {caseItem["@search.highlights"]?.Court ||
                                  caseItem.highlighted?.Court ? (
                                    <Typography
                                      variant="body2"
                                      dangerouslySetInnerHTML={{
                                        __html: formatCourtName(
                                          caseItem["@search.highlights"]
                                            ?.Court ||
                                            caseItem.highlighted.Court
                                        ),
                                      }}
                                    />
                                  ) : (
                                    <Typography variant="body2">
                                      &bull;{" "}
                                      {formatCourtNameWithoutTags(
                                        caseItem.Court
                                      )}
                                    </Typography>
                                  )}
                                </Box>
                              </>
                            }
                            secondary={
                              <>
                                {caseItem["@search.highlights"]
                                  ?.case_description ? (
                                  <Typography
                                    sx={{
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 3,
                                      overflow: "hidden",
                                      bgcolor: "#f5f5f5",
                                      color: "black",
                                      p: "8px 8px 0px 8px",
                                      mt: 1,
                                      borderRadius: "4px",
                                      fontSize: "13px",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: getHighlightedSentences(
                                        caseItem["@search.highlights"]
                                          ?.case_description
                                      ),
                                    }}
                                  />
                                ) : (
                                  <Typography
                                    sx={{
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 3,
                                      overflow: "hidden",
                                      bgcolor: "#f5f5f5",
                                      color: "black",
                                      p: "8px 8px 0px 8px",
                                      mt: 1,
                                      borderRadius: "4px",
                                      fontSize: "13px",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        caseItem.captions?.[0].highlights ||
                                        //caseItem.judgements,
                                        getOriginalJudgement(
                                          caseItem.Case_Description
                                        ),
                                    }}
                                  />
                                )}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    mt: 1,
                                    gap: 1,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                      <VisibilityIcon
                                        sx={{ color: "#2196f3" }}
                                        fontSize="small"
                                      />
                                    </IconButton>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        mr: 0.5,
                                        color: "#303f9f",
                                        cursor: "pointer",
                                        "&:hover": {
                                          textDecoration: "underline",
                                        },
                                      }}
                                      onClick={() => {
                                        handlePreviewClick(caseItem);
                                      }}
                                    >
                                      Preview
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                      <InsightsIcon
                                        sx={{ color: "#4CAF50" }}
                                        fontSize="small"
                                      />
                                    </IconButton>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        mr: 0.5,
                                        color: "#303f9f",
                                        cursor: "pointer",
                                        "&:hover": {
                                          textDecoration: "underline",
                                        },
                                      }}
                                      onClick={async () => {
                                        let creditsToDeduct = 1;
                                        if (
                                          credits <= 0 ||
                                          credits <= creditsToDeduct
                                        ) {
                                          dispatch(
                                            showInsufficientCreditsModal()
                                          ); // Trigger modal if credits are insufficient
                                          return;
                                        }
                                        dispatch({
                                          type: "CASE_DETAIL_SUCCESS",
                                          payload: caseItem,
                                        });

                                        setOpenCaseInsights(true);
                                        const deductionSuccess = deductCredits(
                                          {
                                            userId,
                                            creditsToDeduct: 1,
                                            event:
                                              "Case Insights on Case Search",
                                          },
                                          dispatch
                                        );

                                        if (!deductionSuccess) {
                                          console.warn(
                                            "Failed to deduct credits. Ensure retry or user notification."
                                          );
                                        }
                                        //navigate('/app/case-detail');
                                      }}
                                    >
                                      Case Insights
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                      <FontAwesomeIcon
                                        icon={faRectangleList}
                                        color="#ff9800"
                                        fontSize="16px"
                                      />
                                    </IconButton>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        mr: 0.5,
                                        color: "#303f9f",
                                        cursor: "pointer",
                                        "&:hover": {
                                          textDecoration: "underline",
                                        },
                                      }}
                                      onClick={() => {
                                        handleSummaryClick(
                                          caseItem.Citation_Name
                                        );
                                      }}
                                    >
                                      Summary
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton
                                      sx={{
                                        p: 0,
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                    >
                                      {bookmarkedCaseIds.has(
                                        caseItem.doc_id
                                      ) ? (
                                        <TurnedInIcon
                                          onClick={() =>
                                            handleUnBookmark(caseItem.doc_id)
                                          }
                                          sx={{ color: "#d2042d" }}
                                          fontSize="small"
                                        />
                                      ) : (
                                        <TurnedInNotIcon
                                          onClick={() =>
                                            handleBookmarkClick(caseItem)
                                          }
                                          sx={{
                                            color: "#d2042d",
                                            "&:hover": {
                                              backgroundColor: "transparent",
                                            },
                                          }}
                                          fontSize="small"
                                        />
                                      )}
                                    </IconButton>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        ml: 0.5,
                                        color: "#303f9f",
                                        cursor: "pointer",
                                        "&:hover": {
                                          textDecoration: "underline",
                                        },
                                      }}
                                      onClick={() =>
                                        bookmarkedCaseIds.has(caseItem.doc_id)
                                          ? handleUnBookmark(caseItem.doc_id)
                                          : handleBookmarkClick(caseItem)
                                      }
                                    >
                                      Bookmark
                                    </Typography>
                                  </Box>
                                </Box>
                              </>
                            }
                          />
                        </Box>
                      </ListItem>
                    ))
                  : results?.map((caseItem) => (
                      <ListItem
                        key={caseItem.metadata.id}
                        sx={{
                          alignItems: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            p: 1,
                            width: "100%",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            transform: "translateY(-2px)",
                          }}
                        >
                          <ListItemText
                            primary={
                              <>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    color: "#303f9f",
                                    ml: 1,
                                    cursor: "pointer",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={() => {
                                    dispatch({
                                      type: "CASE_DETAIL_SUCCESS",
                                      payload: caseItem,
                                    });
                                    setOpenCaseInsights(true);
                                    // dispatch(
                                    //   findCaseSummary(
                                    //     caseItem.metadata.Citation_Name
                                    //   )
                                    // );
                                    // navigate('/app/case-detail');
                                  }}
                                >
                                  {formatCaseTitle(caseItem?.metadata.Title)}
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 1,
                                    alignItems: "center",
                                    color: "#021657",
                                    p: 1,
                                  }}
                                >
                                  <Typography variant="body2">
                                    &bull; {caseItem.metadata.Citation_Name}
                                  </Typography>
                                  <Typography variant="body2">
                                    &bull;{" "}
                                    {formatCourtNameWithoutTags(
                                      caseItem.metadata.Court
                                    )}
                                  </Typography>
                                </Box>
                              </>
                            }
                            secondary={
                              <>
                                <Typography
                                  sx={{
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 3,
                                    overflow: "hidden",
                                    bgcolor: "#f8f8fb",
                                    p: "8px 8px 0px 8px",
                                    mt: 1,
                                    borderRadius: "4px",
                                    fontSize: "13px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      caseItem.captions?.[0].highlights ||
                                      caseItem.metadata.Case_Details,
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    mt: 1,
                                    gap: 1,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                      <VisibilityIcon
                                        sx={{ color: "#2196f3" }}
                                        fontSize="small"
                                      />
                                    </IconButton>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        mr: 0.5,
                                        color: "#303f9f",
                                        cursor: "pointer",
                                        "&:hover": {
                                          textDecoration: "underline",
                                        },
                                      }}
                                      onClick={() => {
                                        handlePreviewClick(caseItem);
                                      }}
                                    >
                                      Preview
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                      <InsightsIcon
                                        sx={{ color: "#4CAF50" }}
                                        fontSize="small"
                                      />
                                    </IconButton>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        mr: 0.5,
                                        color: "#303f9f",
                                        cursor: "pointer",
                                        "&:hover": {
                                          textDecoration: "underline",
                                        },
                                      }}
                                      onClick={() => {
                                        dispatch({
                                          type: "CASE_DETAIL_SUCCESS",
                                          payload: caseItem,
                                        });
                                        setOpenCaseInsights(true);
                                        // dispatch(
                                        //   findCaseSummary(
                                        //     caseItem.metadata.Citation_Name
                                        //   )
                                        // );
                                        // navigate('/app/case-detail');
                                      }}
                                    >
                                      Case Insights
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                      <FontAwesomeIcon
                                        icon={faRectangleList}
                                        color="#ff9800"
                                        fontSize="16px"
                                      />
                                    </IconButton>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        mr: 0.5,
                                        color: "#303f9f",
                                        cursor: "pointer",
                                        "&:hover": {
                                          textDecoration: "underline",
                                        },
                                      }}
                                      onClick={() => {
                                        handleSummaryClick(
                                          caseItem.metadata.Citation_Name
                                        );
                                        setOpenSummary(true);
                                      }}
                                    >
                                      Summary
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton
                                      sx={{
                                        p: 0,
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                    >
                                      {bookmarkedCaseIds.has(
                                        caseItem.metadata.id
                                      ) ? (
                                        <>
                                          <TurnedInIcon
                                            onClick={() =>
                                              handleUnBookmark(
                                                caseItem.metadata.id
                                              )
                                            }
                                            sx={{ color: "#d2042d" }}
                                            fontSize="small"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              ml: 0.5,
                                              color: "#303f9f",
                                              cursor: "pointer",
                                              "&:hover": {
                                                textDecoration: "underline",
                                              },
                                            }}
                                            onClick={() =>
                                              handleUnBookmark(
                                                caseItem.metadata.id
                                              )
                                            }
                                          >
                                            Bookmark
                                          </Typography>
                                        </>
                                      ) : (
                                        <>
                                          <TurnedInNotIcon
                                            onClick={() =>
                                              handleBookmarkClick(caseItem)
                                            }
                                            sx={{
                                              color: "#d2042d",
                                              "&:hover": {
                                                backgroundColor: "transparent",
                                              },
                                            }}
                                            fontSize="small"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              ml: 0.5,
                                              color: "#303f9f",
                                              cursor: "pointer",
                                              "&:hover": {
                                                textDecoration: "underline",
                                              },
                                            }}
                                            onClick={() =>
                                              handleBookmarkClick(caseItem)
                                            }
                                          >
                                            Bookmark
                                          </Typography>
                                        </>
                                      )}
                                    </IconButton>
                                  </Box>
                                </Box>
                              </>
                            }
                          />
                        </Box>
                      </ListItem>
                    ))}
              </List>
              <CaseLawPreviewModal
                open={openPreview}
                onClose={() => {
                  //setSelectedCase(null);
                  setOpenPreview(false);
                }}
                title={
                  selectedCase
                    ? formatCaseTitle(
                        selectedCase.Title || selectedCase.metadata.Title
                      )
                    : ""
                }
                court={
                  selectedCase
                    ? selectedCase.Court || selectedCase.metadata.Court
                    : ""
                }
                citation={
                  selectedCase
                    ? selectedCase.Citation_Name ||
                      selectedCase.metadata.Citation_Name
                    : ""
                }
                judge={
                  selectedCase
                    ? selectedCase.judge_name_clean ||
                      selectedCase.metadata?.Judge_Name
                    : ""
                }
                caseDescriptionContent={
                  selectedCase
                    ? selectedCase.Case_Description_HTML ||
                      selectedCase.metadata.Case_Description_HTML
                    : ""
                }
              />
              <CaseSummaryDialog
                open={openSummary}
                onClose={handleCloseSummary}
              />
              <CaseDetailDialog
                open={openCaseInsights}
                onClose={handleCloseCaseInsights}
              />
              <Modal
                open={openBookmarkModal}
                onClose={handleCloseBookmarkModal}
                sx={{
                  "& .MuiModal-root": {
                    outline: "none",
                  },
                  "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <Box
                  sx={{
                    width: 400,
                    margin: "auto",
                    mt: "10%",
                    p: 2,
                    borderRadius: 2,
                    bgcolor: "white",
                    boxShadow: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#303f9f",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Bookmark Case
                    </Typography>
                    <IconButton
                      sx={{
                        padding: 0,
                        color: "gray",
                        "&:hover": { color: "#d32f2f" },
                      }}
                      onClick={handleCloseBookmarkModal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>

                  {/* Dropdown for selecting an existing group or creating a new group */}
                  <TextField
                    label="Select or Create Group"
                    variant="outlined"
                    select
                    fullWidth
                    size="small"
                    value={selectedGroup}
                    onChange={(e) => {
                      setSelectedGroup(e.target.value);
                      setIsCreatingNewGroup(e.target.value === "new");
                    }}
                    sx={{ mb: 2 }}
                    InputProps={{
                      sx: {
                        bgcolor: "white",
                        fontSize: "13px",
                        "& input:-webkit-autofill": {
                          backgroundColor: "white !important",
                          WebkitBoxShadow: "0 0 0 30px white inset !important",
                          WebkitTextFillColor: "black !important",
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "13px",
                      },
                    }}
                  >
                    <MenuItem
                      value="new"
                      sx={{
                        color: "#303f9f",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Create New Group
                    </MenuItem>
                    {groups
                      ?.sort((a, b) =>
                        a.groupName === "Bookmarks"
                          ? -1
                          : b.groupName === "Bookmarks"
                          ? 1
                          : 0
                      )
                      ?.map((group) => (
                        <MenuItem
                          key={group._id}
                          value={group._id}
                          selected={group.groupName === "Bookmarks"}
                          sx={{
                            fontSize: "13px",
                            maxWidth: "400px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {group.groupName}
                        </MenuItem>
                      ))}
                  </TextField>

                  {/* Input field for creating a new group, only visible if 'Create New Group' is selected */}
                  {isCreatingNewGroup && (
                    <TextField
                      fullWidth
                      size="small"
                      label="New Group Name"
                      variant="outlined"
                      value={bookmarkGroupName}
                      onChange={(e) => setBookmarkGroupName(e.target.value)}
                      sx={{ mb: 2 }}
                      InputProps={{
                        sx: {
                          bgcolor: "white",
                          fontSize: "13px",
                          "& input:-webkit-autofill": {
                            backgroundColor: "white !important",
                            WebkitBoxShadow:
                              "0 0 0 30px white inset !important",
                            WebkitTextFillColor: "black !important",
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "13px",
                        },
                      }}
                    />
                  )}

                  {/* Text input for adding a note */}
                  <TextField
                    label="Add a Note"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={caseNote}
                    onChange={(e) => handleNoteChange(e.target.value)}
                    multiline
                    rows={3}
                    error={Boolean(caseNoteError)}
                    helperText={caseNoteError}
                    InputProps={{
                      sx: {
                        bgcolor: "white",
                        fontSize: "13px",
                        "& input:-webkit-autofill": {
                          backgroundColor: "white !important",
                          WebkitBoxShadow: "0 0 0 30px white inset !important",
                          WebkitTextFillColor: "black !important",
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: "13px",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: "8px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleCloseBookmarkModal}
                      color="error"
                      sx={{
                        borderRadius: "8px",
                        backgroundColor: "#d32f2f",
                        textTransform: "none",
                        fontSize: "14px",
                        "&:hover": { backgroundColor: "#b71c1c" },
                        transition: "background-color 0.3s ease-in-out",
                      }}
                    >
                      Cancel
                    </Button>
                    {/* Conditional rendering for buttons based on selection */}
                    {isCreatingNewGroup ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleCreateBookmarkGroup();
                          setOpenBookmarkModal(false);
                        }}
                        disabled={!bookmarkGroupName}
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "#003D78",
                          textTransform: "none",
                          fontSize: "14px",
                          "&:hover": { backgroundColor: "primary.main" },
                          transition: "background-color 0.3s ease-in-out",
                        }}
                      >
                        Add
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!selectedGroup || caseNoteError}
                        onClick={() => {
                          handleAddCaseToGroup();
                          setOpenBookmarkModal(false);
                        }}
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "#003D78",
                          textTransform: "none",
                          fontSize: "14px",
                          "&:hover": { backgroundColor: "primary.main" },
                          transition: "background-color 0.3s ease-in-out",
                        }}
                      >
                        Add
                      </Button>
                    )}
                  </Box>
                </Box>
              </Modal>

              {results?.["search_results"] && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Pagination
                    count={Math.ceil(totalResults / resultsCount)}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{
                      m: "5px 0",
                      "& .MuiPaginationItem-root": {
                        fontSize: "13px",
                        minWidth: "24px",
                        height: "24px",
                        "&:hover": {
                          color: "#fff",
                          backgroundColor: "#303f9f",
                        },
                        "&.Mui-selected": {
                          color: "#fff",
                          backgroundColor: "#213165",
                          "&:hover": {
                            backgroundColor: "#303f9f",
                          },
                        },
                      },
                    }}
                  />
                </Box>
              )}
              {snackbarMessage && (
                <Snackbar
                  open={!!snackbarMessage}
                  autoHideDuration={6000}
                  onClose={() => setSnackbarMessage(null)}
                >
                  <Alert
                    onClose={() => setSnackbarMessage(null)}
                    severity={snackbarMessage.type}
                    sx={{ width: "100%" }}
                  >
                    {snackbarMessage.text}
                  </Alert>
                </Snackbar>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default SearchResults;
