import React, { useState, useRef } from "react";
import {
  Box,
  Stack,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteDialog from "../UI/DeleteDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbTack,
  faThumbTackSlash,
} from "@fortawesome/free-solid-svg-icons";

const LegalDocumentSidebar = () => {
  const [selectedChat, setSelectedChat] = useState({ category: "", index: -1 });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newTitle, setNewTitle] = useState("");
  const [chatTitlesList, setChatTitlesList] = useState([
    { chat_title: "Case Analysis", isPinned: true },
    { chat_title: "Legal Opinion on case", isPinned: true },
    { chat_title: "Insights on Document", isPinned: false },
    { chat_title: "Document Breakdown", isPinned: false },
    { chat_title: "Legal Summary", isPinned: false },
  ]);
  const [isEditing, setIsEditing] = useState({ category: "", index: -1 });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedChatTitle, setSelectedChatTitle] = useState(null);

  const pinnedChats = chatTitlesList.filter((chat) => chat.isPinned);
  const recentChats = chatTitlesList.filter((chat) => !chat.isPinned);

  const open = Boolean(anchorEl);

  const inputRef = useRef(null);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleInputChange = (e) => {
    const cursorPosition = inputRef.current.selectionStart;
    setNewTitle(e.target.value);

    // Set the cursor back to where it was
    setTimeout(() => {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const handleSaveEdit = (category, index) => {
    // Create a copy of the chatTitlesList to modify the chat title
    const updatedChats = [...chatTitlesList];

    // Find the correct chat to update based on category and index
    if (category === "pinned") {
      const pinnedIndex = chatTitlesList.findIndex(
        (chat) => chat.chat_title === pinnedChats[index].chat_title
      );
      updatedChats[pinnedIndex].chat_title = newTitle;
    } else if (category === "recent") {
      const recentIndex = chatTitlesList.findIndex(
        (chat) => chat.chat_title === recentChats[index].chat_title
      );
      updatedChats[recentIndex].chat_title = newTitle;
    }

    // Update the main chat array (chatTitlesList) with the modified chat title
    setChatTitlesList(updatedChats);

    // Reset editing state after saving
    setIsEditing({ category: "", index: -1 });
    setNewTitle("");
  };

  const handleCancelEdit = (index) => {
    setIsEditing({ category: "", index: -1 });
    setNewTitle("");
  };

  const handleListItemClick = (category, index) => {
    setIsEditing({ category: "", index: -1 });
    setSelectedChat({ category, index });
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditTitle = (event, category, index) => {
    event.stopPropagation();
    setIsEditing({ category, index });
    setNewTitle(chatTitlesList[index].chat_title);
  };

  const handleConfirmDelete = (event, category, index) => {
    event.preventDefault();
    event.stopPropagation();

    setChatTitlesList((prevState) => {
      if (!Array.isArray(prevState)) return prevState;

      // Separate pinned and recent chats
      const pinnedChats = prevState.filter((chat) => chat.isPinned);
      const recentChats = prevState.filter((chat) => !chat.isPinned);

      let updatedPinnedChats = [...pinnedChats];
      let updatedRecentChats = [...recentChats];

      if (category === "pinned") {
        // Remove the chat from the pinned list
        updatedPinnedChats = pinnedChats.filter((_, i) => i !== index);
      } else {
        // Remove the chat from the recent list
        updatedRecentChats = recentChats.filter((_, i) => i !== index);
      }

      // Merge the updated pinned and recent chats
      return [...updatedPinnedChats, ...updatedRecentChats];
    });

    // Handle resetting the selected chat if it's the one being deleted
    setSelectedChat((prevSelected) => {
      if (
        category === "pinned" &&
        pinnedChats[prevSelected] &&
        prevSelected === index
      ) {
        return -1;
      } else if (
        category === "recent" &&
        recentChats[prevSelected] &&
        prevSelected === index
      ) {
        return -1;
      }
      return prevSelected;
    });

    // Close the delete dialog
    setOpenDeleteDialog(false);
  };

  const handleDeleteChat = (event, category, index) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);

    // Determine the correct chat list (pinned or recent)
    const findChat =
      category === "pinned" ? pinnedChats[index] : recentChats[index];

    setSelectedChatTitle({
      title: findChat.chat_title,
      index,
      isPinned: findChat.isPinned,
    });

    // Open delete dialog
    setOpenDeleteDialog(true);
  };

  // Pin Logic
  const handlePinChat = (category, index) => {
    const updatedChats = [...chatTitlesList];

    if (category === "recent") {
      // Find the chat in the chatTitlesList and update isPinned to true
      const recentIndex = chatTitlesList.findIndex(
        (chat) => chat.chat_title === recentChats[index].chat_title
      );
      updatedChats[recentIndex].isPinned = true;
    }

    setChatTitlesList(updatedChats);
    setSelectedChatTitle({ category: "", index: -1 });
  };

  const handleUnpinChat = (category, index) => {
    const updatedChats = [...chatTitlesList];

    if (category === "pinned") {
      // Find the chat in the chatTitlesList and update isPinned to false
      const pinnedIndex = chatTitlesList.findIndex(
        (chat) => chat.chat_title === pinnedChats[index].chat_title
      );
      updatedChats[pinnedIndex].isPinned = false;
    }

    setChatTitlesList(updatedChats);
    setSelectedChatTitle({ category: "", index: -1 });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        flex: 1,
        maxWidth: "270px",
        height: "calc(100vh - 50px)",
        p: "12px 0px 0 12px",
        bgcolor: "#F5F5F5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "#fff",
          p: 1,
          borderRadius: "8px",
          flexGrow: 1,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#003D78",
            display: "flex",
            justifyContent: "center",
            marginBottom: "8px",
            mr: 0.5,
            marginTop: "8px",
            textTransform: "none",
            "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            transition: "background-color 0.3s ease-in-out",
          }}
          //onClick={handleNewChatClick}
        >
          New Chat
        </Button>
        {
          // isLoading ? (
          //   <Box
          //     sx={{
          //       margin: 'auto',
          //       display: 'flex',
          //       justifyContent: 'center',
          //       alignItems: 'center',
          //     }}
          //   >
          //     <CircularProgress size={22} />
          //   </Box>
          // ) :
          chatTitlesList.length > 0 ? (
            <List
              sx={{
                overflow: "auto",
                "::-webkit-scrollbar": {
                  width: "5px",
                },
                "::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#101936",
                  borderRadius: "6px",
                },
              }}
            >
              {/* Pinned Section */}
              {pinnedChats.length > 0 && (
                <>
                  <Typography
                    variant="overline"
                    sx={{
                      display: "flex",
                      gap: 0.5,
                      alignItems: "center",
                      padding: "4px 16px 0px 12px",
                      color: "#3C50B1",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Pinned
                    <FontAwesomeIcon icon={faThumbTack} fontSize="12px" />
                  </Typography>
                  {pinnedChats.map((title, index) => (
                    <React.Fragment key={index}>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            mr: 1,
                            borderRadius: "8px",
                            height: "38px",
                            padding: "8px 12px",
                            "&.Mui-selected": {
                              backgroundColor: "#ECECEC",
                              "&:hover": {
                                backgroundColor: "#ECECEC",
                              },
                            },
                            "&:hover": {
                              backgroundColor: "#ECECEC",
                            },
                          }}
                          selected={
                            selectedChat.category === "pinned" &&
                            selectedChat.index === index
                          }
                          onClick={() =>
                            handleListItemClick("pinned", index, title.chat_id)
                          }
                        >
                          {isEditing.category === "pinned" &&
                          isEditing.index === index ? (
                            <Stack direction="row">
                              <input
                                ref={inputRef}
                                style={{
                                  width: "100%",
                                  marginLeft: "0",
                                  fontSize: "13px",
                                }}
                                type="text"
                                autoFocus
                                value={newTitle}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={(e) => e.stopPropagation()}
                                onChange={handleInputChange}
                              />
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSaveEdit("pinned", index);
                                  setAnchorEl(null);
                                }}
                              >
                                <DoneIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCancelEdit();
                                  setAnchorEl(null);
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              width="100%"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  fontSize: "13px",
                                }}
                              >
                                {title.chat_title}
                              </Typography>

                              {selectedChat.category === "pinned" &&
                                selectedChat.index === index && (
                                  <>
                                    <IconButton
                                      size="small"
                                      id="basic-button"
                                      aria-controls={
                                        open ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={open ? "true" : undefined}
                                      onClick={handleMenuOpen}
                                    >
                                      <MoreHorizIcon />
                                    </IconButton>
                                    <Menu
                                      anchorEl={anchorEl}
                                      id="basic-menu"
                                      open={open}
                                      onClose={handleMenuClose}
                                    >
                                      <MenuItem
                                        sx={{ fontSize: "14px" }}
                                        onClick={() =>
                                          handleUnpinChat("pinned", index)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faThumbTackSlash}
                                          fontSize="14px"
                                          style={{ marginRight: "8px" }}
                                        />
                                        Unpin
                                      </MenuItem>
                                      <MenuItem
                                        sx={{ fontSize: "14px" }}
                                        onClick={(e) =>
                                          handleEditTitle(e, "pinned", index)
                                        }
                                      >
                                        <EditIcon
                                          sx={{ fontSize: 16, mr: 1 }}
                                        />
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        sx={{
                                          fontSize: "14px",
                                          color: "#d32f2f",
                                        }}
                                        onClick={(e) =>
                                          handleDeleteChat(e, "pinned", index)
                                        }
                                      >
                                        <DeleteIcon
                                          color="error"
                                          sx={{ fontSize: 16, mr: 1 }}
                                        />
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  </>
                                )}
                            </Stack>
                          )}
                        </ListItemButton>
                      </ListItem>
                      {/* <Divider sx={{ width: '90%', margin: '6px auto' }} /> */}
                    </React.Fragment>
                  ))}
                </>
              )}

              {/* Recent Section */}
              {recentChats.length > 0 && (
                <>
                  <Typography
                    variant="overline"
                    sx={{
                      display: "flex",
                      padding: "4px 16px 0px 12px",
                      color: "#3C50B1",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Recent
                  </Typography>
                  {recentChats.map((title, index) => (
                    <React.Fragment key={index}>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            mr: 1,
                            borderRadius: "8px",
                            height: "38px",
                            padding: "8px 12px",
                            "&.Mui-selected": {
                              backgroundColor: "#ECECEC",
                              "&:hover": {
                                backgroundColor: "#ECECEC",
                              },
                            },
                            "&:hover": {
                              backgroundColor: "#ECECEC",
                            },
                          }}
                          selected={
                            selectedChat.category === "recent" &&
                            selectedChat.index === index
                          }
                          onClick={() =>
                            handleListItemClick("recent", index, title.chat_id)
                          }
                        >
                          {isEditing.category === "recent" &&
                          isEditing.index === index ? (
                            <Stack direction="row">
                              <input
                                ref={inputRef}
                                style={{
                                  width: "100%",
                                  marginLeft: "0",
                                  fontSize: "13px",
                                }}
                                type="text"
                                autoFocus
                                value={newTitle}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={(e) => e.stopPropagation()}
                                onChange={handleInputChange}
                              />
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSaveEdit("recent", index);
                                  setAnchorEl(null);
                                }}
                              >
                                <DoneIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCancelEdit();
                                  setAnchorEl(null);
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              width="100%"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  fontSize: "13px",
                                }}
                              >
                                {title.chat_title}
                              </Typography>
                              {selectedChat.category === "recent" &&
                                selectedChat.index === index && (
                                  <>
                                    <IconButton
                                      size="small"
                                      id="basic-button"
                                      aria-controls={
                                        open ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={open ? "true" : undefined}
                                      onClick={handleMenuOpen}
                                    >
                                      <MoreHorizIcon />
                                    </IconButton>
                                    <Menu
                                      anchorEl={anchorEl}
                                      id="basic-menu"
                                      open={open}
                                      onClose={handleMenuClose}
                                    >
                                      <MenuItem
                                        sx={{ fontSize: "14px" }}
                                        onClick={() =>
                                          handlePinChat("recent", index)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faThumbTack}
                                          width="16px"
                                          height="16px"
                                          style={{ marginRight: "8px" }}
                                        />
                                        Pin
                                      </MenuItem>
                                      <MenuItem
                                        sx={{ fontSize: "14px" }}
                                        onClick={(e) =>
                                          handleEditTitle(e, "recent", index)
                                        }
                                      >
                                        <EditIcon
                                          sx={{ fontSize: 16, mr: 1 }}
                                        />
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        sx={{
                                          fontSize: "14px",
                                          color: "#d32f2f",
                                        }}
                                        onClick={(e) =>
                                          handleDeleteChat(e, "recent", index)
                                        }
                                      >
                                        <DeleteIcon
                                          color="error"
                                          sx={{ fontSize: 16, mr: 1 }}
                                        />
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  </>
                                )}
                            </Stack>
                          )}
                        </ListItemButton>
                      </ListItem>
                      {/* <Divider sx={{ width: '90%', margin: '6px auto' }} /> */}
                    </React.Fragment>
                  ))}
                </>
              )}
            </List>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                m: "auto",
              }}
            >
              <Typography
                variant="subtitle2"
                color="textSecondary"
                textAlign="center"
              >
                You have no previous documents.
                <br />
                Start a new conversation! 🚀
              </Typography>
            </Box>
          )
        }
      </Box>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDelete={handleConfirmDelete}
        chatTitle={selectedChatTitle}
      />
    </Box>
  );
};

export default LegalDocumentSidebar;
