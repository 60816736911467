import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  List,
  ListItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const privacyPolicySections = [
  {
    heading: "Introduction",
    content:
      "LawGPT is committed to protecting your privacy and ensuring the security of your personal data. This Privacy Policy outlines how we collect, use, store, and share information when you access or use our website and services. By using LawGPT, you agree to the terms of this Privacy Policy.",
  },
  {
    heading: "Data Collection ",
    content:
      "We collect personal and usage data to provide and improve our services. This includes: ",
    bulletPoints: [
      "<strong>Personal Information:</strong> Name, email address, phone number, and billing details provided during account registration or subscription",
      "<strong>Usage Information:</strong> Log data such as IP addresses, browser type, device information, and activity on the platform",
      "<strong>Cookies:</strong> Small data files stored on your device to enhance user experience and analyze site traffic.",
    ],
  },
  {
    heading: "Use of Collected Data",
    content: "We use the collected data for the following purposes:",
    bulletPoints: [
      "To provide, maintain, and improve our AI-based legal services.",
      "To personalize user experience and tailor recommendations.",
      "To communicate with users regarding updates, subscriptions, and customer support.",
      "To analyze usage patterns and ensure the security of the platform.",
    ],
  },
  {
    heading: "Data Sharing ",
    content:
      "LawGPT respects user confidentiality and only shares data in the following cases:",
    bulletPoints: [
      "<strong>With Service Providers:</strong> Third-party vendors assisting in payment processing, data analytics, or infrastructure support.",
      "<strong>Legal Obligations:</strong> To comply with applicable laws, regulations, or legal processes.",
      "<strong>Business Transfers:</strong> In case of mergers, acquisitions, or asset sales, user data may be transferred with proper notification",
    ],
  },
  {
    heading: "Data Security",
    content:
      "We implement advanced security measures to protect your information, including encryption, firewalls, and access controls. While we strive for maximum security, no method of transmission or storage is entirely secure, and users provide data at their own risk.",
  },
  {
    heading: "User Rights",
    content: "Users have the following rights regarding their personal data:",
    bulletPoints: [
      "<strong>Access and Correction:</strong> Request access to and correction of your personal data.",
      "<strong>Data Portability:</strong> Request a copy of your data in a standard format.",
      "<strong>Deletion:</strong> Request deletion of your data, subject to legal obligations.",
    ],
    postContent: "To exercise these rights, contact us at info@lawgpt.pk. ",
  },
  {
    heading: "Cookies Policy",
    content:
      "Cookies enhance user experience by saving preferences and enabling faster access. Users can manage cookies through browser settings, but disabling them may limit the platform’s functionality.",
  },
  {
    heading: "Third-Party Links",
    content:
      "Our platform may contain links to third-party websites. LawGPT is not responsible for the privacy practices of these external sites and encourages users to review their policies. ",
  },
  {
    heading: "Children’s Privacy",
    content:
      "LawGPT is not intended for users under the age of 18. We do not knowingly collect personal data from children. If we become aware of such data, it will be promptly deleted.",
  },
  {
    heading: "Changes to the Privacy Policy",
    content:
      "LawGPT reserves the right to update this Privacy Policy at any time. Users will be notified of significant changes via email or through the platform. Continued use after changes implies acceptance. ",
  },
  {
    heading: "Contact Information",
    content:
      "For questions or concerns about this Privacy Policy, please contact:",
    contactDetails: [
      "<strong>Info Email:</strong> info@lawgpt.pk",
      "<strong>Support Email:</strong> support@lawgpt.pk",
      "<strong>Sales Email:</strong> sale@lawgpt.pk",
      "<strong>Phone:</strong> +92 301 5815112",
    ],
  },
];

const PrivacyPolicyDialog = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          padding: "24px",
          borderRadius: "8px",
        },
      }}
    >
      {/* Dialog Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle
          sx={{
            textAlign: "center",
            flex: 1,
            fontSize: "18px",
            fontWeight: "bold",
            color: "#00509e",
          }}
        >
          Privacy Policy
        </DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", right: 16, color: "#00509e" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Dialog Content */}
      <DialogContent
        sx={{
          overflowY: "auto",
          scrollBehavior: "smooth",
          "::-webkit-scrollbar": {
            width: "4px",
          },
          "::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: "6px",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#00509e",
            borderRadius: "6px",
          },
        }}
      >
        {privacyPolicySections.map((section, index) => (
          <Box sx={{ marginBottom: "16px", textAlign: "left" }}>
            <Typography
              sx={{
                fontSize: "16px",
                marginBottom: "8px",
                //color: '#00509e',
              }}
            >
              {`${index + 1}. ${section.heading}`}
            </Typography>
            <Typography
              sx={{
                color: "#555",
                fontSize: "13px",
                //lineHeight: '1.6',
                textAlign: "justify",
                //textIndent: '8px',
              }}
            >
              {section.content}
            </Typography>
            {section.bulletPoints && (
              <List
                sx={{
                  paddingLeft: "16px", // Indent bullets slightly
                  listStyleType: "disc", // Set bullet style
                  "& .MuiListItem-root": {
                    display: "list-item", // Render bullets
                  },
                }}
              >
                {section.bulletPoints?.map((bullet, bulletIndex) => (
                  <ListItem
                    key={index}
                    sx={{
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontSize: "13px",
                      color: "#555",
                      padding: "6px 0 6px 0", // Remove default padding for list items
                      //marginBottom: "8px",
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: bullet }}></span>
                  </ListItem>
                ))}
              </List>
            )}

            <Typography
              sx={{
                color: "#555",
                fontSize: "13px",
                //lineHeight: '1.6',
                textAlign: "justify",
                //textIndent: '8px',
              }}
            >
              {section.postContent}
            </Typography>
            {section.contactDetails?.map((detail, index) => (
              <Typography
                sx={{
                  color: "#555",
                  marginTop: index === 0 ? "8px" : "0px",
                  fontSize: "13px",
                  //lineHeight: '1.6',
                  textAlign: "justify",
                  //textIndent: '8px',
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: detail }} />
              </Typography>
            ))}
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default PrivacyPolicyDialog;
