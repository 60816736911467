import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Stack,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteDialog from "../UI/DeleteDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbTack,
  faThumbTackSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  deletePetition,
  editPetitionTitle,
  getPetitionTitles,
  togglePinPetition,
} from "../../actions/petitionActions";
import { showInsufficientCreditsModal } from "../../actions/creditActions";
import { deductCredits } from "../../utils/HelperFunction/deductCredits";

const Sidebar = ({ setIsHistoryClicked, setPetitionToEdit }) => {
  const dispatch = useDispatch();
  const credits = useSelector((state) => state.credit.credits); // Get credits from Redux store
  const userId = useSelector((state) => state.credit.userId);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const petitionTitles = useSelector((state) => state.petitionTitles);
  const { loading, titles } = petitionTitles;

  const [selectedChat, setSelectedChat] = useState({ chatId: "", title: "" });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newTitle, setNewTitle] = useState("");

  const [isEditing, setIsEditing] = useState({ chatId: "" });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const pinnedChats = titles?.filter(
    (title) => title?.isPinned && title?.Title !== null
  );
  const recentChats = titles?.filter(
    (title) => !title?.isPinned && title.isSaved && title?.Title !== null
  );
  const drafts = titles?.filter(
    (title) => !title?.isSaved && title?.Title !== null
  );

  const open = Boolean(anchorEl);

  const inputRef = useRef(null);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleNewChatClick = () => {
    setSelectedChat({ chatId: "", title: "" });
    setIsHistoryClicked(false);
    setPetitionToEdit({});
    dispatch({ type: "NEW_PETITION_REQUEST" });
  };

  const handleInputChange = (e) => {
    const cursorPosition = inputRef.current.selectionStart;
    setNewTitle(e.target.value);

    // Set the cursor back to where it was
    setTimeout(() => {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const handleSaveEdit = (chatId) => {
    if (!newTitle) return;
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    dispatch(editPetitionTitle(userInfo?._id, chatId, newTitle));
    // Reset editing state after saving
    setIsEditing({ chatId: "" });
    setNewTitle("");
    // Deduct credits
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Petition title edit on petition drafting page.",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleCancelEdit = (index) => {
    setIsEditing({ chatId: "" });
    setNewTitle("");
  };

  const handleListItemClick = (petition) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    setIsEditing({ chatId: "" });
    setSelectedChat({ chatId: petition.chat_id, title: petition.Title });
    setAnchorEl(null);
    dispatch({ type: "SET_SELECTED_PETITION", payload: petition });
    setIsHistoryClicked(false);
    setPetitionToEdit({});
    // Deduct credits
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Petition preview on petition drafting page.",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditTitle = (event, chatId) => {
    event.stopPropagation();
    setIsEditing({ chatId });
    const findChat = titles?.find((title) => title.chat_id === chatId);
    setNewTitle(findChat.Title);
  };

  const handleConfirmDelete = (event, chatId) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    dispatch(deletePetition(userInfo?._id, chatId));
    setSelectedChat({ chatId: "", title: "" });
    dispatch({ type: "SELECTED_PETITION_RESET" });
    setOpenDeleteDialog(false);
    // Deduct credits
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Delete petition on petition drafting page.",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleDeleteChat = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
    // Open delete dialog
    setOpenDeleteDialog(true);
  };

  // Pin Logic
  const handlePinChat = (chatId, isPinned) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    dispatch(togglePinPetition(userInfo._id, chatId, isPinned));
    // Deduct credits
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Pin petition on petition drafting page.",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  const handleUnpinChat = (chatId, isPinned) => {
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    dispatch(togglePinPetition(userInfo._id, chatId, isPinned));
    // Deduct credits
    const deductionSuccess = deductCredits(
      {
        userId,
        creditsToDeduct: 1,
        event: "Un pin petition on petition drafting page.",
      },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
  };

  useEffect(() => {
    dispatch(getPetitionTitles(userInfo?._id));
  }, [userInfo, dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        width: "18%",
        height: "calc(100vh - 50px)",
        p: "12px 0px 0 12px",
        bgcolor: "#F5F5F5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          boxSizing: "border-box",
          flexDirection: "column",
          bgcolor: "#fff",
          p: 1,
          borderRadius: "8px",
          flexGrow: 1,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#003D78",
            display: "flex",
            justifyContent: "center",
            marginBottom: "8px",
            mr: 0.5,
            marginTop: "8px",
            textTransform: "none",
            "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            transition: "background-color 0.3s ease-in-out",
          }}
          onClick={handleNewChatClick}
        >
          New Petition
        </Button>
        <Box
          sx={{ textAlign: "right", paddingTop: "8px" }}
          onClick={() => {
            setIsHistoryClicked(true);
            setSelectedChat({ chatId: "", title: "" });
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              mr: 0.5,
              color: "#3C50B1",
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          >
            View History
          </Typography>
        </Box>
        {loading ? (
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={20} />
          </Box>
        ) : titles?.length > 0 ? (
          <List
            sx={{
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            <>
              {pinnedChats.length > 0 && (
                <>
                  <Typography
                    variant="overline"
                    sx={{
                      display: "flex",
                      gap: 0.5,
                      alignItems: "center",
                      padding: "4px 16px 0px 12px",
                      color: "#3C50B1",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Pinned
                    <FontAwesomeIcon icon={faThumbTack} fontSize="12px" />
                  </Typography>
                  {pinnedChats.map((title, index) => (
                    <React.Fragment key={index}>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            mr: 1,
                            borderRadius: "8px",
                            height: "38px",
                            padding: "8px 12px",
                            "&.Mui-selected": {
                              backgroundColor: "#ECECEC",
                              "&:hover": {
                                backgroundColor: "#ECECEC",
                              },
                            },
                            "&:hover": {
                              backgroundColor: "#ECECEC",
                            },
                          }}
                          selected={selectedChat.chatId === title.chat_id}
                          onClick={() => handleListItemClick(title)}
                        >
                          {isEditing.chatId === title.chat_id ? (
                            <Stack direction="row">
                              <input
                                ref={inputRef}
                                style={{
                                  width: "100%",
                                  marginLeft: "0",
                                  fontSize: "13px",
                                }}
                                type="text"
                                autoFocus
                                value={newTitle}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={(e) => e.stopPropagation()}
                                onChange={handleInputChange}
                              />
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSaveEdit(title.chat_id);
                                  setAnchorEl(null);
                                }}
                              >
                                <DoneIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCancelEdit();
                                  setAnchorEl(null);
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              width="100%"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  fontSize: "13px",
                                }}
                              >
                                {title.Title}
                              </Typography>
                              {selectedChat.chatId === title.chat_id && (
                                <>
                                  <IconButton
                                    size="small"
                                    id="basic-button"
                                    aria-controls={
                                      open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleMenuOpen}
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    id="basic-menu"
                                    open={open}
                                    onClose={handleMenuClose}
                                  >
                                    <MenuItem
                                      sx={{ fontSize: "14px" }}
                                      onClick={() =>
                                        handleUnpinChat(title.chat_id, true)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faThumbTackSlash}
                                        fontSize="14px"
                                        style={{ marginRight: "8px" }}
                                      />
                                      Unpin
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ fontSize: "14px" }}
                                      onClick={(e) =>
                                        handleEditTitle(e, title.chat_id)
                                      }
                                    >
                                      <EditIcon sx={{ fontSize: 16, mr: 1 }} />
                                      Edit
                                    </MenuItem>
                                    <MenuItem
                                      sx={{
                                        fontSize: "14px",
                                        color: "#d32f2f",
                                      }}
                                      onClick={(e) => handleDeleteChat(e)}
                                    >
                                      <DeleteIcon
                                        color="error"
                                        sx={{ fontSize: 16, mr: 1 }}
                                      />
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                            </Stack>
                          )}
                        </ListItemButton>
                      </ListItem>
                      {/* <Divider sx={{ width: '90%', margin: '6px auto' }} /> */}
                    </React.Fragment>
                  ))}
                </>
              )}
              {recentChats?.length > 0 && (
                <>
                  <Typography
                    variant="overline"
                    sx={{
                      display: "flex",
                      padding: "4px 16px 0px 12px",
                      color: "#3C50B1",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Recent
                  </Typography>
                  {recentChats.map((title, index) => (
                    <React.Fragment key={index}>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            mr: 1,
                            borderRadius: "8px",
                            height: "38px",
                            padding: "8px 12px",
                            "&.Mui-selected": {
                              backgroundColor: "#ECECEC",
                              "&:hover": {
                                backgroundColor: "#ECECEC",
                              },
                            },
                            "&:hover": {
                              backgroundColor: "#ECECEC",
                            },
                          }}
                          selected={selectedChat.chatId === title.chat_id}
                          onClick={() => handleListItemClick(title)}
                        >
                          {isEditing.chatId === title.chat_id ? (
                            <Stack direction="row">
                              <input
                                ref={inputRef}
                                style={{
                                  width: "100%",
                                  marginLeft: "0",
                                  fontSize: "13px",
                                }}
                                type="text"
                                autoFocus
                                value={newTitle}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={(e) => e.stopPropagation()}
                                onChange={handleInputChange}
                              />
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSaveEdit(title.chat_id);
                                  setAnchorEl(null);
                                }}
                              >
                                <DoneIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCancelEdit();
                                  setAnchorEl(null);
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              width="100%"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  fontSize: "13px",
                                }}
                              >
                                {title.Title}
                              </Typography>
                              {selectedChat.chatId === title.chat_id && (
                                <>
                                  <IconButton
                                    size="small"
                                    id="basic-button"
                                    aria-controls={
                                      open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleMenuOpen}
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    id="basic-menu"
                                    open={open}
                                    onClose={handleMenuClose}
                                  >
                                    <MenuItem
                                      sx={{ fontSize: "14px" }}
                                      onClick={() =>
                                        handlePinChat(title.chat_id, false)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faThumbTack}
                                        width="16px"
                                        height="16px"
                                        style={{ marginRight: "8px" }}
                                      />
                                      Pin
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ fontSize: "14px" }}
                                      onClick={(e) =>
                                        handleEditTitle(e, title.chat_id)
                                      }
                                    >
                                      <EditIcon sx={{ fontSize: 16, mr: 1 }} />
                                      Edit
                                    </MenuItem>
                                    <MenuItem
                                      sx={{
                                        fontSize: "14px",
                                        color: "#d32f2f",
                                      }}
                                      onClick={(e) => handleDeleteChat(e)}
                                    >
                                      <DeleteIcon
                                        color="error"
                                        sx={{ fontSize: 16, mr: 1 }}
                                      />
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                            </Stack>
                          )}
                        </ListItemButton>
                      </ListItem>
                      {/* <Divider sx={{ width: '90%', margin: '6px auto' }} /> */}
                    </React.Fragment>
                  ))}
                </>
              )}
              {drafts.length > 0 && (
                <>
                  <Typography
                    variant="overline"
                    sx={{
                      display: "flex",
                      gap: 0.5,
                      alignItems: "center",
                      padding: "4px 16px 0px 12px",
                      color: "#3C50B1",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Drafts
                  </Typography>
                  {drafts.map((title, index) => (
                    <React.Fragment key={index}>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            mr: 1,
                            borderRadius: "8px",
                            height: "38px",
                            padding: "8px 12px",
                            "&.Mui-selected": {
                              backgroundColor: "#ECECEC",
                              "&:hover": {
                                backgroundColor: "#ECECEC",
                              },
                            },
                            "&:hover": {
                              backgroundColor: "#ECECEC",
                            },
                          }}
                          selected={selectedChat.chatId === title.chat_id}
                          onClick={() => handleListItemClick(title)}
                        >
                          {isEditing.chatId === title.chat_id ? (
                            <Stack direction="row">
                              <input
                                ref={inputRef}
                                style={{
                                  width: "100%",
                                  marginLeft: "0",
                                  fontSize: "13px",
                                }}
                                type="text"
                                autoFocus
                                value={newTitle}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={(e) => e.stopPropagation()}
                                onChange={handleInputChange}
                              />
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSaveEdit(title.chat_id);
                                  setAnchorEl(null);
                                }}
                              >
                                <DoneIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCancelEdit();
                                  setAnchorEl(null);
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              width="100%"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  fontSize: "13px",
                                }}
                              >
                                {title.Title}
                              </Typography>
                              {selectedChat.chatId === title.chat_id && (
                                <>
                                  <IconButton
                                    size="small"
                                    id="basic-button"
                                    aria-controls={
                                      open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleMenuOpen}
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    id="basic-menu"
                                    open={open}
                                    onClose={handleMenuClose}
                                  >
                                    <MenuItem
                                      sx={{ fontSize: "14px" }}
                                      onClick={(e) =>
                                        handleEditTitle(e, title.chat_id)
                                      }
                                    >
                                      <EditIcon sx={{ fontSize: 16, mr: 1 }} />
                                      Edit
                                    </MenuItem>
                                    <MenuItem
                                      sx={{
                                        fontSize: "14px",
                                        color: "#d32f2f",
                                      }}
                                      onClick={(e) => handleDeleteChat(e)}
                                    >
                                      <DeleteIcon
                                        color="error"
                                        sx={{ fontSize: 16, mr: 1 }}
                                      />
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                            </Stack>
                          )}
                        </ListItemButton>
                      </ListItem>
                    </React.Fragment>
                  ))}
                </>
              )}
            </>
          </List>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              m: "auto",
            }}
          >
            <Typography
              variant="subtitle2"
              color="textSecondary"
              textAlign="center"
            >
              You have no previous documents.
              <br />
              Start a new conversation! 🚀
            </Typography>
          </Box>
        )}
      </Box>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDelete={handleConfirmDelete}
        selectedChat={selectedChat}
      />
    </Box>
  );
};

export default Sidebar;
