import React from "react";
import { Box } from "@mui/material";
import CaseDetail from "./CaseDetail";
import CaseDetailRightSidebar from "./layout/CaseDetailRightSidebar";

const CaseDetailDialog = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        width: "calc(100% - 45px)",
        height: "calc(100vh - 50px)",
        top: "50px",
        left: "45px",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1300,
      }}
      onClick={onClose} // Close dialog when clicking on the backdrop
    >
      <Box
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the content
        sx={{
          position: "relative",
          width: "calc(100vw - 45px)",
          height: "calc(100vh - 50px)",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          overflowY: "clip",
        }}
      >
        <CaseDetail closeOnBack={onClose} />
        <CaseDetailRightSidebar />
      </Box>
    </Box>
  );
};

export default CaseDetailDialog;
