import React, { useState } from "react";
import { Box } from "@mui/material";
import StatuteDetailSidebar from "./layout/StatuteDetailSidebar";
import StatuteDetail from "./StatuteDetail";

const StatuteDetailDialog = ({ open, onClose }) => {
  const [selectedStatuteSection, setSelectedStatuteSection] =
    useState("PREAMBLE. Preamble");

  const handleStatuteSectionClick = (section) => {
    setSelectedStatuteSection(section);
  };

  if (!open) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        width: "calc(100% - 45px)",
        height: "calc(100vh - 50px)",
        top: "50px",
        left: "45px",
        //inset: 0, // Covers the entire viewport
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1300, // Higher z-index to overlay on top of other elements
      }}
      onClick={onClose}
    >
      {/* Content container */}
      <Box
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the content
        sx={{
          position: "relative",
          width: "calc(100vw - 45px)",
          height: "calc(100vh - 50px)",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          overflowY: "clip",
        }}
      >
        <StatuteDetailSidebar
          onSectionClick={handleStatuteSectionClick}
          selectedSection={selectedStatuteSection}
          onClose={onClose}
        />
        <StatuteDetail selectedSection={selectedStatuteSection} />
      </Box>
    </Box>
  );
};

export default StatuteDetailDialog;
