import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Chip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/system";

const DropdownButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(1);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    handleClose();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Button
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="text"
        color="primary"
        endIcon={<ArrowDropDownIcon />}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 16px",
          textTransform: "none",
          marginRight: 2,
          borderRadius: 2,
          borderColor: "white",
          color: "white",
          "&:hover": {
            borderColor: "white",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        Beta Launch
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 2,
            marginTop: 1,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            minWidth: 250,
            "& .MuiMenuItem-root": {
              padding: "8px 16px",
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            },
          },
        }}
      >
        <MenuItem
          onClick={() => handleSelectOption(1)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <Typography sx={{ textTransform: "none", fontSize: "0.9rem" }}>
            Beta Launch
          </Typography>
          <CheckCircleIcon sx={{ color: "green" }} />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => handleSelectOption(2)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <Typography sx={{ textTransform: "none", fontSize: "0.9rem" }}>
            Free
          </Typography>
          <Chip
            label="Upgrade"
            size="small"
            sx={{
              bgcolor: "#ccc",
              ml: 1,
              color: "white",
            }}
          />
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectOption(3)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <Typography sx={{ textTransform: "none", fontSize: "0.9rem" }}>
            Standard Plan
          </Typography>
          <Chip
            label="Upgrade"
            size="small"
            sx={{
              bgcolor: "#ccc",
              ml: 1,
              color: "white",
            }}
          />
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectOption(4)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <Typography sx={{ textTransform: "none", fontSize: "0.9rem" }}>
            Professional Plan
          </Typography>
          <Chip
            label="Upgrade"
            size="small"
            sx={{
              bgcolor: "#ccc",
              ml: 1,
              color: "white",
            }}
          />
        </MenuItem>
        <MenuItem
          onClick={() => handleSelectOption(4)}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <Typography sx={{ textTransform: "none", fontSize: "0.9rem" }}>
            Enterprise Plan
          </Typography>
          <Chip
            label="Upgrade"
            size="small"
            sx={{
              bgcolor: "#ccc",
              ml: 1,
              color: "white",
            }}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default DropdownButton;
