export function getJournalYearRange(journal) {
  const currentYear = new Date().getFullYear();

  // Default range if no journal is specified
  if (!journal) {
    return {
      minYear: 1947,
      maxYear: currentYear,
    };
  }

  const journalRanges = {
    CLC: { minYear: 1979, maxYear: 2024 },
    CLCN: { minYear: 2016, maxYear: 2020 },
    CLD: { minYear: 2002, maxYear: 2024 },
    GBLR: { minYear: 2010, maxYear: 2016 },
    MLD: { minYear: 1984, maxYear: 2024 },
    PCRLJ: { minYear: 1968, maxYear: 2024 },
    PCRLJN: { minYear: 2016, maxYear: 2023 },
    PLC: { minYear: 1970, maxYear: 2024 },
    "PLC(CS)": { minYear: 1970, maxYear: 2024 },
    "PLC(CS)N": { minYear: 2017, maxYear: 2023 },
    PLCN: { minYear: 2017, maxYear: 2018 },
    PLD: { minYear: 1947, maxYear: 2024 },
    PTD: { minYear: 1960, maxYear: 2024 },
    SCMR: { minYear: 1968, maxYear: 2024 },
    YLR: { minYear: 1999, maxYear: 2024 },
    YLRN: { minYear: 2016, maxYear: 2023 },
  };

  return (
    journalRanges[journal] || {
      minYear: 1947,
      maxYear: currentYear,
    }
  );
}
