import { createContext, useState } from "react";
export const CMSContext = createContext();

export const CMSProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [documents, setDocuments] = useState([
    {
      id: "1",
      title: "Evidence Report sdfs fwef wefwef sefwef sefwe",
      description: "Description of case dfsdf sdfsadfs sdfadw sdfasdf",
      version: "1.1",
      uploadedBy: "John Smith sdfsafsd s",
      timestamp: "2024-11-11",
      fileType: "pdf",
      fileUrl: "/documents/evidence.pdf",
      parentId: null,
      comments: [
        "Please review the findings",
        "Need more details in section 3",
      ],
    },
    {
      id: "2",
      title: "Details of Child Node 1 fvsdf",
      description: "Additional evidence details fvsdgvsb dfvdfsvdfv dsvsdsd dsfvfsdf",
      version: "1.1",
      uploadedBy: "John Smith",
      timestamp: "2024-11-11",
      fileType: "docx",
      fileUrl: "/documents/details1.docx",
      parentId: "1",
      comments: ["Updated as requested"],
    },
    {
      id: "3",
      title: "Details of Child Node 2",
      description: "Supporting documents",
      version: "1.2",
      uploadedBy: "Emily Johnson",
      timestamp: "2024-11-12",
      fileType: "pdf",
      fileUrl: "/documents/details2.pdf",
      parentId: "1",
      comments: ["Added missing information"],
    },
    {
      id: "4",
      title: "Details of Child Node 3",
      description: "Final revisions",
      version: "1.3",
      uploadedBy: "Michael Brown",
      timestamp: "2024-11-13",
      fileType: "pdf",
      fileUrl: "/documents/details3.pdf",
      parentId: "1",
      comments: ["Ready for review"],
    },
  ]);

  const handleAddComment = (newComment) => {
    console.log(newComment, data);

    if (data && newComment.trim()) {
      setDocuments((prevDocs) =>
        prevDocs.map((doc) =>
          doc.id === data.id
            ? { ...doc, comments: [...doc.comments, newComment] }
            : doc
        )
      );
      setData((prevDocs) => ({
        ...prevDocs,
        comments: [...prevDocs.comments, newComment],
      }));
    }
  };

  return (
    <CMSContext.Provider value={{ data, setData, documents, handleAddComment, setDocuments }}>
      {children}
    </CMSContext.Provider>
  );
};
