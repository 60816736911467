import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

export default function TasksTable() {
  const [tasks, setTasks] = useState([
    {
      id: 1,
      name: "Review Evidence",
      description: "Analyze evidence submitted by both parties.",
      assignedTo: "Jane Doe",
      status: "Pending",
      startDate: "2024-10-15",
      dueDate: "2024-11-15",
    },
    {
      id: 2,
      name: "Prepare Documents",
      description: "Prepare legal documents for filing.",
      assignedTo: "Jane Doe",
      status: "Pending",
      startDate: "2024-10-15",
      dueDate: "2024-11-18",
    },
    {
      id: 3,
      name: "Client Meeting",
      description: "Meet with the client to discuss next steps.",
      assignedTo: "Jane Doe",
      status: "Pending",
      startDate: "2024-10-15",
      dueDate: "2024-11-25",
    },
  ]);

  const [open, setOpen] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [newTask, setNewTask] = useState({
    id: 0,
    name: "",
    description: "",
    assignedTo: "",
    status: "Pending",
    dueDate: "",
  });

  const handleOpen = (task) => {
    if (task) {
      setEditingTask(task);
      setNewTask(task);
    } else {
      setEditingTask(null);
      setNewTask({
        id: tasks.length + 1,
        name: "",
        description: "",
        assignedTo: "",
        status: "Pending",
        dueDate: "",
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingTask(null);
    setNewTask({
      id: 0,
      name: "",
      description: "",
      assignedTo: "",
      status: "Pending",
      dueDate: "",
    });
  };

  const handleSave = () => {
    if (editingTask) {
      setTasks(
        tasks.map((task) => (task.id === editingTask.id ? newTask : task))
      );
    } else {
      setTasks([...tasks, newTask]);
    }
    handleClose();
  };

  const handleDelete = (id) => {
    setTasks(tasks.filter((task) => task.id !== id));
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "4px",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Tasks
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#003D78",
            color: "#FFFFFF",
            textTransform: "capitalize",
          }}
          startIcon={<AddIcon />}
          onClick={() => handleOpen()}
        >
          Add Task
        </Button>
      </Box>

      <TableContainer
        sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        component={Paper}
      >
        <Table>
          <TableHead sx={{ backgroundColor: "#003D78", color: "#FFFFFF" }}>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "600", color: "#FFFFFF", py: "12px" }}
              >
                Task Name
              </TableCell>
              <TableCell
                sx={{ fontWeight: "600", color: "#FFFFFF", py: "12px" }}
              >
                Task Description
              </TableCell>
              <TableCell
                sx={{ fontWeight: "600", color: "#FFFFFF", py: "12px" }}
              >
                Assigned To
              </TableCell>
              <TableCell
                sx={{ fontWeight: "600", color: "#FFFFFF", py: "12px" }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{ fontWeight: "600", color: "#FFFFFF", py: "12px" }}
              >
                Start Date
              </TableCell>
              <TableCell
                sx={{ fontWeight: "600", color: "#FFFFFF", py: "12px" }}
              >
                Due Date
              </TableCell>
              <TableCell
                sx={{ fontWeight: "600", color: "#FFFFFF", py: "12px" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task) => (
              <TableRow key={task.id}>
                <TableCell
                  sx={{
                    maxWidth: 60, // Adjust as needed
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    py: 1,
                    fontSize: "13px",
                  }}
                >
                  {task.name}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 110, // Adjust as needed
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    py: 1,
                    fontSize: "13px",
                  }}
                >
                  {task.description}
                </TableCell>
                <TableCell sx={{ py: 1, fontSize: "13px" }}>
                  <FormControl fullWidth size="small">
                    <Select
                      sx={{ fontSize: "13px" }}
                      value={task.assignedTo}
                      onChange={(e) => {
                        setTasks(
                          tasks.map((t) =>
                            t.id === task.id
                              ? { ...t, assignedTo: e.target.value }
                              : t
                          )
                        );
                      }}
                    >
                      <MenuItem sx={{ fontSize: "13px" }} value="Jane Doe">
                        Jane Doe
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "13px" }} value="Robert Brown">
                        Robert Brown
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell sx={{ py: 1 }}>
                  <FormControl fullWidth size="small">
                    <Select
                      sx={{ fontSize: "13px" }}
                      value={task.status}
                      onChange={(e) => {
                        setTasks(
                          tasks.map((t) =>
                            t.id === task.id
                              ? { ...t, status: e.target.value }
                              : t
                          )
                        );
                      }}
                    >
                      <MenuItem sx={{ fontSize: "13px" }} value="Pending">
                        Pending
                      </MenuItem>
                      <MenuItem sx={{ fontSize: "13px" }} value="Completed">
                        Completed
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell sx={{ py: 1, fontSize: "13px" }}>
                  {task.startDate}
                </TableCell>
                <TableCell sx={{ py: 1, fontSize: "13px" }}>
                  {task.dueDate}
                </TableCell>
                <TableCell sx={{ py: 1, fontSize: "13px" }}>
                  <IconButton color="primary" onClick={() => handleOpen(task)}>
                    <EditIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(task.id)}
                  >
                    <DeleteIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{editingTask ? "Edit Task" : "Add New Task"}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "4px",
              paddingTop: "6px",
            }}
          >
            <TextField
              label="Task Name"
              fullWidth
              value={newTask.name}
              onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={newTask.description}
              onChange={(e) =>
                setNewTask({ ...newTask, description: e.target.value })
              }
            />
            <FormControl fullWidth>
              <InputLabel>Assigned To</InputLabel>
              <Select
                value={newTask.assignedTo}
                label="Assigned To"
                onChange={(e) =>
                  setNewTask({ ...newTask, assignedTo: e.target.value })
                }
              >
                <MenuItem value="Jane Doe">Jane Doe</MenuItem>
                <MenuItem value="Robert Brown">Robert Brown</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={newTask.status}
                label="Status"
                onChange={(e) =>
                  setNewTask({ ...newTask, status: e.target.value })
                }
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Due Date"
              type="date"
              fullWidth
              value={newTask.dueDate}
              onChange={(e) =>
                setNewTask({ ...newTask, dueDate: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            {editingTask ? "Save Changes" : "Add Task"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
