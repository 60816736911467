import React from "react";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Box,
  Typography,
} from "@mui/material";

const DynamicForm = ({
  formConfig,
  formData,
  handleInputChange,
  handleRadioChange,
}) => {
  const { form: { id = "", fields = [] } = {} } = formConfig || {};

  return (
    <form id={id}>
      <Grid container spacing={2}>
        {fields.map((field, index) => {
          if (shouldSkipField(fields, index)) return null;

          if (field.type === "radio") {
            return renderRadioField(
              fields,
              index,
              handleRadioChange,
              formData,
              handleInputChange
            );
          }

          if (field.type === "list") {
            return renderListField(field, formData, handleInputChange);
          }

          return (
            <Grid
              item
              xs={12}
              sm={field.type === "textarea" ? 12 : 6}
              key={field.id}
            >
              {renderField(field, formData, handleInputChange)}
            </Grid>
          );
        })}
      </Grid>
    </form>
  );
};

// Skip duplicate radio button rendering
const shouldSkipField = (fields, index) => {
  return index > 0 && fields[index - 1]?.type === "radio";
};

// Handle radio fields with adjacent dependent fields
const renderRadioField = (
  fields,
  index,
  handleRadioChange,
  formData,
  handleInputChange
) => {
  const field = fields[index];
  const nextField = fields[index + 1];

  return (
    <Grid item xs={12} key={field.id}>
      <RadioGroup
        row
        name={field.name}
        sx={{
          ml: 1,
          "& .MuiFormControlLabel-label": {
            fontSize: "13px",
          },
        }}
        onChange={(e) => handleRadioChange(e, field.name)}
      >
        {field.options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio size="small" required={field.required} />}
            label={option.label}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "13px",
              },
            }}
          />
        ))}
      </RadioGroup>
      {nextField && (
        <Box sx={{ marginTop: "4px" }}>
          {renderField(nextField, formData, handleInputChange)}
        </Box>
      )}
    </Grid>
  );
};

// Render list fields
const renderListField = (field, formData, handleInputChange) => (
  <Grid item xs={12} key={field.id}>
    <Typography sx={{ fontSize: "14px" }}>{field.label}</Typography>
    {field.fields.map((input) =>
      renderField(input, formData, handleInputChange)
    )}
  </Grid>
);

// General field renderer
const renderField = (field, formData, handleInputChange) => {
  const commonProps = {
    size: "small",
    fullWidth: true,
    variant: "outlined",
    required: field.required,
    label: field.label,
    id: field.id,
    name: field.name,
    value: formData[field.name] || "",
    onChange: handleInputChange,
    sx: { backgroundColor: "white" },
    InputProps: {
      sx: {
        bgcolor: "white",
        fontSize: "13px",
      },
    },
    InputLabelProps: {
      sx: {
        fontSize: "13px",
      },
    },
  };

  switch (field.type) {
    case "text":
      return <TextField {...commonProps} />;
    case "date":
      return (
        <TextField
          {...commonProps}
          type="date"
          inputProps={{ max: "9999-12-31" }}
          InputLabelProps={{ shrink: true }}
        />
      );
    case "textarea":
      return <TextField {...commonProps} multiline minRows={4} />;
    default:
      return null;
  }
};

export default DynamicForm;
