import axios from "axios";
import { toast } from "react-toastify";
import { reduceCredits } from "../../actions/creditActions";
import config from "../../config/api";

export const deductCredits = async (
  { userId, creditsToDeduct, event },
  dispatch
) => {
  try {
    const response = await axios.post(`${config.backendUrl}/deductCredits`, {
      userId,
      creditsToDeduct,
      event,
    });

    if (response.data?.status && response.data?.status === 200) {
      dispatch(reduceCredits(response.data.credits));  
      return true; // Deduction successful
    } else {
      // If no data in response (unlikely with Axios)
      toast.error("An error occurred while processing your request.");
      return false;
    }
  } catch (error) {
    if (error.response) {
      const { message, status } = error.response.data;

      // Handle specific error messages
      if (
        message === "Credits have expired. Please request new credits." &&
        status === 400
      ) {
        toast.error("Your credits have expired. Please request new credits.");
      } else {
        toast.error(`Error: ${message || "Something went wrong."}`);
      }

      console.error("Error deducting credits:", error.response.statusText);
    } else {
      // Handle unexpected errors
      console.error("Unexpected error during credit deduction:", error.message);
      toast.error("An unexpected error occurred while deducting credits.");
    }

    return false; // Deduction failed
  }
};
