import {
  Button,
  List,
  ListItem,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";
import React, { useState } from "react";
const dummyData = [
  { id: 1, title: "Smith v. Johnson", status: "In Process" },
  { id: 2, title: "Doe vs. Brown", status: "In Process" },
  { id: 3, title: "A v. B", status: "Not Started" },
  { id: 4, title: "C v. D", status: "Not Started" },
  { id: 5, title: "A v. B", status: "Completed" },
];
export default function Sidebar() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCase, setSelectedCase] = useState(null);
  const [placeholder, setPlaceholder] = useState("Search Cases");
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleCaseClick = (caseItem) => {
    setSelectedCase(caseItem);
  };

  // Filtered Cases Based on Search Term
  const filteredData = dummyData.filter((caseItem) =>
    caseItem.title.toLowerCase().includes(searchTerm)
  );

  const categories = [
    { title: "In Process", color: "orange" },
    { title: "Not Started", color: "red" },
    { title: "Completed", color: "green" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        flex: 1,
        gap: "4px",
        height: "calc(100vh - 150px)",
        p: "12px 12px 0 12px",
        bgcolor: "#FFFFFF",
        padding: "10px",
        borderRadius: "10px",
        width: "100%",
      }}
    >
      {/* Add Case Button */}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{
          backgroundColor: "#003D78",
          display: "flex",
          justifyContent: "center",
          marginBottom: "8px",
          textTransform: "none",
          "&:hover": { backgroundColor: "primary.main", color: "#fff" },
        }}
      >
        Add Case
      </Button>

      {/* Search Field */}
      <TextField
        placeholder={placeholder}
        variant="outlined"
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        InputProps={{
          sx: {
            p: 0,
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            paddingRight: "5px",
          },
        }}
        sx={{
          width: "100%",
          marginRight: "16px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#ccc",
            },
            "&:hover fieldset": {
              borderColor: "#00509e",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#00509e",
            },
          },
          "& input": {
            padding: "10px 14px",
            fontSize: "0.875rem",
          },
        }}
      />

      {/* Case List */}
      {categories.map((category) => (
        <Box key={category.title}>
          <Typography
            variant="overline"
            sx={{
              padding: "4px 16px",
              color: category.color,
              fontWeight: "bold",
              textTransform: "none",
            }}
          >
            {category.title} (
            {
              filteredData.filter(
                (caseItem) => caseItem.status === category.title
              ).length
            }
            )
          </Typography>
          <List>
            {filteredData
              .filter((caseItem) => caseItem.status === category.title)
              .map((caseItem) => (
                <ListItem key={caseItem.id} disablePadding>
                  <ListItemButton
                    sx={{
                      mr: 1,
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#ECECEC",
                      },
                      backgroundColor:
                        selectedCase?.id === caseItem.id
                          ? "#ECECEC"
                          : "inherit",
                    }}
                    onClick={() => handleCaseClick(caseItem)}
                  >
                    <Typography
                      variant="subtitle2"
                      noWrap
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        fontSize: "13px",
                      }}
                    >
                      {caseItem.title}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      ))}
    </Box>
  );
}
