import React, { useState, useRef, useEffect, useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Stack,
  Avatar,
  TextField,
  IconButton,
  Button,
  Tooltip,
  Typography,
  CircularProgress,
  Skeleton,
  Fab,
  Dialog,
  DialogContent,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Modal,
  DialogActions,
  DialogTitle,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IosShareIcon from "@mui/icons-material/IosShare";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/api";
import { getChatTitles } from "../actions/chatActions";
import BookListingDialog from "./BookListingDialog";
import AlertDialog from "./AlertDialog";
import Feedback from "./Feedback";
import { trackEvent, trackEventWithResponseTime } from "../analytics";
import { formatRefLabel, wait } from "../utils/textFormatting";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CaseLawPreviewModal from "./UI/CaseLawPreviewModal";
import CaseLawsSidebar from "./UI/CaseLawsSidebar";
import { findCaseFromAzureAiSearch } from "../actions/searchActions";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import { KeyboardVoice } from "@mui/icons-material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CancelIcon from "@mui/icons-material/Cancel";
import Markdown from "react-markdown";
import CloseIcon from "@mui/icons-material/Close";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import InfoDialog from "./UI/InfoDialog";
import lawgptLogo from "./../assets/images/lawgptLogo.png";
import modelLogo from "./../assets/images/modelLogo.png";
import { showInsufficientCreditsModal } from "../actions/creditActions";
import { deductCredits } from "../utils/HelperFunction/deductCredits";
import { fetchBookmarkGroups } from "../store/Bookmarks/bookmarksActions";

const deleteCaseUrl =
  "https://rg-law-gpt-be-auth2.azurewebsites.net/api/deleteCaseOrStatuteFromGroup";

const markdownStyles = {
  ul: {
    margin: "0px",
    paddingLeft: "20px",
    fontSize: "14px",
  },
  ol: {
    margin: "0px",
    paddingLeft: "20px",
    marginTop: "2px",
    fontSize: "14px",
  },
  li: {
    margin: "0px",
    padding: "0px",
    marginTop: "2px",
    fontSize: "14px",
  },
  p: {
    margin: "0px",
    padding: "0px",
    fontSize: "14px",
  },
};

// Default Function Component
const ChatInterface = ({ toggleSidebar }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statuteRelevantCasesLoading, setStatuteRelevantCasesLoading] =
    useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [chatTitle, setChatTitle] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [finalChatId, setFinalChatId] = useState("");
  const [openListingDialog, setOpenListingDialog] = useState(false);
  const [alignment, setAlignment] = useState("balanced");
  const [selectedBook, setSelectedBook] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [response, setResponse] = useState("");
  const [feedbackQuery, setFeebackQuery] = useState("");
  const [feedbackRefs, setFeedbackRefs] = useState([]);
  const [feedbackGiven, setFeedbackGiven] = useState({});
  const [messageIndex, setMessageIndex] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isCaseLawClicked, setIsCaseLawClicked] = useState(false);
  const [statuteRelevantCases, setStatuteRelevantCases] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [fullscreen, setFullscreen] = React.useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [placeholder, setPlaceholder] = useState("Ask any legal question...");
  const silenceTimeoutRef = useRef(null);
  const finalTranscriptRef = useRef("");
  const [isVoiceInput, setIsVoiceInput] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);
  const [bookmarkGroupName, setBookmarkGroupName] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [caseNote, setCaseNote] = useState("");
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
  const [bookmarkedCaseIds, setBookmarkedCaseIds] = useState(new Set());
  const [selectedStatute, setSelectedStatute] = useState(null);
  const [statuteNote, setStatuteNote] = useState("");
  const [caseNoteError, setCaseNoteError] = useState("");
  const [statuteNoteError, setStatuteNoteError] = useState("");
  const [bookmarkedStatuteIds, setBookmarkedStatuteIds] = useState(new Set());
  const [openStatuteModal, setOpenStatuteModal] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const chatContainerStartRef = useRef(null);
  const chatContainerEndRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const chatHistory = useSelector((state) => state.chatHistory);
  const { loading: historyLoading, history } = chatHistory;

  const newChat = useSelector((state) => state.newChat);
  const { isClicked } = newChat;

  const chatTitles = useSelector((state) => state.chatTitles);
  const { successEdit } = chatTitles;
  // Check if sufficient credits are available
  const credits = useSelector((state) => state.credit.credits); // Get credits from Redux store
  const userId = useSelector((state) => state.credit.userId); // Get userId from Redux store
  const bookmarkGroups = useSelector((state) => state.bookmarkGroups);
  const { groups } = bookmarkGroups;

  const statuteContentWithStyles = `
  <style>
    .Section1 * {
      font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
    }
     .Section1 b {
      color: #303f9f;
    }
  </style>
  ${modalContent}
`;

  const handleCloseBookmarkModal = () => {
    setOpenBookmarkModal(false);
    setSelectedGroup("");
    setCaseNote("");
    setCaseNoteError("");
    setIsCreatingNewGroup(false);
  };

  const handleCloseStatuteBookmarkModal = () => {
    setOpenStatuteModal(false);
    setSelectedGroup("");
    setStatuteNote("");
    setStatuteNoteError("");
    setIsCreatingNewGroup(false);
  };

  const handleNoteChange = (value) => {
    if (value.length <= 2000) {
      setCaseNote(value);
      setCaseNoteError("");
    } else {
      setCaseNoteError("You can only input 2000 characters");
    }
  };

  const handleStatuteNoteChange = (value) => {
    if (value.length <= 2000) {
      setStatuteNote(value);
      setStatuteNoteError("");
    } else {
      setStatuteNoteError("You can only input 2000 characters");
    }
  };

  const handleClickInfoButton = () => {
    setOpenInfoDialog(true);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  const handlePreviewClick = (caseItem) => {
    dispatch(findCaseFromAzureAiSearch(caseItem.label, caseItem.court));
    setOpenPreview(true);
  };

  const handleFullscreenToggle = () => {
    setFullscreen(!fullscreen);
  };

  const handleCloseCaseLawsSidebar = () => {
    setIsCaseLawClicked(false);
  };

  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const handleCloseListingDialog = () => {
    setOpenListingDialog(false);
  };

  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };

  const handleFeedbackGiven = (messageIndex) => {
    setFeedbackGiven({ ...feedbackGiven, [messageIndex]: true });
  };

  const formatCaseTitle = (title) => {
    if (!title) return;
    const splitTitle = title.split("Honorable")[0];
    const titleCaseTitle = splitTitle
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return titleCaseTitle;
  };

  const formatCourtName = (courtName) => {
    if (!courtName) return "";

    return courtName
      .toLowerCase()
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fetchStatuteRelevantCases = async (citationNames) => {
    setStatuteRelevantCasesLoading(true);
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const requestBody = JSON.stringify({
      statute: {
        Citations: citationNames,
      },
    });
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: requestBody,
      redirect: "follow",
    };
    fetch(`${config.apiUrl}/find_relevant_cases_to_statute`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result && Array.isArray(result.relevant_cases)) {
          setStatuteRelevantCases(result?.relevant_cases);
        } else {
          setStatuteRelevantCases([]);
        }
        setStatuteRelevantCasesLoading(false);
      })
      .catch((error) => {
        setStatuteRelevantCasesLoading(false);
        console.log(error);
      });
  };

  const handleCaseLawsClick = (citationNames) => {
    const formattedArray = citationNames.map((name) => ({
      Citation_Name: name,
    }));
    setIsCaseLawClicked(true);
    fetchStatuteRelevantCases(formattedArray);
  };

  const updateChatTitle = useCallback(async () => {
    const endpoint = `${config.apiUrl}/get_chat_title`;
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ user_id: userInfo._id, chat_id: finalChatId }),
    });
    if (response.ok) {
      let title = await response.json();
      setChatTitle(title);
    }
  }, [userInfo, finalChatId]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const generateChatbotResponse = useCallback(
    async (userMessage) => {
      let keyword;
      let eventAction = "";

      if (alignment === "balanced") {
        keyword = "hybrid_chat";
        eventAction = "Free Form Query";
      }
      if (alignment === "precise") {
        keyword = "statute_chat";
        eventAction = "Structured Query";
      }
      if (alignment === "case law") {
        keyword = "case_chat";
        eventAction = "Case Law Query";
      }
      const balancedAzureFn = `${config.apiUrl}/${keyword}`;
      const data = {
        user_id: userInfo._id,
        chat_id: finalChatId,
        temperature: "precise",
        answer_length: "high",
        query: userMessage,
      };

      const startTime = new Date();

      trackEvent("Chat", "UserQuery", `User: ${userInfo.name}`);

      try {
        setIsLoading(true);
        const response = await fetch(balancedAzureFn, {
          method: "POST",
          body: JSON.stringify(data),
        });

        const endTime = new Date();
        const responseTimeMilliseconds = endTime - startTime;

        if (!response.ok) {
          throw new Error("Failed to response");
        }

        trackEvent("Chat", eventAction, `User: ${userInfo.name}`);
        trackEventWithResponseTime(
          "Chat",
          "Response Time",
          `User Message: ${userMessage}`,
          responseTimeMilliseconds
        );

        return await response.json();
      } catch (error) {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [alignment, finalChatId, userInfo]
  );

  const handleSendMessage = useCallback(async () => {
    // Ignore empty messages
    if (inputMessage.trim() === "") return;
    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }
    if (location.pathname === "/home") {
      return navigate(`/app/legal-chat?query=${inputMessage}`);
    }
    trackEvent("Chat", "Chat Initiated", `User: ${userInfo.name}`);
    const newMessages = [
      ...(messages || []),
      { text: { response: inputMessage }, isUser: true },
    ];
    setMessages(newMessages);
    setInputMessage("");

    // Simulate a response from the chatbot
    const chatbotResponse = await generateChatbotResponse(inputMessage);
    if (chatbotResponse) {
      const updatedMessages = [
        ...newMessages,
        {
          text: chatbotResponse,
          isUser: false,
        },
      ];
      setMessages(updatedMessages);

      // Deduct credits using the helper
      const deductionSuccess = deductCredits(
        { userId, creditsToDeduct: 1, event: "Send Message on Legal Chat" },
        dispatch
      );

      if (!deductionSuccess) {
        console.warn(
          "Failed to deduct credits. Ensure retry or user notification."
        );
      }
    }

    if (messages.length <= 1) {
      dispatch(getChatTitles(userInfo?._id));
      updateChatTitle();
    }
    // Clear the input field
    //setInputMessage('');
  }, [
    dispatch,
    location,
    navigate,
    inputMessage,
    generateChatbotResponse,
    userInfo,
    messages,
    updateChatTitle,
  ]);

  const handleCopyClick = async (responseText) => {
    setIsCopied(true);
    try {
      await navigator.clipboard.writeText(responseText);
      await wait();
      setIsCopied(false);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const handleExportChat = () => {
    const chatText = messages
      .map((message) => {
        return message.isUser
          ? `User: ${message.text.response}`
          : `Assistant: ${message.text.response}\nReferences:\n${
              message.text.references
                ? message.text.references
                    ?.map((ref) => {
                      return `Label: ${ref.label}\nContent: ${ref.content}\n URL: ${ref.url}`;
                    })
                    .join("\n\n")
                : "Not Applicable"
            }`;
      })
      .join("\n");

    const blob = new Blob([chatText], { type: "text/plain" });

    saveAs(blob, `${chatTitle}.txt`);
  };

  const handleStatutePreview = (content) => {
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateSelectedBook = (book) => {
    setSelectedBook(book);
  };

  const handleChangeChat = (event) => {
    setAlignment(event.target.value);
  };

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false);
  };

  const handleFeedbackClick = (response, references, index) => {
    const userQuery = messages[index - 1]?.text.response;
    setFeebackQuery(userQuery);
    setFeedbackRefs(references);
    setResponse(response);
    setOpenFeedbackDialog(true);
    setMessageIndex(index);
  };

  const handleScroll = () => {
    const container = chatContainerStartRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const threshold = 100;
      const shouldShow = scrollTop + clientHeight < scrollHeight - threshold;
      setShowScrollButton(shouldShow);
    }
  };

  const scrollToBottom = () => {
    chatContainerEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const recognition = new window.webkitSpeechRecognition();
  recognition.continuous = true;
  recognition.interimResults = true;
  recognition.lang = "en-US";

  recognition.onresult = (event) => {
    const transcript = Array.from(event.results)
      .map((result) => result[0].transcript)
      .join("");
    setInputMessage(transcript);
    finalTranscriptRef.current = transcript;
    setIsVoiceInput(true);
    resetSilenceTimer();
  };

  recognition.onerror = (event) => {
    console.error(event.error);
    stopListening();
  };

  const startListening = () => {
    setIsListening(true);
    setPlaceholder("Speak...");
    recognition.start();

    startSilenceTimer();
  };

  const stopListening = () => {
    setIsProcessing(true);
    recognition.stop();
  };

  recognition.onend = () => {
    setIsListening(false);
    setIsProcessing(false);
    setPlaceholder("Ask any legal question...");
    clearSilenceTimer();
  };

  const toggleListening = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const startSilenceTimer = () => {
    silenceTimeoutRef.current = setTimeout(() => {
      stopListening();
    }, 3000);
  };

  const resetSilenceTimer = () => {
    clearTimeout(silenceTimeoutRef.current);
    startSilenceTimer();
  };

  const clearSilenceTimer = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };

  const handleBookmarkClick = (caseItem) => {
    setSelectedCase(caseItem);
    const defaultGroup = groups.find(
      (group) => group.groupName === "Bookmarks"
    );

    setSelectedGroup(defaultGroup._id);
    //fetchBookmarkGroups();
    setOpenBookmarkModal(true);
  };

  const handleStatuteBookmark = (statute) => {
    setSelectedStatute(statute);
    const defaultGroup = groups.find(
      (group) => group.groupName === "Bookmarks"
    );

    setSelectedGroup(defaultGroup._id);
    //fetchBookmarkGroups();
    setOpenStatuteModal(true);
  };

  const handleCreateBookmarkGroup = async () => {
    if (!bookmarkGroupName) return;

    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }
      const userId = userInfo._id;
      const response = await fetch(
        "https://rg-law-gpt-be-auth2.azurewebsites.net/api/createBookmarkGroup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            groupName: bookmarkGroupName,
            caseId: selectedCase.id,
            caseNote,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${bookmarkGroupName}`,
        });
        setBookmarkedCaseIds(
          (prevIds) => new Set([...prevIds, selectedCase.id])
        );
        setOpenBookmarkModal(false);
        setBookmarkGroupName("");
        setCaseNote("");
        setIsCreatingNewGroup(false);
        dispatch(fetchBookmarkGroups(userInfo._id));
        const deductionSuccess = deductCredits(
          { userId, creditsToDeduct: 1, event: "Create Bookmark Group" },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
      } else {
        throw new Error(data.message || "Failed to create bookmark group.");
      }
    } catch (error) {
      setSnackbarMessage({ type: "error", text: error.message });
    }
  };

  const handleCreateStatuteGroup = async () => {
    if (!bookmarkGroupName) return;
    try {
      const userId = userInfo._id;
      const response = await fetch(
        "https://rg-law-gpt-be-auth2.azurewebsites.net/api/createBookmarkGroup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            groupName: bookmarkGroupName,
            statuteId: selectedStatute.id,
            statuteNote,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${bookmarkGroupName}`,
        });
        setBookmarkedStatuteIds(
          (prevIds) => new Set([...prevIds, selectedStatute.id])
        );
        setOpenStatuteModal(false);
        setBookmarkGroupName("");
        setStatuteNote("");
        dispatch(fetchBookmarkGroups(userInfo._id));
      } else {
        throw new Error(data.message || "Failed to create bookmark group.");
      }
    } catch (error) {
      console.error("Error creating bookmark group:", error);
    }
  };

  const handleAddCaseToGroup = async () => {
    if (!selectedGroup) return;

    try {
      const userId = userInfo._id;
      const response = await fetch(
        "https://rg-law-gpt-be-auth2.azurewebsites.net/api/addCaseToGroup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            groupId: selectedGroup,
            caseId: selectedCase.id,
            caseNote,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${
            groups?.find((group) => group._id === selectedGroup).groupName
          }`,
        });
        setBookmarkedCaseIds(
          (prevIds) => new Set([...prevIds, selectedCase.id])
        );
        setOpenBookmarkModal(false);
        setCaseNote("");
      } else {
        throw new Error(data.message || "Failed to add case to group.");
      }
    } catch (error) {
      setSnackbarMessage({ type: "error", text: error.message });
    }
  };

  const findGroupIdByCaseId = (documents, caseId) => {
    // Find the document where the caseId exists in the 'cases' array
    const group = documents.find((doc) =>
      doc.cases.some((caseItem) => caseItem.id === caseId)
    );

    // Return the _id of the group or null if not found
    return group ? group._id : null;
  };

  const handleUnBookmark = async (caseId) => {
    try {
      //fetchBookmarkGroups();
      const groupId = findGroupIdByCaseId(groups, caseId);
      const response = await fetch(deleteCaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id, // User ID from context or state
          groupId, // Group ID where the case is bookmarked
          caseId, // Case ID to delete
        }),
      });

      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: "Bookmark Removed!",
        });
        // Update the state by removing the case ID from bookmarked case IDs
        setBookmarkedCaseIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(caseId);
          return updatedIds;
        });
      } else {
        console.error("Error deleting bookmarked case");
      }
    } catch (error) {
      console.error("Error deleting bookmarked case:", error);
    }
  };

  const handleAddStatuteToGroup = async () => {
    if (!selectedGroup) return;
    try {
      const userId = userInfo._id;
      const response = await fetch(
        "https://rg-law-gpt-be-auth2.azurewebsites.net/api/addStatuteToGroup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId,
            groupId: selectedGroup,
            statuteId: selectedStatute.id,
            statuteNote, // Include notes when adding to a group
            statuteName: formatRefLabel(selectedStatute.label),
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: "success",
          text: `Saved to ${
            groups?.find((group) => group._id === selectedGroup).groupName
          }`,
        });
        setBookmarkedStatuteIds(
          (prevIds) => new Set([...prevIds, selectedStatute.id])
        );

        setOpenStatuteModal(false);
        setStatuteNote(""); // Clear notes after submission
      } else {
        throw new Error(data.message || "Failed to add statute to group.");
      }
    } catch (error) {
      console.error("Error adding statute to group:", error);
    }
  };

  const findGroupIdByStatuteId = (documents, statuteId) => {
    const group = documents.find((doc) =>
      doc.statutes.some((statuteItem) => statuteItem.id === statuteId)
    );

    return group ? group._id : null;
  };

  const handleUnBookmarkStatute = async (statuteId) => {
    try {
      //fetchBookmarkGroups();
      const groupId = findGroupIdByStatuteId(groups, statuteId);
      const response = await fetch(deleteCaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id, // User ID from context or state
          groupId, // Group ID where the statute is bookmarked
          statuteId, // Statute ID to delete
        }),
      });

      if (response.ok) {
        // Update the state by removing the statute ID from bookmarked statute IDs
        setBookmarkedStatuteIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(statuteId);
          return updatedIds;
        });
      } else {
        console.error("Error deleting bookmarked statute");
      }
    } catch (error) {
      console.error("Error deleting bookmarked statute:", error);
    }
  };

  useEffect(() => {
    // Fetch bookmark groups when the component mounts or when userInfo changes
    if (userInfo._id) {
      dispatch(fetchBookmarkGroups(userInfo._id));
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (groups) {
      // Extract all case IDs from the cases array in each group document
      const allCaseIds = groups?.flatMap((group) =>
        group.cases.map((caseItem) => caseItem.id)
      );

      setBookmarkedCaseIds(new Set(allCaseIds)); // Store IDs in a Set for fast lookup
      const defaultGroup = groups?.find(
        (group) => group.groupName === "Bookmarks"
      );
      setSelectedGroup(defaultGroup?._id);

      // Extract all statute IDs from the statutes array in each group document
      const allStatuteIds = groups?.flatMap((group) =>
        group.statutes.map((statute) => statute.id)
      );

      // Set the bookmark groups and statute IDs
      setBookmarkedStatuteIds(new Set(allStatuteIds)); // Store IDs in a Set for fast lookup
    }
  }, [groups]);

  useEffect(() => {
    if (location.pathname === "/home") {
      setShowScrollButton(false);
      setMessages([]);
      setChatTitle("");
      setSelectedBook(null);
      setIsCaseLawClicked(false);
      dispatch({ type: "CHAT_HISTORY_RESET" });
    }
  }, [location, dispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const receivedQuery = queryParams.get("query");
    if (receivedQuery) {
      setInputMessage(receivedQuery);

      const handleMessageAndUpdateUrl = async () => {
        // Introduce a slight delay before clearing the URL
        setTimeout(() => {
          queryParams.delete("query");
          navigate({ search: queryParams.toString() }, { replace: true });
        }, 100);
        await handleSendMessage();
      };
      handleMessageAndUpdateUrl();
    }
  }, [location, handleSendMessage, navigate]);

  useEffect(() => {
    const container = chatContainerStartRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [history, messages]);

  useEffect(() => {
    if (successEdit) {
      updateChatTitle();
      dispatch({ type: "SUCCESS_EDIT_RESET" });
    }
  }, [successEdit, dispatch, updateChatTitle]);

  useEffect(() => {
    if (chatContainerEndRef.current) {
      chatContainerEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (isClicked || !history) {
      setShowScrollButton(false);
      setMessages([]);
      setChatTitle("");
      setSelectedBook(null);
      setIsCaseLawClicked(false);
      dispatch({ type: "DELETE_CHAT_RESET" });
      dispatch({ type: "NEW_CHAT_RESET" });
    }
  }, [dispatch, isClicked, history]);

  useEffect(() => {
    if (historyLoading) {
      setIsHistoryLoading(true);
    }
  }, [historyLoading]);

  useEffect(() => {
    //Add history to chat interface
    if (history) {
      setIsHistoryLoading(false);
      dispatch({ type: "NEW_CHAT_RESET" });
      setChatTitle(history.chat_title);
      setFinalChatId(history.chat_id);
      setAlignment("balanced");
      setSelectedBook(null);
      const historyMessages = history.messages;
      if (historyMessages?.length > 0) {
        const firstContent = historyMessages[0].content;
        if (
          firstContent.startsWith("Retrieve") ||
          firstContent.startsWith("retrieve")
        ) {
          setAlignment("case law");
        }
      }
      if (historyMessages?.length > 0) {
        const firstContent = historyMessages[0].content;
        if (firstContent.startsWith("search")) {
          setAlignment("precise");
          setSelectedBook({
            code: "COP",
            format:
              'For article 63A, clause 1 sub-clause a, the search query would be "63A.1.a"',
            full_name: "THE CONSTITUTION OF THE ISLAMIC REPUBLIC OF PAKISTAN",
          });
        }
      }
      const mappedMessages =
        historyMessages?.length > 0 &&
        historyMessages.map((message) => {
          return {
            text: {
              response: message.response || message.content,
              references: message.references,
            },
            isUser: message.role === "user",
          };
        });
      setMessages(mappedMessages);
    }
  }, [history, dispatch]);

  useEffect(() => {
    return () => {
      dispatch({ type: "CHAT_HISTORY_RESET" });
    };
  }, [dispatch]);

  useEffect(() => {
    const newChatId = uuidv4();

    if (!history) {
      setFinalChatId(newChatId);
      setSelectedBook(null);
      setAlignment("balanced");
    }
  }, [history, dispatch, isClicked]);

  useEffect(() => {
    if (!isListening && inputMessage.trim() !== "" && isVoiceInput) {
      handleSendMessage();
      setIsVoiceInput(false);
    }
  }, [isListening, inputMessage, handleSendMessage, isVoiceInput]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          boxSizing: "border-box",
          flexDirection: "column",
          height: "calc(100vh - 50px)",
          width: "80.2%",
          maxWidth: "80.2%",
          p: "12px 12px 0 12px",
          backgroundColor: "#f5f5f5",

          "@media (min-width: 1920px)": {
            width: "81%",
            maxWidth: "81%",
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            boxSizing: "border-box",
            flexDirection: "column",
            bgcolor: "#fff",
            p: 1,
            borderRadius: "8px",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: chatTitle ? "1px solid #ccc" : "",
              width: "100%",
              minHeight: "50px",
            }}
          >
            <IconButton
              sx={{ display: { xs: "flex", sm: "none" } }}
              onClick={toggleSidebar}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" sx={{ marginLeft: "2rem" }}>
                {chatTitle}
              </Typography>
            </Box>
            {history && (
              <Tooltip
                title="Export Chat"
                arrow
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "#101936",
                    },
                    "& .MuiTooltip-arrow": {
                      color: "#101936",
                    },
                  },
                }}
              >
                <IconButton
                  variant="contained"
                  onClick={handleExportChat}
                  sx={{ marginRight: "1rem" }}
                >
                  <IosShareIcon sx={{ color: "#2196f3" }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {/* Conditional Rendering of the Chat Mode Buttons */}
          {messages.length === 0 && !selectedBook && !isHistoryLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "70%",
                width: "60%",
                marginLeft: "auto",
                marginRight: "auto",
                gap: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <img
                  src={lawgptLogo}
                  alt="product logo"
                  style={{
                    maxWidth: "180px",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  value={inputMessage}
                  placeholder={placeholder}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    sx: {
                      p: 0,
                      backgroundColor: "#ffffff",
                      borderRadius: "20px",
                      paddingRight: "5px",
                    },
                    startAdornment: (
                      <IconButton sx={{ color: "#303f9f" }}>
                        <SearchIcon />
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton
                        sx={{
                          color: isListening ? "#FF4081" : "#303f9f",
                          animation: isListening
                            ? "pulse 1.5s infinite"
                            : "none",
                        }}
                        variant="contained"
                        onClick={toggleListening}
                        disabled={isProcessing}
                      >
                        <KeyboardVoice />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    //width: '59%',
                    mb: "4px",
                    //flexGrow: 1,
                    marginRight: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "gray",
                      },
                      "&:hover fieldset": {
                        borderColor: "#00509e",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00509e",
                      },
                    },
                    "& input": {
                      padding: "10px 10px",
                      fontSize: "0.875rem",
                    },
                  }}
                />
                <IconButton
                  size="small"
                  onClick={handleClickInfoButton}
                  sx={{
                    width: "34px",
                    height: "34px",
                  }}
                >
                  <InfoIcon color="info" />
                </IconButton>
              </Stack>
              <RadioGroup
                row
                value={alignment}
                onChange={handleChangeChat}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  width: "100%",
                }}
              >
                {[
                  { label: "Hybrid Chat", value: "balanced" },
                  { label: "Statute Chat", value: "precise" },
                  { label: "Case Chat", value: "case law" },
                ].map((mode, index) => (
                  <FormControlLabel
                    key={index}
                    value={mode.value}
                    control={
                      <Radio
                        sx={{
                          color: "#A0A0A0",
                          "&.Mui-checked": {
                            color: "#1a73e8",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                          },
                        }}
                      />
                    }
                    label={mode.label}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: mode.value === alignment ? "#36454f" : "#A0A0A0",
                        fontSize: "14px",
                        fontWeight:
                          mode.value === alignment ? "bold" : "normal",
                      },
                    }}
                  />
                ))}
              </RadioGroup>
            </Box>
          ) : (
            <div
              ref={chatContainerStartRef}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxSizing: "border-box",
                padding: "16px",
                background: "#fff",
                height: "75%",
                maxWidth: "100%",
                width: "100%",
                overflowY: "auto",
              }}
            >
              <style>
                {`
          div::-webkit-scrollbar {
            width: 5px;
          }
          div::-webkit-scrollbar-track {
            background: #fff;
          }
          div::-webkit-scrollbar-thumb {
            background: #bbb;
            border-radius: 6px;
          }
        `}
              </style>
              {isHistoryLoading ? (
                <Box
                  sx={{
                    height: "30%",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={24} />
                </Box>
              ) : (
                messages.length > 0 &&
                messages.map((message, messageIndex) => (
                  <Box
                    key={uuidv4()}
                    sx={{
                      display: "flex",
                      justifyContent: message.isUser
                        ? "flex-end"
                        : "flex-start",
                      boxSizing: "border-box",
                      padding: "8px",
                      width: "100%",
                      mb: 1,
                    }}
                  >
                    {!message.isUser && (
                      <Avatar
                        sizes=""
                        variant="circle"
                        sx={{
                          width: "32px",
                          height: "32px",
                          marginRight: "10px",
                          // border: '1px solid #0BA4E0',
                          //backgroundColor: '#101936',
                        }}
                        src={modelLogo}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        backgroundColor: message.isUser ? "#F1F9FF" : "#f5f5f5",
                        alignSelf: message.isUser ? "flex-end" : "flex-start",
                        borderRadius: message.isUser
                          ? "10px 0 10px 10px"
                          : "0 10px 10px 10px",
                        marginLeft: message.isUser ? "42px" : "0",
                        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
                        maxWidth: message.isUser ? "60%" : "85%",
                        //overflowX: 'hidden',
                      }}
                    >
                      <Typography
                        variant="body1"
                        textAlign="justify"
                        sx={{
                          wordBreak: "break-word",
                          width: "97%",
                          //overflowWrap: 'break-word',
                          padding: "1rem",
                          color: "#565454",
                        }}
                      >
                        <Markdown
                          components={{
                            ul: ({ ...props }) => (
                              <ul style={markdownStyles.ul} {...props} />
                            ),
                            ol: ({ ...props }) => (
                              <ol style={markdownStyles.ol} {...props} />
                            ),
                            li: ({ ...props }) => (
                              <li style={markdownStyles.li} {...props} />
                            ),
                            p: ({ ...props }) => (
                              <Typography
                                style={markdownStyles.p}
                                {...props}
                                component="p"
                              />
                            ),
                          }}
                        >
                          {message.text.response.replace(/\n\n/gi, "  \n")}
                        </Markdown>
                        {message.text.references?.filter(
                          (reference) => reference.type === "statute"
                        ).length > 0 && (
                          <Typography
                            component="div"
                            sx={{
                              fontWeight: "bold",
                              marginTop: 2,
                              marginBottom: "8px",
                              fontSize: "14px",
                            }}
                          >
                            Relevant Statutes:
                          </Typography>
                        )}
                        {message.text?.references
                          ?.filter((reference) => reference.type === "statute")
                          .map((ref, refIndex) => (
                            <Box
                              key={uuidv4()}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                mb: "5px",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  handleStatutePreview(ref.html_content)
                                }
                                size="small"
                              >
                                <VisibilityIcon
                                  fontSize="small"
                                  sx={{ color: "#2196f3" }}
                                />
                              </IconButton>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  sx={{
                                    p: 0,
                                    color: "#d2042d",
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  {bookmarkedStatuteIds.has(ref.id) ? (
                                    <>
                                      <Tooltip title="Unbookmark">
                                        <TurnedInIcon
                                          onClick={() =>
                                            handleUnBookmarkStatute(ref.id)
                                          }
                                          fontSize="small"
                                        />
                                      </Tooltip>
                                      {/* <Typography
                                    variant="body2"
                                    sx={{
                                      ml: 0.5,
                                      color: "#00509e",
                                      cursor: "pointer",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                    onClick={() =>
                                      handleUnBookmarkStatute(ref.id)
                                    }
                                  >
                                    Bookmarked
                                  </Typography> */}
                                    </>
                                  ) : (
                                    <>
                                      <Tooltip title="Bookmark">
                                        <TurnedInNotIcon
                                          sx={{
                                            "&:hover": {
                                              backgroundColor: "transparent",
                                            },
                                          }}
                                          onClick={() =>
                                            handleStatuteBookmark(ref)
                                          }
                                          fontSize="small"
                                        />
                                      </Tooltip>
                                      {/* <Typography
                                    variant="body2"
                                    sx={{
                                      ml: 0.5,
                                      color: "#00509e",
                                      cursor: "pointer",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                    onClick={() => handleStatuteBookmark(ref)}
                                  >
                                    Bookmark
                                  </Typography> */}
                                    </>
                                  )}
                                </IconButton>
                              </Box>

                              <Typography
                                component="div"
                                sx={{
                                  //fontWeight: 'bold',
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                variant="subtitle2"
                              >
                                {formatRefLabel(ref.label)}
                              </Typography>
                              <Stack
                                sx={{
                                  ml: "auto",
                                  display: "flex",
                                }}
                                direction="row"
                                alignItems="center"
                              >
                                {/* <IconButton
                              onClick={() =>
                                handleCaseLawsClick(ref.cited_cases)
                              }
                              size="small"
                            >
                              <GavelOutlined
                                fontSize="small"
                                sx={{ color: "#ff9800" }}
                              />
                            </IconButton>
                            <Typography
                              onClick={() =>
                                handleCaseLawsClick(ref.cited_cases)
                              }
                              variant="body2"
                              sx={{
                                color: "#303f9f",
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              Case Laws
                            </Typography> */}
                              </Stack>
                            </Box>
                          ))}
                        {/* UI for the Case Law References */}
                        {message.text.references?.filter(
                          (reference) =>
                            reference.type !== "case" &&
                            reference.type !== "statute"
                        ).length > 0 && (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              marginBottom: "6px",
                              marginTop: 2,
                              fontSize: "14px",
                            }}
                          >
                            Relevant Case Laws:
                          </Typography>
                        )}
                        {message.text.references
                          ?.filter(
                            (reference) =>
                              reference.type !== "case" &&
                              reference.type !== "statute"
                          )
                          .map((law, index) => (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "12px",
                                }}
                              >
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => handlePreviewClick(law)}
                                    sx={{ p: 0, ml: 0.5, mr: 0.5 }}
                                  >
                                    <VisibilityIcon
                                      fontSize="small"
                                      sx={{ color: "#2196f3" }}
                                    />
                                  </IconButton>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton
                                      sx={{
                                        p: 0,
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                    >
                                      {bookmarkedCaseIds.has(law.id) ? (
                                        <>
                                          <Tooltip title="Unbookmark">
                                            <TurnedInIcon
                                              onClick={() =>
                                                handleUnBookmark(law.id)
                                              }
                                              sx={{ color: "#d2042d" }}
                                              fontSize="small"
                                            />
                                          </Tooltip>
                                          {/* <Typography
                                        variant="body2"
                                        sx={{
                                          ml: 0.5,
                                          color: "#303f9f",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                        onClick={() => handleUnBookmark(law.id)}
                                      >
                                        Bookmarked
                                      </Typography> */}
                                        </>
                                      ) : (
                                        <>
                                          <Tooltip title="Bookmark">
                                            <TurnedInNotIcon
                                              onClick={() =>
                                                handleBookmarkClick(law)
                                              }
                                              sx={{
                                                color: "#d2042d",
                                                "&:hover": {
                                                  backgroundColor:
                                                    "transparent",
                                                },
                                              }}
                                              fontSize="small"
                                            />
                                          </Tooltip>
                                          {/* <Typography
                                        variant="body2"
                                        sx={{
                                          ml: 0.5,
                                          color: "#303f9f",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                        onClick={() => handleBookmarkClick(law)}
                                      >
                                        Bookmark
                                      </Typography> */}
                                        </>
                                      )}
                                    </IconButton>
                                  </Box>
                                  <Typography
                                    variant="subtitle2"
                                    // sx={{ fontWeight: 'bold' }}
                                  >
                                    {formatCaseTitle(law?.title)}
                                  </Typography>
                                  <Stack
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontWeight: "bold" }}
                                      variant="subtitle2"
                                    >
                                      ({law.label}) -
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight="bold"
                                    >
                                      {formatCourtName(law.court)}
                                    </Typography>
                                  </Stack>
                                </Stack>
                                {/* <Stack
                              sx={{
                                display: "flex",
                                flexDirection: isCaseLawClicked
                                  ? "column"
                                  : "row",
                                alignItems: "flex-start",
                                gap: "8px",
                              }}
                            ></Stack> */}
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {" "}
                                  {/* <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                                gap={1}
                                onClick={() => handlePreviewClick(law)}
                              >
                                <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                  <VisibilityIcon
                                    fontSize="small"
                                    sx={{ color: "#2196f3" }}
                                  />
                                </IconButton>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    mr: 0.5,
                                    color: "#303f9f",
                                    cursor: "pointer",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  Preview
                                </Typography>
                              </Stack> */}
                                </Stack>
                              </Box>
                              <CaseLawPreviewModal
                                open={openPreview}
                                onClose={() => {
                                  setOpenPreview(false);
                                  dispatch({ type: "CASE_DETAIL_RESET" });
                                }}
                              />
                            </>
                          ))}
                        {message.text.references?.filter(
                          (reference) => reference.type === "case"
                        ).length > 0 && (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              marginBottom: "6px",
                              marginTop: 2,
                              fontSize: "14px",
                            }}
                          >
                            Relevant Case Laws:
                          </Typography>
                        )}
                        {message.text.references
                          ?.filter((reference) => reference.type === "case")
                          ?.map((law, index) => (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "12px",
                                }}
                              >
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => handlePreviewClick(law)}
                                    sx={{ p: 0, ml: 0.8, mr: "4px" }}
                                  >
                                    <VisibilityIcon
                                      fontSize="small"
                                      sx={{ color: "#2196f3" }}
                                    />
                                  </IconButton>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton
                                      sx={{
                                        p: 0,
                                        "&:hover": {
                                          backgroundColor: "transparent",
                                        },
                                      }}
                                    >
                                      {bookmarkedCaseIds.has(law.id) ? (
                                        <>
                                          <Tooltip title="Unbookmark">
                                            <TurnedInIcon
                                              onClick={() =>
                                                handleUnBookmark(law.id)
                                              }
                                              sx={{ color: "#d2042d" }}
                                              fontSize="small"
                                            />
                                          </Tooltip>
                                          {/* <Typography
                                        variant="body2"
                                        sx={{
                                          ml: 0.5,
                                          color: "#303f9f",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                        onClick={() => handleUnBookmark(law.id)}
                                      >
                                        Bookmarked
                                      </Typography> */}
                                        </>
                                      ) : (
                                        <>
                                          <Tooltip title="Bookmark">
                                            <TurnedInNotIcon
                                              onClick={() =>
                                                handleBookmarkClick(law)
                                              }
                                              sx={{
                                                color: "#d2042d",
                                                "&:hover": {
                                                  backgroundColor:
                                                    "transparent",
                                                },
                                              }}
                                              fontSize="small"
                                            />
                                          </Tooltip>
                                          {/* <Typography
                                        variant="body2"
                                        sx={{
                                          ml: 0.5,
                                          color: "#303f9f",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                        onClick={() => handleBookmarkClick(law)}
                                      >
                                        Bookmark
                                      </Typography> */}
                                        </>
                                      )}
                                    </IconButton>
                                  </Box>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      //fontWeight: 'bold',
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "50%",
                                    }}
                                  >
                                    {formatCaseTitle(law.title)}
                                  </Typography>
                                  <Stack
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 0.4,
                                      ml: 0.8,
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight="bold"
                                    >
                                      {`(${law.label})`}
                                    </Typography>
                                    <Typography
                                      sx={{ fontWeight: "bold" }}
                                      component="span"
                                      variant="subtitle2"
                                    >
                                      - {formatCourtName(law.court)}
                                    </Typography>
                                  </Stack>
                                </Stack>

                                {/* <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              onClick={() => handlePreviewClick(law)}
                            >
                              <IconButton sx={{ p: 0, ml: 0.8, mr: "4px" }}>
                                <VisibilityIcon
                                  fontSize="small"
                                  sx={{ color: "#2196f3" }}
                                />
                              </IconButton>
                              <Typography
                                variant="body2"
                                sx={{
                                  mr: 0.5,
                                  color: "#303f9f",
                                  cursor: "pointer",
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }}
                              >
                                Preview
                              </Typography>
                            </Stack> */}
                              </Box>
                              <CaseLawPreviewModal
                                open={openPreview}
                                onClose={() => {
                                  setOpenPreview(false);
                                  dispatch({ type: "CASE_DETAIL_RESET" });
                                }}
                              />
                            </>
                          ))}
                        {!message.isUser && (
                          <Stack flexDirection="row" sx={{ marginTop: "10px" }}>
                            <Tooltip
                              title={isCopied ? "Copied" : "Copy"}
                              arrow
                              PopperProps={{
                                sx: {
                                  "& .MuiTooltip-tooltip": {
                                    backgroundColor: "#101936",
                                  },
                                  "& .MuiTooltip-arrow": {
                                    color: "#101936",
                                  },
                                },
                              }}
                            >
                              <IconButton
                                sx={{ color: isCopied ? "#4caf50" : "#2196f3" }}
                                onClick={() =>
                                  handleCopyClick(message.text.response)
                                }
                              >
                                {isCopied ? (
                                  <CheckCircleOutlineIcon fontSize="small" />
                                ) : (
                                  <ContentCopyIcon fontSize="small" />
                                )}
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Bad Response"
                              arrow
                              PopperProps={{
                                sx: {
                                  "& .MuiTooltip-tooltip": {
                                    backgroundColor: "#101936",
                                  },
                                  "& .MuiTooltip-arrow": {
                                    color: "#101936",
                                  },
                                },
                              }}
                            >
                              <IconButton
                                disabled={feedbackGiven[messageIndex]}
                                sx={{ color: "#d32f2f" }}
                                onClick={() =>
                                  handleFeedbackClick(
                                    message.text.response,
                                    message.text.references,
                                    messageIndex
                                  )
                                }
                              >
                                <ThumbDownOffAltIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                ))
              )}
              {isLoading && (
                <Box display="flex" flexDirection="column" width="85%">
                  <Skeleton width="85%" />
                  <Skeleton width="85%" animation="wave" />
                  <Skeleton width="80%" animation={false} />
                </Box>
              )}
              <div ref={chatContainerEndRef} />
            </div>
          )}
          {showScrollButton && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <Fab
                size="small"
                sx={{
                  color: "white",
                  backgroundColor: "#303F9F",
                  "&:hover": {
                    boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                    color: "white",
                    backgroundColor: "#303F9F",
                  },
                }}
                onClick={scrollToBottom}
              >
                <ArrowDownwardIcon />
              </Fab>
            </Box>
          )}
          <AlertDialog
            open={openAlertDialog}
            handleClose={handleCloseAlertDialog}
          />
          <Feedback
            open={openFeedbackDialog}
            modelResponse={response}
            feedbackQuery={feedbackQuery}
            references={feedbackRefs}
            handleClose={handleCloseFeedbackDialog}
            messageIndex={messageIndex}
            feedbackGiven={handleFeedbackGiven}
          />
          <BookListingDialog
            open={openListingDialog}
            handleClose={handleCloseListingDialog}
            handleBookChange={updateSelectedBook}
          />
          <Box sx={{ flexGrow: 1 }} />

          {/* Container for Format text and Input field */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              marginTop: "3px",
            }}
          >
            {(history || messages?.length > 0) && (
              <>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "8%",
                    gap: 1,
                    width: "80%",
                  }}
                >
                  <TextField
                    variant="outlined"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    value={inputMessage}
                    placeholder={placeholder}
                    InputProps={{
                      sx: {
                        p: 0,
                        backgroundColor: "#ffffff",
                        borderRadius: "20px",
                        padding: "0 5px",
                      },
                      startAdornment: (
                        <IconButton sx={{ color: "#303f9f" }}>
                          <SearchIcon />
                        </IconButton>
                      ),
                      endAdornment: (
                        <IconButton
                          sx={{
                            color: isListening ? "#FF4081" : "#303f9f",
                            animation: isListening
                              ? "pulse 1.5s infinite"
                              : "none",
                          }}
                          variant="contained"
                          onClick={toggleListening}
                          disabled={isProcessing}
                        >
                          <KeyboardVoice />
                        </IconButton>
                      ),
                    }}
                    sx={{
                      width: "75%",
                      mb: "4px",
                      flexGrow: 1,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#gray",
                        },
                        "&:hover fieldset": {
                          borderColor: "#00509e",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#00509e",
                        },
                      },
                      "& input": {
                        padding: "10px 10px",
                        fontSize: "0.875rem",
                      },
                    }}
                  />
                  <Stack
                    width="25%"
                    direction="row"
                    // spacing={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {/* Label */}
                    <InputLabel
                      sx={{
                        fontSize: "14px",
                        color: "#36454f",
                        fontWeight: "500",
                        marginRight: "4px",
                      }}
                    >
                      Chat Mode:
                    </InputLabel>

                    {/* Dropdown Menu */}
                    <FormControl
                      variant="outlined"
                      sx={{
                        width: 140,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                          height: "35px",
                          padding: "0px",
                          bgcolor: "#fff",
                          paddingRight: "10px", // Space for the dropdown arrow
                        },
                        "& .MuiSelect-select": {
                          display: "flex",
                          alignItems: "center",
                          fontSize: "13px",
                          padding: "0 8px", // Consistent padding
                        },
                      }}
                    >
                      <Select
                        value={alignment}
                        onChange={(e) => setAlignment(e.target.value)}
                        sx={{
                          height: "35px",
                          color: "#36454f",
                          "& .MuiSvgIcon-root": { color: "#3c50b1" },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 200, // Limit dropdown height for better UI
                            },
                          },
                        }}
                      >
                        <MenuItem
                          value="balanced"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "13px",
                          }}
                        >
                          <AccountTreeOutlinedIcon
                            fontSize="small"
                            sx={{ color: "#3c50b1", marginRight: "8px" }}
                          />
                          Hybrid Chat
                        </MenuItem>
                        <MenuItem
                          value="precise"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "13px",
                          }}
                        >
                          <MenuBookOutlinedIcon
                            fontSize="small"
                            sx={{ color: "#3c50b1", marginRight: "8px" }}
                          />
                          Statute Chat
                        </MenuItem>
                        <MenuItem
                          value="case law"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "13px",
                          }}
                        >
                          <PlagiarismOutlinedIcon
                            fontSize="small"
                            sx={{ color: "#3c50b1", marginRight: "8px" }}
                          />
                          Case Chat
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#555",
                    width: "60%",
                    ml: "8%",
                    //textAlign: 'center',
                  }}
                >
                  LawGPT provides relevant information but is not a replacement
                  for legal advice.
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <ToastContainer />
      </Box>
      {isCaseLawClicked && (
        <Box
          sx={{
            flexDirection: "column",
            overflowY: "auto",
            maxHeight: "100vh",
            width: "80%",
            maxWidth: "80%",
            borderLeft: "1px solid #ccc",
          }}
          display="flex"
          // width={300}
        >
          <CaseLawsSidebar
            cases={statuteRelevantCases}
            onClose={handleCloseCaseLawsSidebar}
          />
        </Box>
      )}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        fullScreen={fullscreen}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title="Full Screen">
            <IconButton onClick={handleFullscreenToggle}>
              {fullscreen ? (
                <FullscreenExitIcon sx={{ color: "#36454f" }} />
              ) : (
                <FullscreenIcon sx={{ color: "#36454f" }} />
              )}
            </IconButton>
          </Tooltip>
          <IconButton
            sx={{ mr: 2 }}
            edge="end"
            color="inherit"
            onClick={handleCloseModal}
          >
            <CancelIcon color="error" />
          </IconButton>
        </Stack>
        <DialogContent dividers>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
              "& p": {
                margin: 0,
              },
              "& h1": {
                fontFamily: "Roboto, Arial, sans-serif",
                margin: "16px 0",
              },
              "& i": {
                fontStyle: "italic",
              },
              "& a": {
                color: "#303f9f",
                textDecoration: "none",
              },
            }}
            component="div"
            dangerouslySetInnerHTML={{ __html: statuteContentWithStyles }}
          />
        </DialogContent>
      </Dialog>
      <Modal
        open={openBookmarkModal}
        onClose={handleCloseBookmarkModal}
        sx={{
          "& .MuiModal-root": {
            outline: "none",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <Box
          sx={{
            width: 400,
            margin: "auto",
            mt: "10%",
            p: 2,
            borderRadius: 2,
            bgcolor: "white",
            boxShadow: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: "#303f9f",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Bookmark Case
            </Typography>
            <IconButton
              sx={{
                padding: 0,
                color: "gray",
                "&:hover": { color: "#d32f2f" },
              }}
              onClick={handleCloseBookmarkModal}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Dropdown for selecting an existing group or creating a new group */}
          <TextField
            label="Select or Create Group"
            variant="outlined"
            select
            fullWidth
            size="small"
            value={selectedGroup}
            onChange={(e) => {
              setSelectedGroup(e.target.value);
              setIsCreatingNewGroup(e.target.value === "new");
            }}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                bgcolor: "white",
                fontSize: "13px",
                "& input:-webkit-autofill": {
                  backgroundColor: "white !important",
                  WebkitBoxShadow: "0 0 0 30px white inset !important",
                  WebkitTextFillColor: "black !important",
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          >
            <MenuItem
              value="new"
              sx={{
                color: "#303f9f",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              Create New Group
            </MenuItem>
            {groups
              ?.sort((a, b) =>
                a.groupName === "Bookmarks"
                  ? -1
                  : b.groupName === "Bookmarks"
                  ? 1
                  : 0
              )
              ?.map((group) => (
                <MenuItem
                  key={group._id}
                  value={group._id}
                  selected={group.groupName === "Bookmarks"}
                  sx={{
                    fontSize: "13px",
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {group.groupName}
                </MenuItem>
              ))}
          </TextField>

          {/* Input field for creating a new group, only visible if 'Create New Group' is selected */}
          {isCreatingNewGroup && (
            <TextField
              fullWidth
              size="small"
              label="New Group Name"
              variant="outlined"
              value={bookmarkGroupName}
              onChange={(e) => setBookmarkGroupName(e.target.value)}
              sx={{ mb: 2 }}
              InputProps={{
                sx: {
                  bgcolor: "white",
                  fontSize: "13px",
                  "& input:-webkit-autofill": {
                    backgroundColor: "white !important",
                    WebkitBoxShadow: "0 0 0 30px white inset !important",
                    WebkitTextFillColor: "black !important",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                },
              }}
            />
          )}

          {/* Text input for adding a note */}
          <TextField
            label="Add a Note"
            fullWidth
            sx={{ mb: 2 }}
            value={caseNote}
            onChange={(e) => handleNoteChange(e.target.value)}
            multiline
            rows={3}
            error={Boolean(caseNoteError)}
            helperText={caseNoteError}
            InputProps={{
              sx: {
                bgcolor: "white",
                fontSize: "13px",
                "& input:-webkit-autofill": {
                  backgroundColor: "white !important",
                  WebkitBoxShadow: "0 0 0 30px white inset !important",
                  WebkitTextFillColor: "black !important",
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleCloseBookmarkModal}
              color="error"
              sx={{
                borderRadius: "8px",
                backgroundColor: "#d32f2f",
                textTransform: "none",
                fontSize: "14px",
                "&:hover": { backgroundColor: "#b71c1c" },
                transition: "background-color 0.3s ease-in-out",
              }}
            >
              Cancel
            </Button>
            {/* Conditional rendering for buttons based on selection */}
            {isCreatingNewGroup ? (
              <Button
                variant="contained"
                onClick={() => {
                  handleCreateBookmarkGroup();
                  setOpenBookmarkModal(false);
                }}
                disabled={!bookmarkGroupName}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#003D78",
                  textTransform: "none",
                  fontSize: "14px",
                  "&:hover": { backgroundColor: "primary.main" },
                  transition: "background-color 0.3s ease-in-out",
                }}
              >
                Add
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedGroup || caseNoteError}
                onClick={() => {
                  handleAddCaseToGroup();
                  setOpenBookmarkModal(false);
                }}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#003D78",
                  textTransform: "none",
                  fontSize: "14px",
                  "&:hover": { backgroundColor: "primary.main" },
                  transition: "background-color 0.3s ease-in-out",
                }}
              >
                Add
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={openStatuteModal}
        onClose={handleCloseStatuteBookmarkModal}
        sx={{
          "& .MuiModal-root": {
            outline: "none",
          },
          "& .MuiPaper-root": {
            width: "400px",
            borderRadius: 2,
            padding: 2,
            boxShadow: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            p: 0.5,
            color: "#303f9f",
            fontSize: "18px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Bookmark Statute
          <IconButton
            color="error"
            onClick={handleCloseStatuteBookmarkModal}
            sx={{
              padding: 0,
              color: "gray",
              "&:hover": { color: "#d32f2f" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "100%",
            overflow: "visible",
            padding: 0,
            marginTop: 1,
          }}
        >
          <TextField
            sx={{
              mt: 1,
              mb: 1,
              bgcolor: "white",
              "& .MuiInputBase-root": {
                fontSize: "13px",
              },
            }}
            label="Add to existing group"
            select
            fullWidth
            size="small"
            value={selectedGroup}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === "create-new") {
                setBookmarkGroupName("");
              }
              setSelectedGroup(selectedValue);
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          >
            <MenuItem
              sx={{
                color: "#303f9f",
                fontSize: "13px",
                fontWeight: "bold",
              }}
              value="create-new"
            >
              Create New Group
            </MenuItem>
            {groups
              ?.sort((a, b) =>
                a.groupName === "Bookmarks"
                  ? -1
                  : b.groupName === "Bookmarks"
                  ? 1
                  : 0
              )
              ?.map((group) => (
                <MenuItem
                  sx={{
                    maxWidth: "400px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  key={group._id}
                  value={group._id}
                >
                  {group.groupName}
                </MenuItem>
              ))}
          </TextField>

          {/* Conditionally render the input field if "Create New Group" is selected */}
          {selectedGroup === "create-new" && (
            <TextField
              label="New Group Name"
              fullWidth
              value={bookmarkGroupName}
              onChange={(e) => setBookmarkGroupName(e.target.value)}
              sx={{
                mt: 2,
                mb: 2,
                bgcolor: "white",
                "& .MuiInputBase-root": {
                  fontSize: "13px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "13px",
                },
              }}
            />
          )}

          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            multiline
            rows={3}
            value={statuteNote}
            onChange={(e) => handleStatuteNoteChange(e.target.value)}
            sx={{
              mt: 1,
              bgcolor: "white",
              "& .MuiInputBase-root": {
                fontSize: "13px",
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            mt: 1,
            justifyContent: "flex-end",
            padding: "8px 0px 8px 8px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleCloseStatuteBookmarkModal}
            color="error"
            sx={{
              borderRadius: "8px",
              backgroundColor: "#d32f2f",
              textTransform: "none",
              fontSize: "14px",
              "&:hover": { backgroundColor: "#b71c1c" },
              transition: "background-color 0.3s ease-in-out",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              backgroundColor: "#003D78",
              textTransform: "none",
              fontSize: "14px",
              "&:hover": { backgroundColor: "primary.main" },
              transition: "background-color 0.3s ease-in-out",
            }}
            onClick={
              selectedGroup === "create-new"
                ? handleCreateStatuteGroup
                : handleAddStatuteToGroup
            }
            disabled={
              selectedGroup === "create-new"
                ? !bookmarkGroupName
                : !selectedGroup || statuteNoteError
            }
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <InfoDialog
        open={openInfoDialog}
        handleClose={handleCloseInfoDialog}
        isLegalChat={true}
      />
    </>
  );
};

export default memo(ChatInterface);

ChatInterface.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};
