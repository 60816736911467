import React from 'react';
import { Tooltip } from '@mui/material';

const StyledTooltip = ({ title, children }) => {
  return (
    <Tooltip
      title={title}
      placement="top"
      disableInteractive
      arrow
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: '#101936',
          },
          '& .MuiTooltip-arrow': {
            color: '#101936',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default StyledTooltip;
