import ReactGA from 'react-ga4';

//const GA_TRACKING_ID = 'G-MLMSMEKZ2K'; // Replace with your actual Google Analytics Measurement ID
const GA_TRACKING_ID = 'G-QFD9FW77Y8';

// Initialize Google Analytics
export const initializeGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);
};

// Track page views
export const trackPageView = (path) => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

// Track time stamps
export const trackEventWithResponseTime = (category, action, label, value) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value,
  });
};

// Track events
export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
