import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import TasksTable from "./Components/TaskComponent";
import Sidebar from "./Components/Sidebar";
import InformationCard from "./Components/InformationCards";
import Navbar from "./Components/Navbar";
import Documents from "./Components/Documents";
import LawyerCard from "./Components/LawyerCard";
import RightSideBar from "./Components/RightSideBar";
import { CMSContext, CMSProvider } from "./context/CaseManagmentContext";
import DocumentManagement from "./Components/UpdateDocument";
import ComingSoonModal from "../../components/Modal/CommingSoonModal";
// Sample data
const initialData = {
  clientInfo: {
    clientName: "John Smith",
    phone: "090078601",
    email: "john@example.com",
    cnic: "61101-1234123-1",
    profession: "Doctor",
    address: "House 1, Street 2, Islamabad",
  },
  caseInfo: {
    caseTitle: "Smith v. Johnson",
    caseDescription: "Dispute over property boundaries.",
    advocates: ["Robert Brown", "Jane Doe"],
    status: "Not Started",
    startDate: "2024-11-20",
    closeDate: "2024-12-25",
  },
};
const lawyers = [
  { name: "Robert Brown", designation: "Senior Advocate" },
  { name: "Jane Doe", designation: "Junior Advocate" },
  { name: "Michael Lee", designation: "Associate Advocate" },
  { name: "Sarah Parker", designation: "Paralegal" },
];
const initialTasks = [
  {
    id: 1,
    name: "Review Evidence",
    description: "Analyze evidence submitted by both parties.",
    assignedTo: "Jane Doe",
    status: "Pending",
    startDate: "2024-10-15",
    dueDate: "2024-11-15",
  },
  {
    id: 2,
    name: "Prepare Documents",
    description: "Prepare legal documents for filing.",
    assignedTo: "Robert Brown",
    status: "Completed",
    startDate: "2024-10-15",
    dueDate: "2024-11-18",
  },
];

const initialDocuments = [
  {
    id: 1,
    title: "Evidence Report",
    description: "Primary evidence details",
    version: "1.1",
    uploadedBy: "John Smith",
    timestamp: "2024-11-11",
    comment: "Initial draft submitted",
    attachments: [
      {
        id: 11,
        title: "Evidence Review - Senior",
        description: "Reviewed by Senior Advocate",
        uploadedBy: "Robert Brown",
        timestamp: "2024-11-12",
      },
    ],
  },
];

// Main Dashboard Component
function CaseManagementSystem() {
  const [currentTab, setCurrentTab] = useState(1);
  const [clientInfo, setClientInfo] = useState(initialData.clientInfo);
  const [caseInfo, setCaseInfo] = useState(initialData.caseInfo);
  const [lawyersInfo, setLawyersInfo] = useState(initialData.lawyersInfo);
  const [tasks, setTasks] = useState(initialTasks);
  const [documents, setDocuments] = useState(initialDocuments);

  const handleSaveLawyersInfo = (data) => setLawyersInfo(data);
  const handleSaveClientInfo = (data) => setClientInfo(data);
  const handleSaveCaseInfo = (data) => setCaseInfo(data);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 50px)",
          boxSizing: "border-box",
          p: "12px 12px 0 12px",
          bgcolor: "#F5F5F5",
        }}
      >
        <Navbar currentTab={currentTab} handleTabChange={handleTabChange} />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "calc(100vh - 150px)",
            gap: 1,
            bgcolor: "#F5F5F5",
          }}
        >
          {/* Left Sidebar */}
          <Box
            sx={{
              minWidth: "16%",
              maxWidth: "16%",
              width: "16%",
              bgcolor: "#F7F7F7",
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            <Sidebar />
          </Box>

          {/* Main Content */}
          <Box
            component="main"
            sx={{
              flex: 1,
              bgcolor: "#FFFFFF",
              borderRadius: "10px",
              px: 2,
              py: 3,
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <InformationCard
                  title="Client Information"
                  data={clientInfo}
                  onSave={handleSaveClientInfo}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InformationCard
                  title="Case Information"
                  data={caseInfo}
                  onSave={handleSaveCaseInfo}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <LawyerCard
                  title="Lawyers Information"
                  data={lawyers}
                  onSave={handleSaveLawyersInfo}
                />
              </Grid>
              <Grid item xs={12}>
                <TasksTable tasks={tasks} onTaskEdit={() => {}} />
              </Grid>
              <Grid item xs={12}>
                <DocumentManagement documents={documents} />
              </Grid>
            </Grid>
          </Box>

          {/* Right Sidebar */}
          <Box
            sx={{
              minWidth: "18%",
              maxWidth: "18%",
              width: "18%",
              height: "100%",
              bgcolor: "#F7F7F7",
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            <RightSideBar />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "calc(100% - 45px)",
          height: "calc(100vh - 50px)",
          top: "50px",
          left: "45px",
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            position: "relative",
            width: "calc(100vw - 45px)",
            height: "calc(100vh - 50px)",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "row",
            overflowY: "clip",
          }}
        >
          <ComingSoonModal />
        </Box>
      </Box>
    </>
  );
}

export default () => (
  <CMSProvider>
    <CaseManagementSystem />
  </CMSProvider>
);
